import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { change, Field } from 'redux-form';
import { withRouter } from 'react-router';

import Button from '../../../../client/components/CustomButton/CustomButton.jsx';
import { currencyMask, ISSMask } from '../../../../utils/masks.js';
import RenderField from '../../../../components/RenderField';

const Footer = ({ onSubmit, onConsult, loading, onCancel }) => {
  const { code, NFSeId, isInvoiceIssued, isInvoiceCanceled, isInvoiceProcessing } = useSelector(state => state.form.NFSe.values)
  const dispatch = useDispatch()

  async function handleEmitInvoice() {
    await dispatch(change('NFSe', 'toEmit', true))
    onSubmit()
  }

  function handleConsultInvoice() {
    onConsult(NFSeId)
  }

  return (
    <div id='NFSe-footer'>
      <header>
        <strong>Nota</strong>
        <strong>{code}</strong>
      </header>
      <main>
        <fieldset>
          <section>
            <Field
              name='subTotal'
              component={RenderField}
              label='Valor de Serviço'
              {...currencyMask}
              disabled
            />
          </section>
          <section>
            <Field
              name='discountValue'
              component={RenderField}
              label='Desconto Geral:'
              {...currencyMask}
              disabled
            />
          </section>
          <section>
            <Field
              name='servicesTotal'
              component={RenderField}
              label='Valor Total:'
              {...currencyMask}
              disabled
            />
          </section>
        </fieldset>

        <fieldset>
          <section>
            <Field
              name='ISS'
              component={RenderField}
              label='% ISS'
              {...ISSMask}
              disabled
            />
          </section>
          <section>
            <Field
              name='ISSValue'
              component={RenderField}
              label='Valor de ISS:'
              {...currencyMask}
              disabled
            />
          </section>
        </fieldset>
      </main>

      <footer>
        <Button
          bsStyle='primary'
          fill
          onClick={onSubmit}
          style={{
            visibility: (isInvoiceIssued || isInvoiceCanceled || isInvoiceProcessing) ? 'hidden' : ''
          }}
          // disabled={loading || isInvoiceDenied || isInvoiceCanceled || isInvoiceIssued || isInvoiceIssuedInContingency}
          disabled={loading}
        >
          <span className={loading ? 'fa fa-spinner fa-pulse fa-1x' : ''} />
          {!NFSeId ? 'Salvar' : 'Atualizar'}
        </Button>
        <div>
          {
            isInvoiceProcessing
              ? (
                <Button
                  bsStyle='primary'
                  fill
                  onClick={handleConsultInvoice}
                >
                  <span className={loading ? 'fa fa-spinner fa-pulse fa-1x' : ''} />
                Processar NFS-e
                </Button>
              )
              : (
                <Button
                  bsStyle='info'
                  fill
                  disabled={loading || isInvoiceIssued || isInvoiceCanceled}
                  onClick={handleEmitInvoice}
                >
                  <span className={loading ? 'fa fa-spinner fa-pulse fa-1x' : ''} />
                  {
                    isInvoiceIssued
                      ? 'NFS-e Emitida'
                      : isInvoiceCanceled
                        ? 'NFS-e Cancelada'
                        : 'Emitir'
                  }
                </Button>
              )
          }

          {/* NÃO APAGAR ABAIXO, SERÁ USADO NA EMISSÃO */}
          {/* NÃO APAGAR ABAIXO, SERÁ USADO NA EMISSÃO */}
          {/* NÃO APAGAR ABAIXO, SERÁ USADO NA EMISSÃO */}
          {/* NÃO APAGAR ABAIXO, SERÁ USADO NA EMISSÃO */}
          {/* {isInvoiceIssuedInContingency
            ? (
              <Button
                bsStyle='info'
                fill
                disabled={loading}
                onClick={onConsultContingency}
              >
                <span className={loading ? 'fa fa-spinner fa-pulse fa-1x' : ''} />
                Consultar NF-e
              </Button>
            )
            : (
              <Button
                bsStyle='info'
                fill
                disabled={loading || isInvoiceIssued || isInvoiceCanceled || isInvoiceDenied}
                onClick={handleEmitInvoice}
              >
                <span className={loading ? 'fa fa-spinner fa-pulse fa-1x' : ''} />
                {isInvoiceIssued
                  ? 'NF-e emitida'
                  : isInvoiceCanceled
                    ? 'NF-e Cancelada'
                    : isInvoiceDenied
                      ? 'NF-e Denegada'
                      : 'Emitir NF-e'
                }
              </Button>
            )
          } */}
          <Button
            bsStyle='danger'
            fill
            disabled={loading}
            onClick={onCancel}
          >
            Voltar
          </Button>
        </div>
      </footer>
    </div>
  )
}

export default withRouter(Footer)