import config from '../config'
import api from '../services/api'

const getProductsAndServicesRanking = (companyId, queryParams) =>
  api.get(config.endpoint + `ranking-sales/${companyId}`, {
    params: queryParams,
  })

export default {
  getProductsAndServicesRanking,
}
