import { cpfOrCnpjMask } from 'client/components/ToNormalize/ToNormalize'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { toastr } from 'react-redux-toastr'
import financingBankSlipRepository from 'repositories/FinancingBankSlip'
import companyRepository from 'repositories/Companies'
import BoletoFlexCard from './BoletoFlexCard'
import NewFinancingBankSlipSelect from './NewFinancingBankSlipSelect'
import '../styles.css'
import Button from 'client/components/CustomButton/CustomButton'
import KoinCard from './KoinCard'

const NewFinancingBankSlipMain = ({
  goToHomePage,
  financingBankSlipId,
  partnerId,
}) => {
  const [partner, setPartner] = useState(1)
  const [formattedOptions, setFormattedOptions] = useState([])
  const [loading, setLoading] = useState(false)

  const { register, control, setValue, handleSubmit } = useForm({
    defaultValues: {
      cpfCnpj: '',
      companyName: '',
      tradingName: '',
      isActive: true,
      selectedClient: null,
      companyId: null,
    },
  })

  const selectedClient = useWatch({
    control,
    name: 'selectedClient',
  })

  const isActive = useWatch({
    control,
    name: 'isActive',
  })

  async function loadClientsOptions() {
    setLoading(true)
    try {
      const clientsWithoutConfiguration =
        await financingBankSlipRepository.findClientsWithoutConfiguration()

      const { data: clientsWithPaidPlan } =
        await companyRepository.getCompaniesWithPaidPlan()

      const clientsWithPaidPlanOnlyActives = clientsWithPaidPlan
        .map((element) => {
          return {
            ...element,
            subElements: element.Signature.filter(
              (subElement) => subElement.SignatureStatus_id === 2
            ),
          }
        })
        .filter((element) => element.subElements.length > 0)

      const clientsWithPaidPlanIds = clientsWithPaidPlanOnlyActives.map(
        (element) => element.id
      )

      const clientsWithoutConfigurationWithPaidPlan =
        clientsWithoutConfiguration.filter((client) =>
          clientsWithPaidPlanIds.includes(client.id)
        )

      setFormattedOptions(
        clientsWithoutConfigurationWithPaidPlan.map((client) => ({
          value: client,
          label: `${cpfOrCnpjMask(client.Cpf_Cnpj)} - ${
            client.Company_Name || client.Trading_Name
          }`,
        }))
      )
    } catch (err) {
      console.log(err)
      return toastr.error(
        'Erro',
        'Ocorreu um erro ao carregar os clientes. Tente novamente!'
      )
    }
    setLoading(false)
  }

  const validations = (data) => {
    if (!data.companyId) {
      toastr.warning(
        'Campo obrigatório ausente',
        'Selecione ao menos um cliente para continuar o cadastro.'
      )
      return false
    }

    if (partner === 1) {
      if (data.consumerKey?.length === 0) {
        toastr.warning(
          'Campo obrigatório ausente',
          'Preencha o campo "consumerKey" conforme cadastro da Koin'
        )
        return false
      }

      if (data.secretKey?.length === 0) {
        toastr.warning(
          'Campo obrigatório ausente',
          'Preencha o campo "secretKey" conforme cadastro da Koin'
        )
        return false
      }
    }

    if (partner === 2) {
      if (data.sellerName?.length === 0) {
        toastr.warning(
          'Campo obrigatório ausente',
          'Preencha o nome do seller conforme cadastro da Boleto Flex'
        )
        return false
      }
    }
    return true
  }

  const loadFinancingBankSlip = async () => {
    setLoading(true)
    try {
      const financingBankSlip = await financingBankSlipRepository.show(
        financingBankSlipId,
        {
          partnerId,
        }
      )

      setPartner(partnerId)
      setValue('cpfCnpj', financingBankSlip.CompanyBankSlip.Company.Cpf_Cnpj)
      setValue(
        'companyName',
        financingBankSlip.CompanyBankSlip.Company.Company_Name
      )
      setValue(
        'tradingName',
        financingBankSlip.CompanyBankSlip.Company.Trading_Name
      )
      setValue('selectedClient', {})
      setValue('companyId', financingBankSlip.CompanyBankSlip.companyId)
      setValue('isActive', financingBankSlip.CompanyBankSlip.isActive)
      setValue('consumerKey', financingBankSlip.consumerKey)
      setValue('secretKey', financingBankSlip.secretKey)
      setValue('sellerName', financingBankSlip.nameSeller)
    } catch (err) {
      console.log(err)
      return toastr.error(
        'Erro',
        'Ocorreu um erro ao carregar o financiamento. Tente novamente!'
      )
    }
    setLoading(false)
  }

  const onSubmit = async (data) => {
    if (!validations(data)) {
      return
    }
    setLoading(true)
    try {
      const params = {
        isActive: data.isActive,
        companyId: data.companyId,
        partnerId: partner,
        sellerName: data.sellerName,
        consumerKey: data.consumerKey,
        secretKey: data.secretKey,
      }

      if (!financingBankSlipId) {
        const financingBankSlipConfig =
          await financingBankSlipRepository.create(params)

        if (financingBankSlipConfig.alreadyExists) {
          setLoading(false)
          return toastr.warning(
            'Cliente já vinculado ao parceiro.',
            'O cliente em questão já foi vinculado ao parceiro. Verifique e tente novamente!'
          )
        }
      } else {
        await financingBankSlipRepository.update(financingBankSlipId, params)
      }

      toastr.success(
        `Cliente ${financingBankSlipId ? 'salvo' : 'cadastrado'} com sucesso`,
        'A partir desse momento, o cliente poderá utilizar o financiamento em seu sistema'
      )

      goToHomePage()
    } catch (err) {
      console.log(err)
      toastr.error(
        'Erro',
        'Ocorreu um erro ao salvar a configuração de boleto. Tente novamente!'
      )
    }
    setLoading(false)
  }

  const handleSelectClient = (e) => {
    setValue('selectedClient', e.value)
    setValue('cpfCnpj', cpfOrCnpjMask(e.value.Cpf_Cnpj))
    setValue('tradingName', e.value.Trading_Name)
    setValue('companyName', e.value.Company_Name)
    setValue('companyId', e.value.id)
  }

  useEffect(() => {
    loadClientsOptions()
  }, [])

  useEffect(() => {
    if (!!financingBankSlipId) {
      loadFinancingBankSlip()
    }
  }, [financingBankSlipId])

  return (
    <div className="new-financing-bank-slip__page">
      <NewFinancingBankSlipSelect
        setPartner={setPartner}
        partner={partner}
        formattedOptions={formattedOptions}
        setValue={(e) => handleSelectClient(e)}
        value={selectedClient}
        isLoading={loading}
        placeholder="Pesquisar por CNPJ, Razão Social ou Nome Fantasia"
        noOptionsMessage={() => 'Nenhum cliente encontrado'}
        disabled={financingBankSlipId}
      />

      {partner === 1 && (
        <KoinCard register={register} setValue={setValue} isActive={isActive} />
      )}

      {partner === 2 && (
        <BoletoFlexCard
          register={register}
          setValue={setValue}
          isActive={isActive}
        />
      )}

      <div className="new-financing-bank-slip__buttons ">
        <Button
          bsStyle="danger"
          disabled={loading}
          onClick={() => goToHomePage()}
          fill
        >
          Cancelar
        </Button>

        <Button
          bsStyle="info"
          disabled={loading}
          onClick={handleSubmit(onSubmit)}
          fill
        >
          <span
            className={`${loading ? 'fa fa-spinner fa-pulse fa-1x' : ''}`}
          />
          Salvar
        </Button>
      </div>
    </div>
  )
}

export default NewFinancingBankSlipMain
