import LoaderSpinner from 'client/components/LoaderSpinner';

import React, { useState, useEffect } from 'react';

import { Modal } from 'react-bootstrap';
import LoadingSpinnerFullHeight from 'v2/components/LoadingSpinner';
import { ProgressBar } from 'v2/components/ProgressBar';

const LoadingModal = ({
  showLoadingModal,
  customMessage,
  isProgress,
  progress,
  importCountLabel,
}) => {
  const [show, setShow] = useState(showLoadingModal);

  useEffect(() => {
    setShow(showLoadingModal);
  }, [showLoadingModal]);

  return (
    <Modal
      show={show}
      keyboard={false}
      backdrop={'static'}
      dialogClassName="loading-modal-wrapper"
      centered
      style={{
        marginTop: '250px',
        zIndex: '100000',
      }}
    >
      <Modal.Body>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <p
            style={{
              fontSize: '28px',
              fontWeight: 200,
              display: 'flex',
            }}
          >
            Por favor, aguarde...
            <LoadingSpinnerFullHeight />
          </p>

          {isProgress ? (
            <div style={{ width: '300px' }}>
              <ProgressBar
                progress={progress}
                progressLabel={importCountLabel}
              />
            </div>
          ) : (
            <LoaderSpinner
              style={{
                fontSize: '5px',
                margin: '10px',
              }}
            />
          )}

          <p
            style={{
              fontSize: '14px',
              fontWeight: 400,
            }}
          >
            {' '}
            {customMessage ? customMessage : 'Não saia ou feche a tela'}{' '}
          </p>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default LoadingModal;
