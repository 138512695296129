import config from 'config';
import api from 'services/api';
import sobreRodasApi from 'services/sobreRodasApi';

const create = async (values) => {
  const response = await api.post(config.endpoint + `company`, values);
  return response.data;
};

const getAllUsers = async (companyId) => {
  try {
    const response = await api.get(
      config.endpoint + `company/${companyId}/users`
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

const showBySuperLogicaId = async (superLogicaId) => {
  try {
    const company = await api.get(
      `company/find/by-super-logica/${superLogicaId}`
    );

    return company.data;
  } catch (err) {
    throw err;
  }
};

const updateSuperLogicaId = async ({ companyId, superLogicaId }) => {
  try {
    const company = await api.put(`company/${companyId}/super-logica-id`, {
      superLogicaId,
    });

    return company;
  } catch (err) {
    throw err;
  }
};

const getWithAddressAndPhones = async (id, headers) => {
  const { data } = await api.get('company/address-phones/' + id, { headers });

  return data;
};

const show = async (id) => {
  const response = await api.get(config.endpoint + `company/${id}`);
  return response.data.data;
};

const getById = async (companyId) => {
  try {
    const response = await api.get(
      config.endpoint + `company/dadoscompany/${companyId}`
    );

    if (!response.data.status) {
      throw new Error('Ocorreu um erro ao buscar a empresa');
    }

    return response.data.data;
  } catch (err) {
    throw err;
  }
};

const getByCpfCnpj = async (document) => {
  const { data } = await api.get('company/get-by-cpfCnpj/' + document);

  return data;
};

const getTaxData = async (companyId) => {
  try {
    const response = await api.get(
      config.endpoint + `company/${companyId}/tax-data`
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

const getCompaniesForSuccessReport = async () => {
  try {
    const response = await api.get(
      config.endpoint + 'company/by-report/success-points'
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

const getSuccessPoints = async (companyId) => {
  try {
    const response = await api.get(
      config.endpoint + `report/success-points/${companyId}`
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

const updateTaxData = async (companyId, values) => {
  await api.put(config.endpoint + `company/${companyId}/tax-data`, values);
};

const updateWithSuperlogicaInfo = (companyId, values) =>
  api.put(config.endpoint + `company/${companyId}/superlogica-info`, values);

const validateCertificate = async (formData) => {
  const response = await api.post(
    config.endpoint + 'company/validate-certificate',
    formData
  );
  return response.data;
};

const getCounters = async (companyId, monthRangeOfCustomerReturnCalc) => {
  try {
    const response = await api.get(
      config.endpoint +
        `company/${companyId}/dashboard/${monthRangeOfCustomerReturnCalc}`
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

const updateFinancialConfiguration = async (companyId, values) => {
  await api.put(
    config.endpoint + `company/${companyId}/financial-configuration`,
    values
  );
};

const updateSalesConfiguration = async (companyId, values) => {
  await api.put(
    config.endpoint + `company/${companyId}/sales-configuration`,
    values
  );
};

const updatePurchaseConfiguration = async (companyId, values) => {
  await api.put(
    config.endpoint + `company/${companyId}/purchase-configuration`,
    values
  );
};

const updateCompanyCpfCnpj = async ({ companyId, cpfCnpj, forceUpdate }) => {
  await api.put(`company/change-cpfCnpj/${companyId}`, {
    cpfCnpj,
    forceUpdate,
  });
};

const getIndicatorsByCompanyId = async (companyId) => {
  const response = await api.get(
    config.endpoint + `company/${companyId}/dashboard-external`
  );

  return response.data;
};

const mainUpdate = (companyId, values) =>
  api.put(config.endpoint + `company/${companyId}/main`, values);

const getCompaniesWithPaidPlan = async () =>
  await api.get(config.endpoint + 'company/plans/paid');

const checkSobreRodasRegisterByCnpj = (cpfCnpj) =>
  sobreRodasApi.get('companies/by-cnpj/' + cpfCnpj);

export default {
  getAllUsers,
  getTaxData,
  getCounters,
  getById,
  getByCpfCnpj,
  mainUpdate,
  updateTaxData,
  updateWithSuperlogicaInfo,
  validateCertificate,
  show,
  updateFinancialConfiguration,
  updateSalesConfiguration,
  updatePurchaseConfiguration,
  create,
  getCompaniesWithPaidPlan,
  updateCompanyCpfCnpj,
  getWithAddressAndPhones,
  checkSobreRodasRegisterByCnpj,
  getCompaniesForSuccessReport,
  getSuccessPoints,
  updateSuperLogicaId,
  showBySuperLogicaId,
  getIndicatorsByCompanyId,
};
