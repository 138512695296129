import React, { useEffect, useState, useRef } from 'react';
import { Col, Modal } from 'react-bootstrap';
import { toastr } from 'react-redux-toastr';
import Select, { components } from 'react-select';

import Button from 'client/components/CustomButton/CustomButton.jsx';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard, faFileInvoice } from '@fortawesome/free-solid-svg-icons';

import { faPix } from '@fortawesome/free-brands-svg-icons';

import TabelaCondPayments from '../../../PaymentConditions/PaymentCondition';
import TabelaFormOfPayment from '../../../FormOfPayment/FormOfPayment';
import { useDispatch, useSelector } from 'react-redux';
import {
  loadPaymentData,
  handleInstallmentDate,
  handleCondOfPayments,
  handleInstallmentValue,
  handleInstallmentFormOfPayment,
  replicateFirstFormOfPayment,
  handleInstallmentAmountReceived,
  handleChange,
  handleInstallmentAuthorizationCode,
} from './redux/actions';
import CurrencyInput from 'client/components/Currency';
import CashierBankModal from './CashierBankModal';
import moment from 'moment';
import { useAuth } from 'contexts/auth';
import { percentage } from 'client/components/ToNormalize/ToNormalize';
import FinancingModal from '../../FinancingModal';
import { useModulesContext } from 'contexts/modules';
import { InactiveModuleModal } from 'client/components/InactiveModuleModal';

import { usePlanSignatureContext } from 'contexts/plan-signature';

import financingBankSlipRepository from 'repositories/FinancingBankSlip';
import { Right } from 'react-bootstrap/lib/Media';
import { useBonus } from 'hooks/useBonus';
import { useBranding } from 'hooks/useBranding';
import { currency } from 'client/components/ToNormalize/ToNormalize';

import {
  HeaderItem,
  HeaderPaymentForm,
  InstallmentRow,
  SaleInstallmentHeader,
  SaleInstallmentsWrapper,
  InstallmentsContainer,
  HyperlinkButton,
} from './PaymentData.styles';
import { NOT_DIGITAL_PAYMENT_FORMS } from 'v2/utils/constants';

const { Option } = components;

const IconOption = (props) => (
  <Option {...props}>
    {props.data.isStoneIntegrated && (
      <FontAwesomeIcon
        className="option-icon"
        color="#444444"
        icon={faCreditCard}
      />
    )}
    <span>{props.data.label}</span>
  </Option>
);

export default function PaymentData() {
  const [modalTitle, setModalTitle] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [paymentFormsOptions, setPaymentFormsOptions] = useState([]);
  const [hasStonePaymentForm, setHasStonePaymentForm] = useState(false);
  const [selectedStonePaymentId, setSelectedStonePaymentId] = useState(null);

  const [clickedInputIndex, setClickedInputIndex] = useState();
  const currencyInputRef = useRef();

  const [isFinancingModalOpen, setIsFinancingModalOpen] = useState(false);
  const [
    isInactiveFinancingModuleModalOpen,
    setIsInactiveFinancingModuleModalOpen,
  ] = useState(false);

  const [isCRMBonusButtonDisabled, setIsCRMBonusButtonDisabled] =
    useState(null);

  const {
    company: { hasCashierControl, companyConfig },
    companyId,
    userId,
  } = useAuth();

  const { companyHasCRMBonus, handleRedeemBonusClick, crmCustomerId } =
    useBonus();
  const [isProcessing, setIsProcessing] = useState(false);

  const { isPlanStart, isPlanFree, isPlanBasic, isPaidWorkmotor } =
    usePlanSignatureContext();
  const isPlanPrime = !isPlanStart && !isPlanFree && !isPlanBasic;

  const [companyFinancingModule, setCompanyFinancingModule] = useState(null);
  const [isLoadingFinancingModule, setIsLoadingFinancingModule] =
    useState(false);

  const validateCRMBonusButtonDisabled = () => {
    const hasClient = !!saleReducer.selectedClient.id;

    const isBudgetCancelled =
      saleReducer.saleTypeId === 1 && saleStatusId === 2;

    const isSaleFinished = saleReducer.saleTypeId === 2 && saleStatusId === 5;
    const isSaleCancelled = saleReducer.saleTypeId === 2 && saleStatusId === 2;

    const isOrderOfServiceCancelled =
      saleReducer.saleTypeId === 3 && saleStatusId === 2;
    const isOrderOfServiceFinished =
      saleReducer.saleTypeId === 3 && saleStatusId === 5;

    return (
      (hasClient && isBudgetCancelled) ||
      (hasClient && isSaleFinished) ||
      (hasClient && isSaleCancelled) ||
      (hasClient && isOrderOfServiceCancelled) ||
      (hasClient && isOrderOfServiceFinished) ||
      usedCRMBonus
    );
  };

  const { isWorkmotor } = useBranding();

  const dispatch = useDispatch();
  const { saleReducer } = useSelector((state) => state);
  const {
    saleStatusId,
    installments,
    selectedClient,
    condOfPayments,
    items,
    selectedCondOfPayment,
    formOfPayments,
    openCashierBankModal,
    selectedVehicle,
    redeemedBonus,
    usedCRMBonus,
    continueWithoutBonus,
  } = saleReducer;

  const handleBonusClick = () => {
    handleRedeemBonusClick(saleReducer);
    setIsProcessing(true);

    // Simulate an asynchronous process
    setTimeout(() => {
      setIsProcessing(false);
      // Outras lógicas aqui...
    }, 2000);
  };

  const getRedeemBonusText = () => {
    if (usedCRMBonus && redeemedBonus > 0) {
      return 'Bônus Resgatado';
    }

    if (usedCRMBonus) {
      return 'R$ 0,00 disponível';
    }

    if (continueWithoutBonus) {
      return 'Bônus não usado';
    }

    return 'Consultar Bônus';
  };

  useEffect(() => {
    const isCRMBonusButtonDisabled = validateCRMBonusButtonDisabled();
    setIsCRMBonusButtonDisabled(isCRMBonusButtonDisabled);
  }, [saleReducer]);

  useEffect(() => {
    if (!formOfPayments) return;
    const paymentFormsOptions = formOfPayments.map(
      ({
        id,
        Desciption: description,
        isStoneIntegration,
        isMateraIntegration,
        isBankSlipIntegration,
      }) => ({
        label:
          isMateraIntegration || isBankSlipIntegration ? (
            <>
              <FontAwesomeIcon
                color="#000"
                icon={isMateraIntegration ? faPix : faFileInvoice}
                className="option-icon"
              />
              {description}
            </>
          ) : (
            description
          ),
        value: id,
        isStoneIntegrated: isStoneIntegration,
      })
    );

    setPaymentFormsOptions(paymentFormsOptions);

    const hasStonePaymentForm = !!formOfPayments.find(
      (paymentForm) => paymentForm.isStoneIntegration
    );
    setHasStonePaymentForm(hasStonePaymentForm);
  }, [formOfPayments]);

  useEffect(() => {
    const selectedStonePayment = installments.find(
      (installment) => installment.formOfPayment.isStoneIntegration
    );
    setSelectedStonePaymentId(selectedStonePayment?.formOfPayment.id);

    if (isPlanPrime) {
      const installmentsWithCreditStore = installments.map((installment) => {
        if (installment.formOfPayment.TypeOfPayment === 'Crédito Loja') {
          // installment.amountReceived = true
          installment.date = moment().format('YYYY-MM-DD');
        }
        return installment;
      });

      dispatch(handleChange(installmentsWithCreditStore, 'installments'));
    }
  }, [JSON.stringify(installments)]);

  const onHideModal = () => {
    dispatch(loadPaymentData());
    setOpenModal(false);
  };

  const handleReplicateFormOfPayment = () => {
    if (saleStatusId !== 5 && saleStatusId !== 2) {
      installments[0].formOfPayment.id === ''
        ? toastr.warning('Selecione uma Forma de Pagamento na primeira linha')
        : dispatch(replicateFirstFormOfPayment());
    }
  };

  const checkValue = (value, index) => {
    let price = parseFloat(value);

    if (currencyInputRef?.current?.theInput) {
      currencyInputRef.current.theInput.blur();
      currencyInputRef.current.theInput.focus();
    }

    dispatch(handleInstallmentValue(price, index));
  };

  const checkDate = (value, index) => {
    if (value !== '') {
      var dataAtual = moment(value).format('YYYY-MM-DD');
      if (value < dataAtual) return toastr.warning('Data inválida');
      dispatch(handleInstallmentDate(value, index));
    }
  };

  const handleChangeAuthorizationCodeInput = (value, index) => {
    dispatch(handleInstallmentAuthorizationCode(value, index));
  };

  const handleOpenCashierBankModal = () => {
    if (installments.length === 0)
      return toastr.warning('Selecione uma condição de pagamento');
    dispatch(handleChange(true, 'openCashierBankModal'));
  };

  const handlePaymentFormChange = (paymentFormId, index) => {
    const paymentForm = formOfPayments.find(
      (paymentForm) => paymentForm.id === paymentFormId
    );

    if (
      paymentForm.isStoneIntegration &&
      !!selectedStonePaymentId &&
      selectedStonePaymentId !== paymentFormId &&
      installments.length > 1
    ) {
      return toastr.warning(
        'Não é permitido mais de uma forma de pagamento na integração com Stone.'
      );
    }

    dispatch(handleInstallmentFormOfPayment(paymentFormId, index));
  };

  const handleOpenPaymentFormModal = () => {
    setModalTitle('Formas de Pagamento');
    setOpenModal(true);
  };

  const isCreditStorePayment = (index) => {
    if (!isPlanPrime) {
      return false;
    }

    return installments[index].formOfPayment.TypeOfPayment === 'Crédito Loja'
      ? true
      : false;
  };

  async function getCompanyFinancingModule() {
    try {
      setIsLoadingFinancingModule(true);

      const financingModule = await financingBankSlipRepository.findByCompany(
        companyId,
        { isActive: true }
      );

      setCompanyFinancingModule(financingModule);
      setIsLoadingFinancingModule(false);

      return financingModule;
    } catch (err) {
      console.log(err);
      toastr.error(
        'OS Digital',
        'Ocorreu um erro ao carregar o módulo de financiamento. Tente novamente!'
      );
      setIsLoadingFinancingModule(false);
    }
  }

  async function handleOpenFinancingModal() {
    const financingModule = await getCompanyFinancingModule();

    if (!financingModule) {
      return setIsInactiveFinancingModuleModalOpen(true);
    }

    if (items.length === 0 || !selectedClient) {
      return toastr.warning(
        'Campos obrigatórios não preenchidos',
        'Selecione ao menos 1 item e 1 cliente para solicitar o financiamento'
      );
    }

    const saleServices = items.filter((item) => item.Type === 'Serviço');

    if (saleServices.length && !selectedVehicle.id) {
      return toastr.warning(
        'Por favor, informe o veículo para a ordem de serviço.'
      );
    }

    if (selectedClient.Cpf_Cnpj?.length > 11) {
      return toastr.warning(
        'Função não disponível pra PJ',
        'A função de financiamento é disponível apenas para clientes PF. Mude o cliente e tente novamente'
      );
    }

    if (saleStatusId !== 6) {
      dispatch(handleCondOfPayments('', []));
    }

    setIsFinancingModalOpen(true);
  }

  function handleCloseFinancingModal() {
    setIsFinancingModalOpen(false);
  }

  useEffect(() => {
    if (!isWorkmotor) return;
    handleCondPaymentOptions();
    handleCondPaymentMinValue();
  }, [saleReducer.total, selectedCondOfPayment.id, condOfPayments]);

  const handleCondPaymentOptions = () => {
    if (condOfPayments.length === 0) return;

    condOfPayments.forEach((condOfPayment) => {
      const minValue = +condOfPayment?.minValue;

      let option = document.getElementById(`condOfPayment-${condOfPayment.id}`);

      if (minValue > saleReducer.total) {
        if (option) {
          option.classList.add('min-value-option');
        }
      } else {
        if (option) {
          option.classList.remove('min-value-option');
        }
      }
    });
  };

  const handleCondPaymentMinValue = () => {
    if (
      !selectedCondOfPayment.id ||
      +selectedCondOfPayment?.minValue <= saleReducer.total
    ) {
      return;
    }

    toastr.error(
      'Valor mínimo não atingido',
      `O valor da venda/os não atingiu o mínimo necessário para usar a condição. Para ser utilizada, necessário custar ${currency(
        +selectedCondOfPayment?.minValue
      )}. Verifique e tente novamente ou selecione outra condição.`
    );

    dispatch(handleCondOfPayments('', []));

    return;
  };

  return (
    <div style={{ marginTop: '10px' }}>
      {openCashierBankModal && <CashierBankModal />}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '10px',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <p
              style={{
                fontSize: '14px',
                paddingTop: '5px',
                paddingRight: '2px',
                width: '120px',
                fontWeight: 'bold',
              }}
            >
              Condição de Pagamento:{' '}
            </p>
            <Col
              xs={12}
              sm={4}
              md={4}
              lg={4}
              style={{
                display: 'flex',
                padding: 0,
              }}
            >
              <a
                className="div-a"
                style={{
                  position: 'absolute',
                  marginTop: '-20px',
                  fontSize: '12px',
                }}
                onClick={() => {
                  setModalTitle('Condições de Pagamento');
                  setOpenModal(true);
                }}
              >
                Visualizar Condições
              </a>

              <select
                className="form-control foco-input"
                value={selectedCondOfPayment.id || ''}
                onChange={(e) =>
                  items.length === 0
                    ? toastr.warning('Sem itens na venda')
                    : dispatch(
                        handleCondOfPayments(e.target.value, condOfPayments)
                      )
                }
                disabled={
                  saleStatusId === 5 ||
                  saleStatusId === 2 ||
                  saleStatusId === 6 ||
                  saleStatusId === 7 ||
                  saleStatusId === 9
                }
              >
                <option value={''}>Selecione</option>
                {condOfPayments.map((c) => (
                  <option id={`condOfPayment-${c.id}`} key={c.id} value={c.id}>
                    {c.Description}{' '}
                    {isWorkmotor && +c.minValue > 0
                      ? ` - Mínimo: ${currency(+c.minValue)}`
                      : ''}
                  </option>
                ))}
              </select>
            </Col>

            <Button
              fill
              onClick={handleOpenFinancingModal}
              style={{
                margin: '10px',
                height: '35px',
                width: 'auto',
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#5bc0de',
                borderColor: '#5bc0de',
              }}
              disabled={
                (!!saleStatusId &&
                  saleStatusId !== 3 &&
                  saleStatusId !== 8 &&
                  saleStatusId !== 9) ||
                isLoadingFinancingModule
              }
            >
              <span
                className={
                  isLoadingFinancingModule
                    ? 'fa fa-spinner fa-pulse fa-1x update-spinner'
                    : 'update-spinner-disabled'
                }
              />
              Financiamento (até 24x)
            </Button>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            margin: '5px',
            justifyContent: 'space-between',
            padding: '5px',
          }}
        >
          {hasStonePaymentForm && (
            <span>Será aceito apenas uma forma de pagamento pela Stone.</span>
          )}
          {companyHasCRMBonus && (
            <Button
              fill
              onClick={() => handleBonusClick()}
              bsStyle="info"
              style={{
                margin: '5px',
                height: '35px',
                width: 'auto',
                display: 'flex',
                alignItems: 'center',
              }}
              disabled={isProcessing || isCRMBonusButtonDisabled}
            >
              <span
                className={
                  isProcessing
                    ? 'fa fa-spinner fa-pulse fa-1x update-spinner'
                    : 'update-spinner-disabled'
                }
              />
              {getRedeemBonusText()}
            </Button>
          )}
          {hasCashierControl && (
            <button
              style={{
                margin: '5px',
                maxWidth: '120px',
              }}
              id="change-cashierbank-button"
              onClick={handleOpenCashierBankModal}
            >
              Caixa/Banco
            </button>
          )}

          {isPaidWorkmotor && companyConfig.manageInternalCredit && (
            <div
              style={{
                flexGrow: 1,
                display: 'flex',
                alignItems: 'center',
                padding: '10px',
              }}
            >
              <span>
                <strong>Crédito Disponível:</strong>
              </span>
              <CurrencyInput
                id="initial-balance"
                className="form-control foco-input"
                style={{ width: '120px' }}
                value={selectedClient?.availableCredit || 0}
                disabled
              />
            </div>
          )}
        </div>
      </div>

      <SaleInstallmentsWrapper>
        {installments.length ? (
          <React.Fragment>
            <SaleInstallmentHeader>
              {Array.from(Array(3).keys()).map((arr) => (
                <HeaderItem key={arr} />
              ))}
              <HeaderItem>
                <HyperlinkButton onClick={handleOpenPaymentFormModal}>
                  Visualizar Formas
                </HyperlinkButton>
              </HeaderItem>
            </SaleInstallmentHeader>
            <SaleInstallmentHeader>
              <HeaderItem>Parcela</HeaderItem>
              <HeaderItem>Vencimento</HeaderItem>
              <HeaderItem>
                Valor
                <span style={{ color: 'red', fontSize: '11px' }}>
                  {selectedCondOfPayment?.feePercent
                    ? ` (${percentage(
                        selectedCondOfPayment?.feePercent
                      )} de Juros)`
                    : ''}
                </span>
              </HeaderItem>

              <HeaderPaymentForm>
                <HeaderItem>Forma de Pagamento</HeaderItem>
              </HeaderPaymentForm>

              <HeaderItem>Valor Recebido?</HeaderItem>
              <HeaderItem>Cód. Autorização de Pagto</HeaderItem>
            </SaleInstallmentHeader>
          </React.Fragment>
        ) : (
          ''
        )}
        <InstallmentsContainer>
          {installments.map((item, index) => {
            const isDigitalPayment = !NOT_DIGITAL_PAYMENT_FORMS.includes(
              item.formOfPayment.TypeOfPayment
            );
            return (
              <React.Fragment>
                <InstallmentRow>
                  <div>
                    <span
                      style={{
                        display: 'block',
                        alignSelf: 'center',
                        fontSize: '14px',
                        textAlign: 'center',
                      }}
                    >
                      {selectedCondOfPayment.Condition === 'À vista' ||
                      selectedCondOfPayment.Condition === '0'
                        ? 'À vista'
                        : selectedCondOfPayment.hasEntry && index === 0
                        ? 'Entrada'
                        : !selectedCondOfPayment.hasEntry
                        ? `${index + 1}/${installments.length}`
                        : `${index}/${installments.length - 1}`}
                    </span>
                  </div>
                  <div>
                    <input
                      className="form-control foco-input"
                      value={item.date}
                      onChange={(e) => checkDate(e.target.value, index)}
                      disabled={
                        saleStatusId === 5 ||
                        saleStatusId === 2 ||
                        (selectedCondOfPayment.hasEntry && index === 0) ||
                        selectedCondOfPayment.Condition === 'À vista' ||
                        selectedCondOfPayment.Condition === '0' ||
                        isCreditStorePayment(index)
                      }
                      style={{ fontSize: '13px' }}
                      type="date"
                    />
                  </div>
                  <div>
                    <CurrencyInput
                      className="form-control foco-input"
                      value={item.value}
                      onChangeEvent={(e) => checkValue(e.target.value, index)}
                      disabled={
                        saleStatusId === 5 ||
                        saleStatusId === 2 ||
                        saleStatusId === 7 ||
                        saleStatusId === 6 ||
                        saleStatusId === 9 ||
                        (installments.length === 1 &&
                          selectedCondOfPayment.Condition !== '0')
                      }
                      onClick={() => setClickedInputIndex(index)}
                      ref={
                        index === clickedInputIndex ? currencyInputRef : null
                      }
                    />
                  </div>
                  <div>
                    <Select
                      options={paymentFormsOptions}
                      placeholder="Selecione"
                      noOptionsMessage={() =>
                        'Nenhuma forma de pagamento encontrada'
                      }
                      components={{ Option: IconOption }}
                      isDisabled={
                        saleStatusId === 5 ||
                        saleStatusId === 2 ||
                        saleStatusId === 7 ||
                        saleStatusId === 6 ||
                        saleStatusId === 9
                      }
                      onChange={(paymentForm) =>
                        handlePaymentFormChange(paymentForm.value, index)
                      }
                      value={
                        paymentFormsOptions.find(
                          (paymentForm) =>
                            paymentForm.value === item.formOfPayment.id
                        ) || null
                      }
                      styles={{
                        control: (base) => ({
                          ...base,
                          height: '35px',
                          minHeight: '35px',
                          borderColor: '#E3E3E3',
                          '&:hover': {
                            borderColor: '#E3E3E3',
                          },
                        }),
                        indicatorSeparator: () => ({
                          display: 'none',
                        }),
                        valueContainer: (provided) => ({
                          ...provided,
                          marginTop: '-6px',
                        }),
                        dropdownIndicator: (provided) => ({
                          ...provided,
                          marginTop: '-6px',
                        }),
                      }}
                    />
                  </div>
                  <div className="installments-checkbox">
                    <input
                      type="checkbox"
                      checked={item.amountReceived}
                      onChange={() =>
                        dispatch(
                          handleInstallmentAmountReceived(
                            !item.amountReceived,
                            index
                          )
                        )
                      }
                      disabled={
                        saleStatusId === 5 ||
                        saleStatusId === 2 ||
                        saleStatusId === 7 ||
                        saleStatusId === 6 ||
                        saleStatusId === 9
                      }
                    />
                  </div>
                  <div>
                    <input
                      value={installments[index].authorizationCode}
                      className="form-control foco-input"
                      onChange={(e) =>
                        handleChangeAuthorizationCodeInput(
                          e.target.value,
                          index
                        )
                      }
                      disabled={
                        !item.formOfPayment?.id ||
                        saleStatusId === 5 ||
                        saleStatusId === 2 ||
                        !isDigitalPayment
                      }
                      type="text"
                    />
                  </div>
                </InstallmentRow>
                {index === 0 && installments.length > 1 && (
                  <div style={{ paddingTop: '5px' }}>
                    <SaleInstallmentHeader>
                      {Array.from(Array(3).keys()).map((arr) => (
                        <HeaderItem key={arr} />
                      ))}

                      <HyperlinkButton onClick={handleReplicateFormOfPayment}>
                        <div className="flex end">Replicar</div>
                      </HyperlinkButton>
                    </SaleInstallmentHeader>
                  </div>
                )}
              </React.Fragment>
            );
          })}
        </InstallmentsContainer>
      </SaleInstallmentsWrapper>
      <Modal
        dialogClassName="modal-formProduto"
        show={openModal}
        onHide={onHideModal}
        animation={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>{modalTitle}</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body id="modal-formProduto-body">
          {modalTitle === 'Condições de Pagamento' ? (
            <TabelaCondPayments />
          ) : (
            <TabelaFormOfPayment />
          )}
        </Modal.Body>
      </Modal>
      {isFinancingModalOpen && (
        <FinancingModal
          open={true}
          handleClose={handleCloseFinancingModal}
          financingModule={companyFinancingModule}
        />
      )}

      {isInactiveFinancingModuleModalOpen && (
        <InactiveModuleModal
          show={true}
          onHide={() => setIsInactiveFinancingModuleModalOpen(false)}
          customButtonText={'Informações via WhatsApp'}
          showWhatsAppIcon={true}
          customMessage={
            'O modulo de financiamento é adicional, solicite maiores informações via Whatsapp.'
          }
          customLink={`https://wa.me/551937553007?text=Quero%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20financiamento`}
        />
      )}
    </div>
  );
}
