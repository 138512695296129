import config from '../config';
import api from '../services/api';

const getAll = async () => {
  const response = await api.get(config.endpoint + 'advertisements');
  return response.data;
};

const create = async (advertising) => {
  const response = await api.post(
    config.endpoint + 'advertisements',
    advertising
  );
  return response.data;
};

const update = async (id, values) => {
  await api.put(config.endpoint + `advertisements/${id}/`, values);
};

const show = async (id) => {
  const response = await api.get(config.endpoint + `advertisements/${id}`);
  return response.data;
};

const disable = async (id) => {
  await api.put(config.endpoint + `advertisements/${id}/disable`);
};

const activate = async (id) => {
  await api.put(config.endpoint + `advertisements/${id}/activate`);
};

const click = async (body) => {
  await api.post(config.endpoint + `advertisements/clicks`, body);
};

const showActiveBySegmentForUserId = async (userId) => {
  const response = await api.get(
    config.endpoint + `advertisements/${userId}/by-segment`
  );
  return response.data;
};

const showAdvertisementByCompanyId = async (companyId) => {
  const response = await api.get(
    config.endpoint + `advertisements/${companyId}/by-config`
  );
  return response.data;
};

const getExclusiveAdByCompanyId = async (companyId, brandingId) => {
  const response = await api.get(
    config.endpoint + `advertisements/exclusive/active/${companyId}`,
    {
      params: {
        brandingId,
      },
    }
  );
  return response.data;
};

const getActiveGlobalAd = async (companyId, brandingId) => {
  const response = await api.get(
    config.endpoint + `advertisements/global/active/by-company/${companyId}`,
    {
      params: {
        brandingId,
      },
    }
  );
  return response.data;
};

const advertisementsRepository = {
  create,
  update,
  show,
  getAll,
  disable,
  activate,
  click,
  showActiveBySegmentForUserId,
  showAdvertisementByCompanyId,
  getExclusiveAdByCompanyId,
  getActiveGlobalAd,
};

export default advertisementsRepository;
