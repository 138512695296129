import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { toastr } from 'react-redux-toastr';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm, useFieldArray } from 'react-hook-form';
import AlertModal from 'components/AlertModal/AlertModal';
import Button from 'client/components/CustomButton/CustomButton';
import {
  ButtonContainer,
  Container,
  Footer,
  OSDigLogoContainer,
} from './styles';
import LogoOSD from 'assets/img/OsDigitalLogo.png';
import LogoWM from 'assets/img/workmotor/logo.png';
import { QuotationHeader } from './components/Header';
import { QuotationProvider } from './components/ProviderInfo';
import { QuotationData } from './components/QuotationData';
import companyRepository from 'repositories/Companies';
import providerRepository from 'repositories/Providers';
import quotationRepository from 'repositories/Quotations';
import quotationResponseRepository from 'repositories/QuotationResponses';
import './styles.css';
import { useBranding } from 'hooks/useBranding';

const replySchema = yup.object().shape({
  quotation: yup.object().shape({
    code: yup.string(),
    QuotationItems: yup.array().of(
      yup.object().shape({
        description: yup.string().required(),
        manufacturer_ref: yup.string().required(),
        quantity: yup.number().positive().required(),
        available_quantity: yup
          .number()
          .moreThan(0)
          .required('Quantidade disponível deve ser maior que 0'),
        unit_value: yup
          .number()
          .moreThan('0')
          .required('Valor unitário deve ser maior que 0'),
      })
    ),
    quotationItems: yup.array().of(
      yup.object().shape({
        description: yup.string().required(),
        manufacturer_ref: yup.string().required(),
        quantity: yup.number().positive().required(),
        available_quantity: yup
          .number()
          .positive('Não é possível inserir quantidade negativa')
          .required('Quantidade disponível é obrigatório'),
        unit_value: yup
          .number()
          .positive('Não é possível inserir valor unitário negativo')
          .required('Valor unitário é obrigatório'),
      })
    ),
  }),
});
export function QuotationResponse({ location }) {
  // States
  const [replied, setReplied] = useState(false);
  const [cancelled, setCancelled] = useState(false);
  const [showClearFieldsModal, setShowClearFieldsModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [quotationItems, setQuotationItems] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { isWorkmotor } = useBranding();

  // Constants
  const methods = useForm({
    resolver: yupResolver(replySchema),
    defaultValues: {
      company: {
        Trading_Name: 'Nome fantasia',
        Company_Name: 'Nome company',
        Email: 'company@email.com',
        Url_Logo: isWorkmotor ? LogoWM : LogoOSD,
        Address: [
          {
            Address: 'Nome da rua',
            Address_Number: 10,
            Neighborhood: 'Bairro',
            City: 'Cidade',
            State: 'Estado',
          },
        ],
        Phones: [{ Number_Phone1: '00000000000' }],
      },
      provider: {
        tradingName: 'Nome fantasia Fornecedor',
        companyName: 'Nome empresa fornecedora',
        cpfCnpj: '00000000100',
        Phones: [{ Number_Phone1: '00 00000-0000' }],
        email: 'provider@email.com',
        Address: [
          {
            Zipcode: '00000000',
            Address: 'Nome da rua',
            Address_Number: 20,
            Neighborhood: 'Bairro',
            City: 'Cidade',
            State: 'Estado',
          },
        ],
      },
      quotation: {
        code: 1,
        createdAt: new Date(),
        QuotationItems: [{}],
        observations: '',
      },
      quotationItems: [],
      reply_observations: '',
    },
  });

  const fieldArrayMethods = useFieldArray({
    control: methods.control,
    name: 'quotationItems',
  });

  // Use effects
  useEffect(() => {
    loadAllIds(location.search);
  }, []);

  useEffect(() => {
    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  const updateDimensions = () => {
    const width = window.innerWidth;
    setWindowWidth(width);
  };

  const showMobileVersion = windowWidth < 700;

  // async functions
  // Load data functions
  async function loadAllIds(search) {
    try {
      const quotationProviderId = search.split('=')[1];

      const response = await quotationRepository.getIdsFromQuotationProvider(
        quotationProviderId,
        {
          ['x-force-auth']: true,
        }
      );

      await loadCompany(response.Quotation.companyId);
      await loadProvider(response.providerId, response.provider_phone);
      await loadQuotation(response.quotationId);

      //verificação provisória sobre status da quotação:
      await verifyResponseStatus(response.quotationId, response.providerId);
    } catch (err) {
      return toastr.error(
        'Erro',
        'Ocorreu um erro ao carregar as informações, tente novamente'
      );
    }
  }

  async function loadCompany(companyId) {
    try {
      const company = await companyRepository.getWithAddressAndPhones(
        companyId,
        {
          ['x-force-auth']: true,
        }
      );

      methods.setValue('company', company);
    } catch (err) {
      return toastr.error(
        'Empresa',
        'Ocorreu um erro ao carregar dados da empresa, tente novamente'
      );
    }
  }

  async function loadProvider(providerId, providerPhone) {
    try {
      const provider = await providerRepository.getById(providerId, {
        ['x-force-auth']: true,
      });

      methods.setValue('provider', provider);
      if (providerPhone) {
        methods.setValue('provider.Phones', [{ Number_Phone1: providerPhone }]);
      }
    } catch (err) {
      return toastr.error(
        'Fornecedor',
        'Ocorreu um erro ao carregar as informações do fornecedor, tente novamente'
      );
    }
  }

  async function loadQuotation(quotationId) {
    try {
      const quotation = await quotationRepository.getQuotationWithItems(
        quotationId,
        {
          ['x-force-auth']: true,
        }
      );

      const items = quotation.QuotationItems.map((item) => ({
        ...item,
        avaliable_quantity: 0,
        unit_value: 0,
      }));

      delete quotation.QuotationItems;

      methods.setValue('quotation', quotation);
      fieldArrayMethods.replace(items);
      setQuotationItems(items);

      if (quotation.status === 'Cancelada') {
        setCancelled(true);
        return toastr.error(
          'Não é possível interagir com a cotação',
          'A cotação em questão encontra-se expirada. Em caso de dúvidas, entre em contato com quem lhe enviou informando o caso.'
        );
      }
    } catch (err) {
      return toastr.error(
        'Carregamento da cotação',
        'Ocorreu um erro ao carregar as informações da cotação, tente novamente'
      );
    }
  }

  async function verifyResponseStatus(quotationId, providerId) {
    try {
      const quotationResponse =
        await quotationResponseRepository.verifyResponseByQuotationAndProvider(
          quotationId,
          providerId,
          {
            ['x-force-auth']: true,
          }
        );

      if (quotationResponse) {
        setReplied(true);
        return toastr.error(
          'Não é possível interagir com a cotação',
          'A cotação em questão encontra-se expirada. Em caso de dúvidas, entre em contato com quem lhe enviou informando o caso.'
        );
      }
    } catch (err) {
      return toastr.error(
        'Erro na busca da cotação',
        'Tente novamente e caso persista, contate o suporte.'
      );
    }
  }

  // Submit
  async function submit() {
    const formData = methods.getValues();

    let hasMoreThanZero = false;
    hasMoreThanZero = formData.quotationItems.some((item) => {
      if (item.avaliable_quantity !== 0 || item.unit_value !== 0) {
        return true;
      }
    });

    if (hasMoreThanZero === false) {
      return toastr.error(
        'Erro',
        'Informe a quantidade disponível e o valor unitário para prosseguir.'
      );
    }

    try {
      const replyItems = formData.quotationItems.map((item) => ({
        quotationItemId: item.id,
        avaliable_quantity: item.avaliable_quantity
          ?.replace('.', '')
          ?.replace(',', '.'),
        unit_value: item.unit_value
          ?.replace('R$ ', '')
          ?.replace('.', '')
          ?.replace(',', '.'),
      }));

      const quotationReply = {
        observations: formData.reply_observations,
        providerId: formData.provider.id,
        Items: replyItems,
      };

      await quotationRepository.answerQuotation(
        formData.quotation.id,
        quotationReply,
        {
          ['x-force-auth']: true,
        }
      );
      toastr.success(
        'Cotação Respondida',
        'Resposta da cotação enviada com sucesso'
      );
      setReplied(true);
    } catch (err) {
      console.log(err);
      toastr.error(
        'Erro',
        'Ocorreu um erro ao enviar a resposta da cotação, tente novamente'
      );
    } finally {
      setShowConfirmationModal(false);
    }
  }

  // sync functions
  function clearFields() {
    const clearedItems = fieldArrayMethods.fields.map((item) => ({
      ...item,
      avaliable_quantity: 0,
      unit_value: 0,
    }));

    fieldArrayMethods.replace(clearedItems);
    methods.setValue('reply_observations', '');
    setShowClearFieldsModal(false);
  }

  return (
    <Container
      style={{
        background: '#F7F6F6',
        minHeight: '100vh',
      }}
    >
      <div className={isWorkmotor ? 'wmBar' : 'blueBar'}></div>
      <div style={{ width: showMobileVersion ? '100%' : '70%' }}>
        <FormProvider {...methods}>
          <form>
            <QuotationHeader
              showMobileVersion={showMobileVersion}
              defaultLogo={isWorkmotor ? LogoWM : LogoOSD}
            />
            <QuotationProvider showMobileVersion={showMobileVersion} />
            <QuotationData
              quotationItems={quotationItems}
              replied={replied}
              cancelled={cancelled}
              showMobileVersion={showMobileVersion}
            />

            {showMobileVersion ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '90%',
                  marginTop: '-40px',
                  borderTop: '1px solid #c0c0c0',
                  paddingTop: '15px',
                }}
              >
                <Button
                  disabled={replied || cancelled}
                  style={{
                    color: '#d9534f',
                    border: 'none',
                    fontWeight: 'bold',
                  }}
                  onClick={(e) => setShowClearFieldsModal(true)}
                >
                  Limpar Cotação
                </Button>
                <Button
                  fill
                  bsStyle="info"
                  disabled={replied || cancelled}
                  onClick={() => setShowConfirmationModal(true)}
                >
                  Responder Cotação
                </Button>
              </div>
            ) : (
              <ButtonContainer>
                <Button
                  fill
                  bsStyle="warning"
                  disabled={replied || cancelled}
                  onClick={() => setShowClearFieldsModal(true)}
                >
                  Limpar Todos os Campos
                </Button>
                <Button
                  fill
                  bsStyle="info"
                  disabled={replied || cancelled}
                  onClick={() => setShowConfirmationModal(true)}
                >
                  Responder Cotação
                </Button>
              </ButtonContainer>
            )}

            {/* Clear fields alert modal */}
            <AlertModal
              show={showClearFieldsModal}
              title="Limpeza de campos"
              onCancelLabel="Voltar"
              onSubmitLabel="Limpar Cotação"
              onHide={() => setShowClearFieldsModal(false)}
              message={
                <div style={showMobileVersion ? { textAlign: 'center' } : {}}>
                  <strong>
                    Você tem certeza que deseja limpar todos os campos da
                    cotação?
                  </strong>{' '}
                  <br />
                  <br />
                  <span>
                    O processo de limpeza é irreversível. Ao limpar, será
                    necessário inserir todos os valores da cotação novamente
                    para que possa ser enviado ao cliente
                  </span>
                  <br />
                  <br />
                  <span>
                    Em caso de dúvidas, por favor entre em contato com o
                    estabelecimento
                  </span>
                </div>
              }
              customFooter={
                <div
                  style={{
                    display: 'flex',
                    flexDirection: showMobileVersion ? 'column' : 'row',
                    justifyContent: 'flex-end',
                    gap: showMobileVersion ? '5px' : '10px',
                  }}
                >
                  <Button
                    fill
                    style={showMobileVersion ? { order: 2 } : {}}
                    bsStyle="danger"
                    onClick={() => setShowClearFieldsModal(false)}
                  >
                    Voltar
                  </Button>

                  <Button
                    style={showMobileVersion ? { marginLeft: 0, order: 1 } : {}}
                    fill
                    bsStyle="info"
                    onClick={() => clearFields()}
                  >
                    Limpar Cotação
                  </Button>
                </div>
              }
            />

            {/* Confirmation modal */}
            <AlertModal
              show={showConfirmationModal}
              title="Responder Cotação"
              onCancelLabel="Voltar"
              onSubmitLabel="Responder Cotação"
              onHide={() => setShowConfirmationModal(false)}
              message={
                <div style={showMobileVersion ? { textAlign: 'center' } : {}}>
                  <strong>
                    Você tem certeza que deseja responder a cotação?
                  </strong>
                  <br />
                  <br />
                  <span>
                    O processo de limpeza de resposta é definitivo. Ao responder
                    a cotação, não será possível realizar ajustes de valores e
                    quantidade posteriormente
                  </span>
                  <br />
                  <br />
                  <span>
                    Em caso de dúvidas, por favor entre em contato com o
                    estabelecimento
                  </span>
                </div>
              }
              customFooter={
                <div
                  style={{
                    display: 'flex',
                    flexDirection: showMobileVersion ? 'column' : 'row',
                    justifyContent: 'flex-end',
                    gap: showMobileVersion ? '5px' : '10px',
                  }}
                >
                  <Button
                    fill
                    bsStyle="danger"
                    style={showMobileVersion ? { order: 2 } : {}}
                    onClick={() => setShowConfirmationModal(false)}
                  >
                    Voltar
                  </Button>
                  <Button
                    fill
                    bsStyle="info"
                    style={showMobileVersion ? { order: 1 } : {}}
                    type="submit"
                    onClick={() => methods.handleSubmit(submit())}
                  >
                    Responder Cotação
                  </Button>
                </div>
              }
            />
          </form>
        </FormProvider>
        <Footer>
          <OSDigLogoContainer>
            <img src={isWorkmotor ? LogoWM : LogoOSD} alt="logo" />
          </OSDigLogoContainer>
        </Footer>
      </div>

      <div className={isWorkmotor ? 'wmBar' : 'blueBar'}></div>
    </Container>
  );
}
