import React, { useEffect, useState } from 'react';
import Toggle from 'react-toggle';

import PollsTable from './PollsTable';
import Card from '../../../../components/Card/Card';

import { useAdminAuth } from 'contexts/adminAuth';

import useFilters from 'hooks/useFilters';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';

import pollsRepository from '../../../../repositories/Polls';
import PollsFilter from './PollsFilter';
import AlertModal from 'components/AlertModal';

function PollsMain({ history }) {
  const { admin } = useAdminAuth();

  const [loading, setLoading] = useState(false);
  const [polls, setPolls] = useState([]);

  const pollsSorted = polls.sort((a, b) => {
    if (a.status && b.status) return 0;
    if (a.status && !b.status) return -1;
    return 1;
  });

  const { dateFilter, queryFilter, statusFilter, typeFilter } = useFilters();
  const { typeFilter: brandingFilter } = useFilters();

  const { filterByDate, finalDate, initialDate, setFinalDate, setInitialDate } =
    dateFilter;

  const { status, setStatus, filterByStatus } = statusFilter;
  const { query, setQuery, filterByQuery } = queryFilter;
  const { type, setType, filterByType } = typeFilter;

  const [branding, setBranding] = useState({ label: 'Todos', value: '' });

  const { setType: setBrandingFilter, filterByType: filterByBranding } =
    brandingFilter;

  useEffect(() => {
    if (!!admin) loadPolls();
  }, []);

  useEffect(() => {
    setBrandingFilter(branding.value);
  }, [branding]);

  const loadPolls = async () => {
    setLoading(true);
    try {
      const polls = await pollsRepository.getAll();
      const formatedPolls = polls.map((poll) => {
        const pollsAnswer = poll.pollsAnswer.map((answer) => {
          const company = answer.pollsUsers.Company[0];
          const signature = company
            ? company.Signature[company.Signature.length - 1]
            : null;
          const plan = signature ? signature.Plans.Description : '';
          const cpfCnpj = company ? company.Cpf_Cnpj : '';
          const companyName = company ? company.Company_Name : '';
          const tradingName = company ? company.Trading_Name : '';
          const customerXId = company ? company.external_id_client : '';

          return {
            ...answer,
            userName: answer.pollsUsers.Name,
            userEmail: answer.pollsUsers.Email,
            userPhone: answer.pollsUsers.Phone,
            companyCpfCnpj: cpfCnpj,
            companyPlan: plan,
            companyName,
            tradingName,
            customerXId,
          };
        });

        return {
          ...poll,
          pollsAnswer,
        };
      });

      setPolls(formatedPolls);
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao buscar as pesquisas. Por favor, tente novamente.'
      );
    } finally {
      setLoading(false);
    }
  };

  const handleFilters = (polls) => {
    if (!!validations()) {
      const querySearch = [polls['description']];

      return (
        filterByQuery(querySearch) &&
        filterByDate('initialDate', polls) &&
        filterByDate('finalDate', polls) &&
        filterByStatus('status', polls) &&
        filterByType('typeId', polls) &&
        filterByBranding('brandingId', polls)
      );
    }
    return;
  };

  const validations = () => {
    //verifica se initialDate e finalDate são maiores que a data atual
    if (
      new Date(initialDate) > new Date() ||
      new Date(finalDate) > new Date()
    ) {
      toastr.warning('Datas devem ser menores ou iguais a data atual.');
      return false;
    }

    if (finalDate && initialDate) {
      if (finalDate < initialDate) {
        toastr.warning('A data final deve ser maior que a data inicial.');
        return false;
      }
    }

    return true;
  };

  const filteredPolls = pollsSorted?.filter(handleFilters);

  const PollsStatusSwitch = ({ pollsId, brandingId, status }) => {
    const [confirmModal, setConfirmModal] = useState(false);

    const updateStatus = async () => {
      await pollsRepository.updateStatus(pollsId, !status);
      setPolls(
        polls.map((item) => {
          if (item.id === pollsId) {
            return { ...item, status: !status };
          } else {
            return item;
          }
        })
      );
    };

    const handleUpdateStatus = async () => {
      try {
        if (status) return updateStatus();

        const hasActivePolls = polls.some(
          (item) => item.status === true && item.brandingId === brandingId
        );

        if (!hasActivePolls) {
          setConfirmModal(true);
        } else {
          return toastr.warning(
            'Pesquisa não foi ativada',
            'Só é possível ativar uma pesquisa por vez e existe uma pesquisa ativa. Desative antes para ativar essa.'
          );
        }
      } catch (err) {
        return toastr.warning(
          'Pesquisa não foi ativa',
          'Ocorreu um erro ao atualizar pesquisa ' + err
        );
      }
    };

    return (
      <div>
        <Toggle
          style={{ position: 'fixed' }}
          checked={status}
          onChange={() => handleUpdateStatus(pollsId, !status)}
        />

        <AlertModal
          title="OS Digital"
          subtitle="Você tem certeza que deseja ativar a pesquisa?"
          show={confirmModal}
          message="Ao ativar, será exibida a partir desse momento para todos os clientes na home do OS Digital"
          onCancel={() => setConfirmModal(false)}
          onHide={() => setConfirmModal(false)}
          onSubmit={updateStatus}
          loading={loading}
        />
      </div>
    );
  };

  return (
    <Card
      content={
        <div>
          <PollsFilter
            history={history}
            query={query}
            setQuery={setQuery}
            status={status}
            setStatus={setStatus}
            type={type}
            setType={setType}
            initialDate={initialDate}
            setInitialDate={setInitialDate}
            finalDate={finalDate}
            setFinalDate={setFinalDate}
            branding={branding}
            setBranding={setBranding}
          />

          <PollsTable
            loading={loading}
            polls={filteredPolls}
            PollsStatusSwitch={PollsStatusSwitch}
          />
        </div>
      }
    />
  );
}

export default withRouter(PollsMain);
