import React, { useEffect, useState } from 'react'
import { toastr } from 'react-redux-toastr'
import ReactTable from 'react-table'

import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Card from 'components/Card/Card.jsx'
import DownloadXlsButton from 'components/DownloadXlsButton'

import Button from 'client/components/CustomButton/Button.jsx'
import { phoneMask } from 'client/components/ToNormalize/ToNormalize'

import { useAuth } from 'contexts/auth'
import useFilters from 'hooks/useFilters'

import birthdaysRepository from 'repositories/BirthdaysReports'
import { getDateOnlyFromDate } from 'utils/dateHelpers'
import { isBefore } from 'date-fns'

export default function BirthdaysReportMain() {
  const [loading, setLoading] = useState(false)

  const [birthdays, setBirthdays] = useState([])
  const [birthdaysHasLoaded, setBirthdaysHasLoaded] = useState(false)

  const [initialDate, setInitialDate] = useState('')
  const [finalDate, setFinalDate] = useState('')
  const [personType, setPersonType] = useState('')

  const { queryFilter } = useFilters()
  const { query, setQuery, filterByQuery } = queryFilter

  const { companyId } = useAuth()

  useEffect(() => {
    if (companyId) {
      handleSetInitialParams()
    }
  }, [companyId])

  const handleSetInitialParams = () => {
    const params = new URLSearchParams(window.location.search)

    const receivedInitialDate = params.get('firstDate')
    const receivedFinalDate = params.get('secondDate')

    if (receivedInitialDate && receivedFinalDate) {
      setInitialDate(receivedInitialDate)
      setFinalDate(receivedFinalDate)
      setPersonType('BOTH')
      handleLoadBirthdays(receivedInitialDate, receivedFinalDate)
    }
  }

  const handleValidateSubmit = () => {
    if (
      !initialDate ||
      !finalDate ||
      isBefore(getDateOnlyFromDate(finalDate), getDateOnlyFromDate(initialDate))
    ) {
      return toastr.warning('Selecione um período válido')
    }

    if (!personType) {
      return toastr.warning('Selecione o tipo')
    }

    handleLoadBirthdays(initialDate, finalDate)
  }

  const handleLoadBirthdays = async (initialDate, finalDate) => {
    setLoading(true)

    try {
      const birthdays = await birthdaysRepository.getAllBirthdays({
        initialDate,
        finalDate,
        personType,
        companyId,
      })

      setBirthdays(birthdays)
    } catch (err) {
      console.log(err)
      toastr.warning(
        'OS Digital',
        'Ocorreu um erro ao buscar os aniversariantes. Por favor, tente novamente!'
      )
    } finally {
      setLoading(false)
      setBirthdaysHasLoaded(true)
    }
  }

  const handleQueryFilter = (person) => {
    const querySearch = [person['name'], person['phone'], person['email']]
    return filterByQuery(querySearch)
  }
  return (
    <Card
      content={
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <header style={{ display: 'flex' }}>
            <section style={{ marginRight: '5px' }}>
              <label style={{ fontWeight: 'bold' }}>Data Inicial:</label>
              <input
                type="date"
                className="form-control foco-input"
                value={initialDate}
                onChange={(e) => setInitialDate(e.target.value)}
              />
            </section>
            <section style={{ marginRight: '5px' }}>
              <label style={{ fontWeight: 'bold' }}>Data Final:</label>
              <input
                type="date"
                className="form-control foco-input"
                value={finalDate}
                onChange={(e) => setFinalDate(e.target.value)}
              />
            </section>
            <div style={{ marginRight: '5px', width: '20%' }}>
              <label style={{ fontWeight: 'bold' }}>Tipo:</label>
              <select
                value={personType}
                onChange={(e) => setPersonType(e.target.value)}
                className="form-control foco-input"
              >
                <option value="">Selecione</option>
                <option value="BOTH">Ambos</option>
                <option value="CUSTOMER">Cliente</option>
                <option value="EMPLOYEE">Funcionário</option>
              </select>
            </div>
            <section style={{ marginTop: '38px', marginRight: '5px' }}>
              <button
                className="btn btn-sucesso"
                type="submit"
                onClick={handleValidateSubmit}
                disabled={!initialDate || !finalDate || !personType || loading}
              >
                <span
                  className={`${loading ? 'fa fa-spinner fa-pulse fa-1x' : ''}`}
                />{' '}
                Gerar relatório
              </button>
            </section>

            <div style={{ marginTop: '38px' }}>
              <DownloadXlsButton
                archiveName={`Aniversariantes-${initialDate
                  .split('-')
                  .reverse()
                  .join('/')}-${finalDate.split('-').reverse().join('/')}`}
                data={birthdays.filter(handleQueryFilter)}
                className="btn btn-export"
                disabled={loading || !birthdays.length}
                columns={[
                  {
                    name: 'Nome Completo',
                    acessor: 'name',
                  },
                  {
                    name: 'Data de Nascimento',
                    acessor: 'birthdayFormatted',
                  },
                  {
                    name: 'Tipo',
                    acessor: 'typeLocalized',
                  },
                  {
                    name: 'Celular',
                    acessor: 'phone',
                  },
                  {
                    name: 'Email',
                    acessor: 'email',
                  },
                ]}
              >
                <FontAwesomeIcon color="white" icon={faCloudDownloadAlt} />{' '}
                Exportar .xls
              </DownloadXlsButton>
            </div>
          </header>

          <div style={{ width: '100%', marginTop: 20, marginBottom: 20 }}>
            <div style={{ width: '500px' }} id="review-search">
              <i id="iconepesquisar" className="fa fa-search"></i>
              <input
                type="text"
                style={{
                  paddingLeft: '30px',
                  width: '100%',
                }}
                className="form-control foco-input"
                placeholder="Pesquisa por Nome Completo, Celular ou Email"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />
            </div>
          </div>

          <main>
            <ReactTable
              style={{ fontWeight: 'bold', textAlign: 'center' }}
              data={birthdays.filter(handleQueryFilter)}
              columns={[
                {
                  Header: 'Nome Completo',
                  accessor: 'name',
                  headerClassName: 'text-left',
                },
                {
                  Header: 'Data de Nascimento',
                  accessor: 'birthdayFormatted',
                  headerClassName: 'text-left',
                },
                {
                  Header: 'Tipo',
                  accessor: 'type',
                  headerClassName: 'text-left',
                  width: 150,
                  Cell: (props) => {
                    return props.value === 'CUSTOMER' ? (
                      <Button
                        id="botaoReactTable"
                        style={{
                          backgroundColor: '#428bca',
                          color: 'white',
                        }}
                      >
                        Cliente
                      </Button>
                    ) : (
                      <Button
                        id="botaoReactTable"
                        style={{
                          backgroundColor: '#5cb85c',
                          color: 'white',
                        }}
                      >
                        Funcionário
                      </Button>
                    )
                  },
                },
                {
                  Header: 'Celular',
                  accessor: 'phone',
                  headerClassName: 'text-left',
                  Cell: (props) => phoneMask(props.value) || null,
                },
                {
                  Header: 'Email',
                  accessor: 'email',
                  headerClassName: 'text-left',
                },
              ]}
              defaultPageSize={10}
              loading={loading}
              showPagination={true}
              sortable={true}
              showPaginationTop={false}
              showPaginationBottom={true}
              pageSizeOptions={[5, 10, 20, 25, 50, 100]}
              previousText="Anterior"
              nextText="Próximo"
              loadingText="Carregando..."
              noDataText={
                birthdaysHasLoaded
                  ? birthdays.length > 0
                    ? ''
                    : 'Não há aniversariantes no período'
                  : 'Selecione o período e o tipo para ver os aniversariantes.'
              }
              pageText="Página"
              ofText="de"
              rowsText="linhas"
            />
          </main>

          <div>
            <p
              style={{
                textAlign: 'end',
                margin: '20px',
                marginRight: '5rem',
              }}
            >
              Total de aniversariantes: {birthdays.length}
            </p>
          </div>
        </div>
      }
    />
  )
}
