import React, { useEffect, useState } from 'react';
import { change, reduxForm } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';

import CardForm from 'client/components/CardForm';
import AlertModal from '../../../../components/AlertModal/AlertModal';

import natureOfTransactionsRepository from '../../../../repositories/NatureOfTransactions';
import companyRepository from '../../../../repositories/Companies';
import taxesByStateRepository from '../../../../repositories/TaxesByState';
import typeUnityRepository from '../../../../repositories/TypeUnities';
import MainData from './MainData';
import Items from './Items';
import Freight from './Freight';
import OtherInfos from './OtherInfos';
import Footer from './Footer';

import './styles.css';
import { useAuth } from 'contexts/auth';

const FormNewNFe = ({
  handleSubmit,
  onConsultContingency,
  loading,
  setLoading,
  onCancel,
}) => {
  const [isMainCardOpen, setIsMainCardOpen] = useState(true);
  const [isItemsCardOpen, setIsItemsCardOpen] = useState(false);
  const [isFreightCardOpen, setIsFreightCardOpen] = useState(false);
  const [isOtherInfosCardOpen, setIsOtherInfosCardOpen] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);

  const { companyId } = useAuth();
  const dispatch = useDispatch();
  const { NFeId } = useSelector((state) => state.form.NFe.values);

  useEffect(() => {
    loadCFOPs();
    loadCompanyUF();
    loadCompanyTaxData();
    loadTaxesByState();
    loadTypeUnityitems();
  }, []);

  async function loadTypeUnityitems() {
    try {
      const typeUnities = await typeUnityRepository.getAll();
      dispatch(change('NFe', 'typeUnities', typeUnities));
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao buscar as unidades de medida. Por favor, tente novamente'
      );
    }
  }

  async function loadCFOPs() {
    try {
      const natureOftransactions =
        await natureOfTransactionsRepository.getAllByCompanyAndDefaults(
          companyId
        );
      dispatch(change('NFe', 'CFOPs', natureOftransactions));
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao carregar as CFOPs. Por favor, tente novamente'
      );
    }
  }

  async function loadCompanyUF() {
    try {
      const company = await companyRepository.getById(companyId);
      dispatch(change('NFe', 'companyUF', company.Address[0].State));
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao buscar os dados da empresa. Por favor, tente novamente'
      );
    }
  }

  async function loadCompanyTaxData() {
    try {
      const companyTaxData = await companyRepository.getTaxData(companyId);

      dispatch([
        change(
          'NFe',
          'isCompanyTaxRegimeSimpleNational',
          companyTaxData.TaxRegime === 'Simples Nacional'
        ),
        change('NFe', 'serie', companyTaxData.SettingsNFe?.serie),
        change('NFe', 'companyPIS', companyTaxData.PIS),
        change('NFe', 'companyCOFINS', companyTaxData.COFINS),
      ]);
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao buscar os dados da empresa. Por favor, tente novamente'
      );
    }
  }

  async function loadTaxesByState() {
    try {
      const taxesByState = await taxesByStateRepository.getAllByCompany(
        companyId
      );
      dispatch(change('NFe', 'taxesByState', taxesByState));
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao buscar os tributos por estado. Por favor, tente novamente'
      );
    }
  }

  return (
    <form id="NFe-form">
      <CardForm
        show={isMainCardOpen}
        title="Dados da Nota"
        proceedButton
        onProceed={() => {
          setIsMainCardOpen(false);
          setIsItemsCardOpen(true);
          setIsFreightCardOpen(true);
          setIsOtherInfosCardOpen(true);
        }}
        onClick={() => setIsMainCardOpen(!isMainCardOpen)}
      >
        <MainData setLoading={setLoading} />
      </CardForm>
      <CardForm
        show={isItemsCardOpen}
        title="Itens"
        proceedButton
        onProceed={() => {
          setIsMainCardOpen(false);
          setIsItemsCardOpen(false);
          setIsFreightCardOpen(true);
          setIsOtherInfosCardOpen(true);
        }}
        onClick={() => setIsItemsCardOpen(!isItemsCardOpen)}
      >
        <Items />
      </CardForm>

      <CardForm
        show={isFreightCardOpen}
        title="Frete/Transporte (Opcional)"
        proceedButton
        onProceed={() => {
          setIsMainCardOpen(false);
          setIsItemsCardOpen(false);
          setIsFreightCardOpen(false);
          setIsOtherInfosCardOpen(true);
        }}
        onClick={() => setIsFreightCardOpen(!isFreightCardOpen)}
      >
        <Freight />
      </CardForm>

      <CardForm
        show={isOtherInfosCardOpen}
        title="Outras Informações (Opcional)"
        onClick={() => setIsOtherInfosCardOpen(!isOtherInfosCardOpen)}
      >
        <OtherInfos />
      </CardForm>

      <Footer
        loading={loading}
        onConsultContingency={onConsultContingency}
        onCancel={() => (NFeId ? onCancel() : setIsCancelModalOpen(true))}
        onSubmit={handleSubmit}
      />

      {isCancelModalOpen && (
        <AlertModal
          show={isCancelModalOpen}
          onHide={() => setIsCancelModalOpen(false)}
          onCancel={() => setIsCancelModalOpen(false)}
          message="Essa ação não salvará nenhuma das informações apresentadas nessa tela. Deseja realmente sair ?"
          onSubmit={onCancel}
        />
      )}
    </form>
  );
};
export default reduxForm({
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  form: 'NFe',
})(FormNewNFe);
