import config from '../config'
import api from '../services/api'

const getAllBirthdays = async ({
  companyId,
  personType,
  initialDate,
  finalDate,
}) => {
  try {
    const response = await api.get(config.endpoint + `birthdays`, {
      params: { companyId, personType, initialDate, finalDate },
    })
    return response.data
  } catch (err) {
    throw err
  }
}

export default { getAllBirthdays }
