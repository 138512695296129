import React from 'react'
import { Col } from 'react-bootstrap'
import { Field, change } from 'redux-form'
import {
  forName,
  maxLength,
  onlyNumbers,
  specialChar,
  cepMask,
  name,
} from 'client/components/ToNormalize/ToNormalize'
import Input from 'client/components/Input/Input.jsx'
import api from 'services/api'
import config from 'config'
import { useEffect } from 'react'
import { useState } from 'react'
import { useDispatch } from 'react-redux'

const AddressData = () => {
  const [cities, setCities] = useState([])
  const [states, setStates] = useState([])
  const [disabled, setDisabled] = useState(false)

  const dispatch = useDispatch()
  const companyId = localStorage.getItem('ID_EMPRESA')

  useEffect(() => {
    loadCities()
    loadStates()

    if (companyId) {
      setDisabled(true)
    }
  }, [])

  const loadCities = async () => {
    const response = await api.get(config.endpoint + `address/cities`)
    setCities(response.data)
  }

  const loadStates = async () => {
    const response = await api.get(config.endpoint + `address/states`)
    setStates(response.data)
  }

  function handleCity(city) {
    switch (city) {
      case 'Birigüi':
        return 'Birigui'
      default:
        return city
    }
  }

  const handleCep = (event) => {
    let cep = event.target.value.replace(/\D/g, '')
    if (cep.length !== 8) {
      return setDisabled(false)
    }

    fetch('https://viacep.com.br/ws/' + cep + '/json/')
      .then((response) => response.json())
      .then((data) => {
        if (data.erro) return

        const state = states.find((state) => state.Initials === data.uf)

        if (state) {
          setStateCities(state.id)
        }

        setDisabled(true)
        const n = data.localidade.indexOf("'")
        if (n > 0) {
          let City = data.localidade.replace("'", '`')
          City = City.replace(
            City.charAt([n - 1]),
            City.charAt([n - 1]).toLowerCase()
          )
          data.localidade = City
        }

        dispatch([
          change('meusDados', 'Country', data.uf !== 'EX' ? 'Brasil' : ''),
          change('meusDados', 'Address', data.logradouro),
          change('meusDados', 'Complement', data.complemento),
          change('meusDados', 'Neighborhood', data.bairro),
          change('meusDados', 'City', handleCity(data.localidade)),
          change('meusDados', 'State', data.uf),
        ])
      })
  }

  const handleSetStates = async (event) => {
    const { value } = event.target
    const state = states.find((state) => state.Initials === value)

    if (!state) return

    setStateCities(state.id)
  }

  const setStateCities = async (stateId) => {
    const response = await api.get(
      config.endpoint + `address/cities/ByStateId/${stateId}`
    )
    setCities(response.data)
  }

  return (
    <div>
      <Col lg={2}>
        <label>
          CEP<span style={{ color: 'red' }}>*</span>
        </label>
        <Field
          disabled={true}
          component={Input}
          name="Zipcode"
          normalize={(value) => maxLength(cepMask(value), 10)}
          onBlur={handleCep}
        />
      </Col>
      <Col lg={4}>
        <label>
          Rua<span style={{ color: 'red' }}>*</span>
        </label>
        <Field
          disabled={true}
          component={Input}
          name="Address"
          normalize={(value) => maxLength(forName(value), 50)}
        />
      </Col>
      <Col lg={1}>
        <label>
          Número<span style={{ color: 'red' }}>*</span>
        </label>
        <Field
          disabled={true}
          component={Input}
          name="Address_Number"
          normalize={(value) => maxLength(onlyNumbers(value), 5)}
        />
      </Col>
      <Col lg={2}>
        <label>Complemento</label>
        <Field
          disabled={true}
          component={Input}
          name="Complement"
          normalize={(value) => maxLength(specialChar(value), 100)}
        />
      </Col>
      <Col lg={3}>
        <label>
          Bairro<span style={{ color: 'red' }}>*</span>
        </label>
        <Field
          disabled={true}
          component={Input}
          name="Neighborhood"
          normalize={(value) => maxLength(forName(value), 30)}
        />
      </Col>
      <Col lg={3}>
        <label>
          Estado<span style={{ color: 'red' }}>*</span>
        </label>
        <Field
          component="select"
          className="form-control foco-input"
          disabled={true}
          name="State"
          onChange={handleSetStates}
        >
          <option value="">Selecione</option>
          {states.map((e) => {
            return (
              <option key={e.id} value={e.Initials}>
                {e.Name}
              </option>
            )
          })}
        </Field>
      </Col>
      <Col lg={3}>
        <label>
          Cidade<span style={{ color: 'red' }}>*</span>
        </label>
        <Field
          component="select"
          className="form-control foco-input"
          disabled={true}
          name="City"
        >
          <option value="">Selecione</option>
          {cities.map((c) => {
            return (
              <option key={c.id} value={c.Name}>
                {c.Name}
              </option>
            )
          })}
        </Field>
      </Col>
      {/* <Col lg={2}>
                <label>País<span style={{ color: 'red' }}>*</span></label>
                <Field component={Input} name="Country" normalize={(value) => maxLength(name(value), 40)} />
            </Col> */}
    </div>
  )
}

export default AddressData
