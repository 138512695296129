import React, { useEffect, useState } from 'react'
import { toastr } from 'react-redux-toastr'
import { withRouter } from 'react-router'
import { useDispatch } from 'react-redux'
import { change } from 'redux-form'

import { onlyNumbers } from 'client/components/ToNormalize/ToNormalize'
import servicesRepository from '../../../../repositories/Services'
import defaultServicesRepository from 'repositories/DefaultServices'
import { useAuth } from '../../../../contexts/auth'
import constants from '../../../../utils/constants'

import FormService from './FormService'

const BundleFormService = ({ history, serviceId }) => {
  const [loading, setLoading] = useState(false)

  const { companyId, company } = useAuth()
  const dispatch = useDispatch()

  useEffect(() => {
    if (!!serviceId) {
      loadService()
    } else if (!!company) {
      loadLastCode()
    }
  }, [company])

  async function loadService() {
    setLoading(true)
    try {
      const service = await servicesRepository.getById(serviceId)

      const { data: defaultServices } = await defaultServicesRepository.getAll()

      const isDefaultService = defaultServices.some(
        (defaultService) => defaultService.description === service.Description
      )

      dispatch([
        change('service', 'description', service.Description),
        change('service', 'code', service.Code),
        change('service', 'price', service.Price),
        change('service', 'familyId', service.Family_id),
        change('service', 'warrantyId', service.Warranty_id),
        change('service', 'reviewId', service.Review_id),
        change(
          'service',
          'additionalInformation',
          service.Additional_Information
        ),
        change(
          'service',
          'hoursExpected',
          !service.Hours_Expected ? null : onlyNumbers(service.Hours_Expected)
        ),
        change('service', 'Commission_Rate', service.Commission_Rate),
        change('service', 'isActive', !!service.IsActive),
        change('service', 'descriptionEditDisabled', isDefaultService),
        change('service', 'companyIdFromService', service.Company_id),
        change('service', 'hasDiscountLimit', service.hasDiscountLimit),
        change('service', 'discountLimit', service.discountLimit),
      ])
    } catch (err) {
      console.log(err)
      toastr.warning(
        'Ocorreu um erro ao carregar o serviço. Por favor, tente novamente'
      )
    } finally {
      setLoading(false)
    }
  }

  async function loadLastCode() {
    setLoading(true)
    try {
      const code = await servicesRepository.getCompanyLastCode(companyId)
      dispatch([
        change('service', 'code', code + 1),
        change(
          'service',
          'Commission_Rate',
          company.defaultServiceCommissionRate
        ),
      ])
    } catch (err) {
      console.log(err)
      toastr.warning(
        'Ocorreu um erro ao buscar o código do Serviço. Por favor, tente novamente'
      )
    } finally {
      setLoading(false)
    }
  }

  async function handleSubmit(values) {
    const {
      description,
      familyId,
      price,
      warrantyId,
      hoursExpected,
      companyIdFromService,
      reviewId,
    } = values

    if (!description || !familyId || !price || !warrantyId || !reviewId) {
      return toastr.warning('Por favor, informe todos os dados para salvar')
    }
    if (!!hoursExpected) {
      if (!hoursExpected.match(/^[0-9][0-9][0-9][0-5][0-9]$/)) {
        return toastr.warning('Hora inválida', 'Digite uma hora válida.')
      }
    }

    const companyServicesAndDefaults =
      await servicesRepository.getAllByCompanyAndDefaults(companyId)
    const services = companyServicesAndDefaults.filter(
      (service) => service.Description === description.trim()
    )
    const hasServiceWithDescription = services.every(
      (service) => String(serviceId) !== String(service.id)
    )

    if (hasServiceWithDescription && !!services.length) {
      return toastr.warning('Serviço já existente')
    }

    if (!serviceId || !companyIdFromService) {
      create(values)
    } else {
      update(values)
    }
  }

  async function create(values) {
    const {
      description,
      familyId,
      code,
      price,
      isActive,
      warrantyId,
      reviewId,
      additionalInformation,
      hoursExpected,
      companyIdFromService,
      Commission_Rate,
      hasDiscountLimit,
      discountLimit,
    } = values
    setLoading(true)

    try {
      await servicesRepository.create({
        Description: description,
        Code: code,
        Price: price,
        Hours_Expected: !!hoursExpected
          ? hoursExpected.substring(0, 3) + ':' + hoursExpected.substring(3, 5)
          : null,
        IsActive: isActive,
        Company_id: companyId,
        Family_id: familyId,
        Warranty_id: warrantyId,
        Review_id: reviewId,
        Additional_Information: additionalInformation,
        Commission_Rate,
        hasDiscountLimit,
        discountLimit,
      })

      toastr.success(
        'Sucesso',
        !companyIdFromService && !!serviceId
          ? 'Serviço atualizado com sucesso'
          : `Serviço '${description}' cadastrado com sucesso`
      )
      history.push(constants.ROUTES.SERVICES)
    } catch (err) {
      toastr.warning(
        'Ocorreu um erro ao salvar o serviço. Por favor, tente novamente'
      )
    } finally {
      setLoading(false)
    }
  }

  async function update(values) {
    const {
      description,
      familyId,
      price,
      isActive,
      warrantyId,
      reviewId,
      additionalInformation,
      hoursExpected,
      Commission_Rate,
      hasDiscountLimit,
      discountLimit,
    } = values
    setLoading(true)

    try {
      await servicesRepository.update(serviceId, {
        Description: description,
        Price: price,
        Hours_Expected: !!hoursExpected
          ? hoursExpected.substring(0, 3) + ':' + hoursExpected.substring(3, 5)
          : null,
        IsActive: isActive,
        Family_id: familyId,
        Warranty_id: warrantyId,
        Review_id: reviewId,
        Additional_Information: additionalInformation,
        Commission_Rate,
        hasDiscountLimit,
        discountLimit,
      })

      toastr.success('Sucesso', `Serviço atualizado com sucesso`)
      history.push(constants.ROUTES.SERVICES)
    } catch (err) {
      toastr.warning(
        'Ocorreu um erro ao salvar o serviço. Por favor, tente novamente'
      )
    } finally {
      setLoading(false)
    }
  }

  return (
    <FormService
      initialValues={{
        isActive: true,
        companyIdFromService: null,
        additionalInformation: '',
      }}
      onSubmit={handleSubmit}
      loading={loading}
      serviceId={serviceId}
      onCancel={() => history.push(constants.ROUTES.SERVICES)}
    />
  )
}

export default withRouter(BundleFormService)
