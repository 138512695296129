import React, { useState, useEffect } from 'react'
import ReactTable from 'react-table'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import { toastr } from 'react-redux-toastr'
import './styles.css'

import SearchInput from 'components/SearchInput'
import Button from 'client/components/CustomButton/Button.jsx'
import DownloadXlsButton from 'components/DownloadXlsButton'
import { faCloudDownloadAlt, faEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import useFilters from 'hooks/useFilters'
import bankBilletAccountRepository from 'repositories/BankBilletAccount'
import { cpfOrCnpjMask } from 'client/components/ToNormalize/ToNormalize'
import { format, isAfter } from 'date-fns'
import { getDateOnlyFromDate } from 'utils/dateHelpers'

export default function TicketIssuanceHome() {
  const [loading, setLoading] = useState(false)
  const [companies, setCompanies] = useState([])
  const [status, setStatus] = useState()
  const [initialDate, setInitialDate] = useState()
  const [finalDate, setFinalDate] = useState()

  const { queryFilter } = useFilters()

  const { query, setQuery, filterByQuery } = queryFilter

  const history = useHistory()

  useEffect(() => {
    loadHomologatedCompanies({ status, initialDate, finalDate })
  }, [status, initialDate, finalDate])

  async function loadHomologatedCompanies({ status, initialDate, finalDate }) {
    if (initialDate && finalDate) {
      if (isAfter(new Date(initialDate), new Date(finalDate))) {
        return toastr.warning(
          'Datas inválidas',
          'A data inicial deve ser menor que a data final'
        )
      }
    }

    const companiesList = await bankBilletAccountRepository.index({
      status,
      initialDate,
      finalDate,
    })

    const formatedCompanies = companiesList.data.map((company) => ({
      ...company,
      bankName: company.TicketIssuanceBank.name,
      CompanyTradingName: company.Company.Trading_Name,
      CompanyCpfCnpj: cpfOrCnpjMask(company.Company.Cpf_Cnpj),
      createdAtF: format(new Date(company.createdAt), 'dd/MM/yyyy'),
      statusF: company.status ? 'Ativo' : 'Inativo',
    }))

    setCompanies(formatedCompanies)
  }

  // #To-do: Remover a filtragem por query do front e coloca-la no backend
  function handleQueryFilter(company) {
    const fields = [company.Company.Cpf_Cnpj, company.Company.Trading_Name]

    return filterByQuery(fields)
  }

  return (
    <main className='container-wrapper'>
      <section className='container-row'>
        <button
          className='btn btn-sucesso new-account'
          onClick={() => history.push('bank-billet-account')}
        >
          + Nova Carteira Bancária
        </button>
        <div className='search-input-wrapper'>
          <SearchInput
            placeholder='Pesquisa por CNPJ ou Razão Social'
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
        </div>
        <DownloadXlsButton
          archiveName={`Carteira-de-cobrança-${format(
            initialDate ? new Date(initialDate) : new Date(),
            'dd-MM-yyyy'
          )}-${format(
            finalDate ? new Date(finalDate) : new Date(),
            'dd-MM-yyyy'
          )}`}
          data={companies.filter(handleQueryFilter)}
          className='btn btn-export export-accounts'
          disabled={!companies.length}
          columns={[
            {
              name: 'Data',
              acessor: 'createdAtF',                            
            },
            {
              name: 'CNPJ',
              acessor: 'CompanyCpfCnpj',
            },
            {
              name: 'Razão Social',
              acessor: 'CompanyTradingName',
            },
            {
              name: 'Banco',
              acessor: 'bankName',
            },
            {
              name: 'ID Carteira',
              acessor: 'bankBilletId',
            },
            {
              name: 'Status',
              acessor: 'statusF',              
            },
          ]}
        >
          <FontAwesomeIcon
            color='white'
            style={{ marginRight: '10px' }}
            icon={faCloudDownloadAlt}
          />
          Exportar .xls
        </DownloadXlsButton>
      </section>
      <section className='container-row'>
        <fieldset>
          <label>Data Inicial:</label>
          <input
            type='date'
            className='form-control foco-input'
            value={initialDate}
            max={finalDate || '9999-12-31'}
            onChange={(e) => setInitialDate(e.target.value)}
          />
        </fieldset>
        <fieldset>
          <label>Data Final:</label>
          <input
            type='date'
            className='form-control foco-input'
            value={finalDate}
            min={initialDate}
            max={'9999-12-31'}
            onChange={(e) => setFinalDate(e.target.value)}
          />
        </fieldset>
        <fieldset>
          <label className='status-label'>Status:</label>
          <select
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            className='form-control foco-input'
          >
            <option value=''>Todos</option>
            <option value={true}>Ativo</option>
            <option value={false}>Inativo</option>
          </select>
        </fieldset>
      </section>
      <ReactTable
        style={{ fontWeight: 'bold', textAlign: 'center' }}
        data={companies.filter(handleQueryFilter)}
        columns={[
          {
            Header: 'Data',
            accessor: 'createdAt',
            Cell: (props) =>
              format(getDateOnlyFromDate(props.value), 'dd/MM/yyyy'),
            width: 150,
          },
          {
            Header: 'CNPJ',
            accessor: 'CompanyCpfCnpj',
            width: 175,
          },
          {
            Header: 'Razão Social',
            accessor: 'CompanyTradingName',
            width: 275,
          },
          {
            Header: 'Banco',
            accessor: 'bankName',
          },
          {
            Header: 'ID Carteira',
            accessor: 'bankBilletId',
          },
          {
            Header: 'Status',
            accessor: 'status',
            width: 150,
            Cell: (props) => (
              <Button
                id='botaoReactTable'
                style={{
                  width: '80%',
                  height: '100%',
                  backgroundColor: props.value === true ? '#5CB85C' : '#D9534F',
                }}
              >
                {props.value === true ? 'Ativo' : 'Inativo'}
              </Button>
            ),
          },
          {
            Header: 'Ações',
            accessor: 'id',
            Cell: (props) => (
              <Link
                to={{ pathname: `bank-billet-account#${props.value}` }}
                title='Editar Carteira Bancária'
              >
                <FontAwesomeIcon
                  cursor='pointer'
                  icon={faEdit}
                  style={{ width: '1.5em', height: '1.5em' }}
                />
              </Link>
            ),
          },
        ]}
        defaultPageSize={10}
        loading={loading}
        showPagination
        sortable
        showPaginationTop={false}
        showPaginationBottom
        pageSizeOptions={[5, 10, 20, 25, 50, 100]}
        previousText='Anterior'
        nextText='Próximo'
        loadingText='Carregando...'
        noDataText='Não há informação'
        pageText='Página'
        ofText='de'
        rowsText='linhas'
      />
    </main>
  )
}
