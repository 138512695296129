import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { toastr } from 'react-redux-toastr';
import { useDispatch } from 'react-redux';
import { change, initialize } from 'redux-form';
import { useAuth } from 'contexts/auth';

import api from '../../../../services/api';
import config from '../../../../config';
import purchasesRepository from '../../../../repositories/Purchases';
import providersRepository from '../../../../repositories/Providers';
import { listAllCSTConversor } from '../../../../v2/repositories/CSTConversorRepository';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';

import {
  cepMask,
  phoneMask,
  cnpjMask,
  currency,
} from '../../../components/ToNormalize/ToNormalize';

function ImportPage({ nextPage }) {
  const { companyId } = useAuth();

  const dispatch = useDispatch();

  const onDrop = useCallback(async (acceptedFiles) => {
    if (!acceptedFiles.length) return;

    await dispatch([
      change('PurchaseXmlImport', 'loading', true),
      change('PurchaseXmlImport', 'loadingMessage', 'Aguarde, carregando XML'),
    ]);

    const file = acceptedFiles[0];
    const reader = new FileReader();

    reader.readAsText(file);
    reader.onloadend = async () => {
      await xmlReader(reader.result);
      await dispatch(change('PurchaseXmlImport', 'loading', false));
    };
  }, []);

  const { getRootProps, getInputProps, isDragActive, isDragReject, open } =
    useDropzone({
      onDrop,
      accept: 'text/xml',
      multiple: false,
      onDropRejected,
      noClick: true,
      noKeyboard: true,
    });

  async function xmlReader(xml) {
    var span = document.createElement('span');
    span.appendChild(document.createTextNode(xml));
    var parser = new DOMParser();
    var xmlDoc = parser.parseFromString(span.innerText, 'text/xml');

    var ide = xmlDoc.getElementsByTagName('ide')[0];
    var emit = xmlDoc.getElementsByTagName('emit')[0];
    var dest = xmlDoc.getElementsByTagName('dest')[0];
    var det = xmlDoc.getElementsByTagName('det');
    var total = xmlDoc.getElementsByTagName('total')[0];

    var mod = ide.getElementsByTagName('mod')[0]?.childNodes[0]?.nodeValue;
    if (mod !== '55' && mod !== '65')
      return toastr.error(
        'OS Digital',
        'A importação de XML é válida apenas para NF-e e NFC-e'
      );

    var tpAmb = ide.getElementsByTagName('tpAmb')[0]?.childNodes[0].nodeValue;
    if (tpAmb !== '1')
      return toastr.error('OS Digital', 'Utilize uma XML de nota em produção');

    let cpfCnpjDest, cpfCnpjEmit;
    var destCNPJ = dest.getElementsByTagName('CNPJ');

    if (destCNPJ.length) {
      cpfCnpjDest = destCNPJ[0]?.childNodes[0]?.nodeValue;
    }
    var destCPF = dest.getElementsByTagName('CPF');

    if (destCPF.length) {
      cpfCnpjDest = destCPF[0]?.childNodes[0]?.nodeValue;
    }

    if (!cpfCnpjDest) {
      return toastr.error(
        'OS Digital',
        'A importação de XML só é válida quando a sua empresa é o destinatário. Caso não seja, faça a entrada manualmente ou entre em contato com o fornecedor.'
      );
    }

    try {
      const { data } = await api.get(config.endpoint + `company/${companyId}`);

      if (data.data.Cpf_Cnpj !== cpfCnpjDest)
        return toastr.error(
          'OS Digital',
          'A importação de XML só é válida quando a sua empresa é o destinatário. Caso não seja, faça a entrada manualmente ou entre em contato com o fornecedor.'
        );
    } catch (err) {
      console.log(err);
      return toastr.error(
        'Ocorreu algum erro inesperado.',
        'Por favor, tente novamente mais tarde.'
      );
    }

    var nfCode = ide.getElementsByTagName('nNF')[0]?.childNodes[0].nodeValue;
    var serie = ide.getElementsByTagName('serie')[0]?.childNodes[0].nodeValue;

    var emitCNPJ = emit.getElementsByTagName('CNPJ');
    if (emitCNPJ.length) {
      cpfCnpjEmit = emitCNPJ[0]?.childNodes[0].nodeValue;
    }
    var emitCPF = emit.getElementsByTagName('CPF');

    if (emitCPF.length) {
      cpfCnpjEmit = emitCPF[0]?.childNodes[0].nodeValue;
    }

    let stopMethod;
    try {
      const response = await purchasesRepository.getPurchases(companyId);

      await response.forEach((item) => {
        if (
          item.invoice === nfCode &&
          item.serie === serie &&
          item.Provider?.cpfCnpj === cpfCnpjEmit
        ) {
          const status = item.PurchaseStatus?.description?.toLowerCase();
          if (status.includes('abert') || status.includes('finaliz')) {
            return (stopMethod = true);
          }
        }
      });
    } catch (err) {
      console.log(err);
      return toastr.error(
        'Ocorreu algum erro inesperado.',
        'Por favor, tente novamente mais tarde.'
      );
    }

    if (stopMethod) {
      return toastr.error('XML já importado.');
    }

    let providerInDB;
    try {
      const response = await providersRepository.getAllByCompany(companyId);

      await response.forEach((prov) => {
        if (prov.cpfCnpj === cpfCnpjEmit) {
          const address = prov.Address.length
            ? {
                ...prov.Address[0],
                addressId: prov.Address[0].id,
                Zipcode: cepMask(prov.Address[0].Zipcode),
              }
            : {};
          delete address.id;
          const phone = prov.Phones.length
            ? {
                ...prov.Phones[0],
                phonesId: prov.Phones[0].id,
                Number_Phone1: phoneMask(prov.Phones[0].Number_Phone1),
              }
            : {};
          delete phone.id;
          delete prov.Address;
          return (providerInDB = {
            ...prov,
            ...address,
            ...phone,
          });
        }
      });
    } catch (err) {
      console.log(err);
      return toastr.error(
        'Ocorreu algum erro inesperado.',
        'Por favor, tente novamente mais tarde.'
      );
    }

    let providers;
    try {
      const response =
        await providersRepository.getProviderAndProductsByCompany(companyId);
      providers = response;
    } catch (err) {
      console.log(err);
      return toastr.error(
        'Ocorreu algum erro inesperado.',
        'Por favor, tente novamente mais tarde.'
      );
    }

    let allCSTConversor;
    try {
      const data = await listAllCSTConversor(companyId);
      allCSTConversor = data;
    } catch (err) {
      console.error(err);
      toastr.warning(
        'Ocorreu um erro ao buscar as Conversões de CST. Por favor, tente novamente!'
      );
    }

    const handleCSTConverter = (cst, cfop, csosn) => {
      if (!cst) {
        return {
          CSOSN: csosn,
          CFOP_InState_Code: '5' + cfop.substring(1),
          CFOP_OutState_Code: '6' + cfop.substring(1),
        };
      }

      const matchingConverter = allCSTConversor.find(
        (converter) =>
          converter.originCST === cst &&
          converter.originCFOPNumber.toString().replace('.', '') === cfop
      );

      if (matchingConverter) {
        const CSOSN = matchingConverter.convertedCSOSN.toString();
        const convertedCFOPNumber = matchingConverter.convertedCFOPNumber
          .toString()
          .replace('.', '');
        const CFOP_InState_Code = '5' + convertedCFOPNumber.substring(1);
        const CFOP_OutState_Code = '6' + convertedCFOPNumber.substring(1);
        return {
          CSOSN,
          CFOP_InState_Code,
          CFOP_OutState_Code,
        };
      }
    };

    let items = [];

    for (let i = 0; i < det.length; i++) {
      const cEAN = det[i].getElementsByTagName('cEAN')?.length
        ? det[i].getElementsByTagName('cEAN')[0]?.childNodes[0]?.nodeValue
        : '';
      const cEANTrib = det[i].getElementsByTagName('cEANTrib')?.length
        ? det[i].getElementsByTagName('cEANTrib')[0]?.childNodes[0]?.nodeValue
        : '';
      var icmsTag = det[i].getElementsByTagName('ICMS')[0];

      var CST = icmsTag.getElementsByTagName('CST')?.length
        ? icmsTag.getElementsByTagName('CST')[0]?.childNodes[0].nodeValue
        : '';
      var CSOSN = icmsTag.getElementsByTagName('CSOSN')?.length
        ? icmsTag.getElementsByTagName('CSOSN')[0]?.childNodes[0].nodeValue
        : '';

      // TAGS DE INFORMAÇÃO DO ICMS
      var ICMS = icmsTag.getElementsByTagName('pICMS')?.length
        ? icmsTag.getElementsByTagName('pICMS')[0]?.childNodes[0].nodeValue
        : '';
      var BCICMS = icmsTag.getElementsByTagName('vBC')?.length
        ? icmsTag.getElementsByTagName('vBC')[0]?.childNodes[0].nodeValue
        : '';
      var ICMSValue = icmsTag.getElementsByTagName('vICMS')?.length
        ? icmsTag.getElementsByTagName('vICMS')[0]?.childNodes[0].nodeValue
        : '';
      var BCICMSST = icmsTag.getElementsByTagName('vBCST')?.length
        ? icmsTag.getElementsByTagName('vBCST')[0]?.childNodes[0].nodeValue
        : '';
      var ICMSSTValue = icmsTag.getElementsByTagName('vICMSST')?.length
        ? icmsTag.getElementsByTagName('vICMSST')[0]?.childNodes[0].nodeValue
        : '';

      // TAGS PARA PEGAR INFORMAÇÃO DE PROD
      var prodTag = det[i].getElementsByTagName('prod')[0];
      var CFOP = prodTag.getElementsByTagName('CFOP')?.length
        ? prodTag.getElementsByTagName('CFOP')[0]?.childNodes[0].nodeValue
        : '';

      var CodANP = prodTag.getElementsByTagName('cProdANP')?.length
        ? prodTag.getElementsByTagName('cProdANP')[0]?.childNodes[0].nodeValue
        : '';
      var DescANP = prodTag.getElementsByTagName('descANP')?.length
        ? prodTag.getElementsByTagName('descANP')[0]?.childNodes[0].nodeValue
        : '';
      var UFANP = prodTag.getElementsByTagName('UFCons')?.length
        ? prodTag.getElementsByTagName('UFCons')[0]?.childNodes[0].nodeValue
        : '';

      // Tags de IPI - IPITrib - IPINT - IPIDevol
      var ipiTribTag = det[i].getElementsByTagName('IPITrib')?.length
        ? det[i].getElementsByTagName('IPITrib')[0]
        : [];
      var ipiIntTag = det[i].getElementsByTagName('IPINT')?.length
        ? det[i].getElementsByTagName('IPINT')[0]
        : [];
      var ipiDevolTag = det[i].getElementsByTagName('IPIDevol')?.length
        ? det[i].getElementsByTagName('IPIDevol')[0]
        : [];

      var IPI = ipiTribTag?.length
        ? ipiTribTag.getElementsByTagName('pIPI')?.length
          ? ipiTribTag.getElementsByTagName('pIPI')[0]?.childNodes[0].nodeValue
          : ipiIntTag?.length
          ? ipiIntTag.getElementsByTagName('pIPI')?.length
            ? ipiIntTag.getElementsByTagName('pIPI')[0]?.childNodes[0].nodeValue
            : ipiDevolTag?.length
            ? ipiDevolTag.getElementsByTagName('pIPI')?.length
              ? ipiDevolTag.getElementsByTagName('pIPI')[0]?.childNodes[0]
                  .nodeValue
              : ''
            : ''
          : ''
        : '';

      var BCIPI = ipiTribTag?.length
        ? ipiTribTag.getElementsByTagName('vBC')?.length
          ? ipiTribTag.getElementsByTagName('vBC')[0]?.childNodes[0].nodeValue
          : ipiIntTag?.length
          ? ipiIntTag.getElementsByTagName('vBC')?.length
            ? ipiIntTag.getElementsByTagName('vBC')[0]?.childNodes[0].nodeValue
            : ipiDevolTag?.length
            ? ipiDevolTag.getElementsByTagName('vBC')?.length
              ? ipiDevolTag.getElementsByTagName('vBC')[0]?.childNodes[0]
                  .nodeValue
              : ''
            : ''
          : ''
        : '';

      var IPIValue = ipiTribTag?.length
        ? ipiTribTag.getElementsByTagName('vIPI')?.length
          ? ipiTribTag.getElementsByTagName('vIPI')[0]?.childNodes[0].nodeValue
          : ipiIntTag?.length
          ? ipiIntTag.getElementsByTagName('vIPI')?.length
            ? ipiIntTag.getElementsByTagName('vIPI')[0]?.childNodes[0].nodeValue
            : ipiDevolTag?.length
            ? ipiDevolTag.getElementsByTagName('vIPI')?.length
              ? ipiDevolTag.getElementsByTagName('vIPI')[0]?.childNodes[0]
                  .nodeValue
              : ''
            : ''
          : ''
        : '';

      // INFORMAÇÕES PARA PIS
      var PISAliq =
        det[i].getElementsByTagName('PISAliq')[0] ||
        det[i].getElementsByTagName('PISNT')[0];
      let CSTPIS = '';
      let PISValue = '';
      if (PISAliq) {
        CSTPIS = PISAliq.getElementsByTagName('CST')?.length
          ? PISAliq.getElementsByTagName('CST')[0]?.childNodes[0].nodeValue
          : '';
        PISValue = PISAliq.getElementsByTagName('vPIS')?.length
          ? PISAliq.getElementsByTagName('vPIS')[0]?.childNodes[0].nodeValue
          : '';
      }

      // INFORMAÇÕES PARA COFINS
      var COFINSAliq =
        det[i].getElementsByTagName('COFINSAliq')[0] ||
        det[i].getElementsByTagName('COFINSNT')[0];
      let CSTCOFINS = '';
      let COFINSValue = '';
      if (COFINSAliq) {
        CSTCOFINS = COFINSAliq.getElementsByTagName('CST')?.length
          ? COFINSAliq.getElementsByTagName('CST')[0]?.childNodes[0].nodeValue
          : '';
        COFINSValue = COFINSAliq.getElementsByTagName('vCOFINS')?.length
          ? COFINSAliq.getElementsByTagName('vCOFINS')[0]?.childNodes[0]
              .nodeValue
          : '';
      }
      const vUnCom = parseFloat(
        det[i].getElementsByTagName('vUnCom')[0]?.childNodes[0].nodeValue
      ).toFixed(2);
      const unitDiscount = parseFloat(
        det[i].getElementsByTagName('vDesc')[0]?.childNodes[0].nodeValue || 0
      ).toFixed(2);
      const value = vUnCom - unitDiscount;

      let providerCode =
        det[i].getElementsByTagName('cProd')[0]?.childNodes[0].nodeValue;
      let productCode,
        productId,
        multiplierBase,
        brand,
        isCalculateStOnSale = false;

      providers.forEach(({ cpfCnpj, Products }) => {
        if (Products.length) {
          Products.forEach(
            ({
              id,
              code,
              ProvidersProducts,
              Multiplier_Base,
              Brands,
              calculateStOnSale,
            }) => {
              if (
                ProvidersProducts.productProviderCode === providerCode &&
                cpfCnpj === cpfCnpjEmit
              ) {
                productId = id;
                productCode = code;
                multiplierBase = Multiplier_Base === null ? 1 : Multiplier_Base;
                isCalculateStOnSale = calculateStOnSale;
                brand =
                  Brands === null
                    ? {
                        value: '*',
                        label: 'Selecione',
                      }
                    : {
                        value: Brands.id,
                        label: Brands.Description,
                      };
                return;
              }
            }
          );
        }
      });

      const convertedCSOSNandCFOP = handleCSTConverter(CST, CFOP, CSOSN);
      let CFOP_InState_Code;
      let CFOP_OutState_Code;

      if (convertedCSOSNandCFOP) {
        CSOSN = convertedCSOSNandCFOP.CSOSN;
        CFOP_InState_Code = convertedCSOSNandCFOP.CFOP_InState_Code;
        CFOP_OutState_Code = convertedCSOSNandCFOP.CFOP_OutState_Code;
      }
      items.push({
        productId,
        productCode,
        providerCode,
        brand,
        description:
          det[i].getElementsByTagName('xProd')[0]?.childNodes[0].nodeValue,
        quantity:
          det[i].getElementsByTagName('qCom')[0]?.childNodes[0].nodeValue,
        unitValue: !!isCalculateStOnSale
          ? Number(value) + Number(ICMSSTValue)
          : Number(value),
        unitDiscountValue: Number(unitDiscount),
        margin: 100,
        newMargin: 100,
        newSalesPrice: value * 2,
        insurance: 0,
        otherExpenses: 0,
        salesPrice: value * 2,
        formattedSalesPrice: currency(value * 2),
        multiplierBase: productId ? multiplierBase : 1,
        cEAN: cEAN?.toLowerCase()?.includes('sem') ? '' : cEAN,
        cEANTrib: cEANTrib?.toLowerCase()?.includes('sem') ? '' : cEANTrib,
        unitType:
          det[i].getElementsByTagName('uCom')[0]?.childNodes[0].nodeValue,
        ncm: det[i].getElementsByTagName('NCM')[0]?.childNodes[0].nodeValue,
        cest: det[i].getElementsByTagName('CEST')[0]?.childNodes[0].nodeValue,
        cfop: det[i].getElementsByTagName('CFOP')[0]?.childNodes[0].nodeValue,
        origin: det[i].getElementsByTagName('orig')[0]?.childNodes[0].nodeValue,
        CSTorCSOSN:
          CST !== ''
            ? `${
                det[i].getElementsByTagName('orig')[0]?.childNodes[0].nodeValue
              }${CST}`
            : `${
                det[i].getElementsByTagName('orig')[0]?.childNodes[0].nodeValue
              }${CSOSN}`,
        CST,
        CSOSN,
        CFOP_InState_Code,
        CFOP_OutState_Code,
        ICMS,
        BCICMS,
        ICMSValue,
        BCICMSST,
        ICMSSTValue,
        freightValue: 0,
        IPI,
        BCIPI,
        IPIValue,
        CSTPIS,
        PISValue,
        CSTCOFINS,
        COFINSValue,
        CodANP,
        DescANP,
        UFANP,
      });
    }

    const cepEmit =
      emit.getElementsByTagName('CEP')[0]?.childNodes[0].nodeValue;

    const viaCepEmit = cepEmit
      ? await fetch('https://viacep.com.br/ws/' + cepEmit + '/json/').then(
          (resp) => {
            return resp.json();
          }
        )
      : null;

    const provider = {
      companyName:
        emit.getElementsByTagName('xNome')[0]?.childNodes[0].nodeValue,
      email: emit.getElementsByTagName('email')[0]?.childNodes[0].nodeValue,
      tradingName:
        emit.getElementsByTagName('xFant').length > 0
          ? emit.getElementsByTagName('xFant')[0]?.childNodes[0].nodeValue
          : emit.getElementsByTagName('xNome')[0]?.childNodes[0].nodeValue,
      cpfCnpj: cnpjMask(cpfCnpjEmit),
      IE: emit.getElementsByTagName('IE')[0]?.childNodes[0].nodeValue,
      Number_Phone1: phoneMask(
        emit.getElementsByTagName('fone')[0]?.childNodes[0].nodeValue
      ),
      Zipcode: cepMask(cepEmit),
      Address: emit.getElementsByTagName('xLgr')[0]?.childNodes[0].nodeValue,
      Address_Number:
        emit.getElementsByTagName('nro')[0]?.childNodes[0].nodeValue,
      Neighborhood:
        emit.getElementsByTagName('xBairro')[0]?.childNodes[0].nodeValue,
      City: viaCepEmit?.localidade,
      State: viaCepEmit?.uf,
      Complement: emit.getElementsByTagName('xCpl')
        ? emit.getElementsByTagName('xCpl')[0]?.childNodes[0]?.nodeValue
        : null,
    };

    await dispatch([initialize('PurchaseXmlImport', provider)]);

    var vBC = total.getElementsByTagName('vBC')[0]?.childNodes[0].nodeValue;
    var vICMS = total.getElementsByTagName('vICMS')[0]?.childNodes[0].nodeValue;
    var vBCST = total.getElementsByTagName('vBCST')[0]?.childNodes[0].nodeValue;
    var vST = total.getElementsByTagName('vST')[0]?.childNodes[0].nodeValue;
    var vProd = total.getElementsByTagName('vProd')[0]?.childNodes[0].nodeValue;
    var vFrete =
      total.getElementsByTagName('vFrete')[0]?.childNodes[0].nodeValue;
    var vSeg = total.getElementsByTagName('vSeg')[0]?.childNodes[0].nodeValue;
    var vDesc = total.getElementsByTagName('vDesc')[0]?.childNodes[0].nodeValue;
    var vIPI = total.getElementsByTagName('vIPI')[0]?.childNodes[0].nodeValue;
    var vPIS = total.getElementsByTagName('vPIS')[0]?.childNodes[0].nodeValue;
    var vCOFINS =
      total.getElementsByTagName('vCOFINS')[0]?.childNodes[0].nodeValue;
    var vOutro =
      total.getElementsByTagName('vOutro')[0]?.childNodes[0].nodeValue;
    var vNF = total.getElementsByTagName('vNF')[0]?.childNodes[0].nodeValue;
    var vFCP = total.getElementsByTagName('vFCP')
      ? total.getElementsByTagName('vFCP')[0]?.childNodes[0]?.nodeValue
      : null;
    var vFCPST = total.getElementsByTagName('vFCPST')
      ? total.getElementsByTagName('vFCPST')[0]?.childNodes[0]?.nodeValue
      : null;
    var vFCPSTRet = total.getElementsByTagName('vFCPSTRet')
      ? total.getElementsByTagName('vFCPSTRet')[0]?.childNodes[0]?.nodeValue
      : null;

    var accessKey =
      xmlDoc.getElementsByTagName('chNFe')[0]?.childNodes[0].nodeValue;
    var dhEmi = ide.getElementsByTagName('dhEmi')[0]?.childNodes[0].nodeValue;

    await dispatch([
      change('PurchaseXmlImport', 'providerInDB', providerInDB),
      change('PurchaseXmlImport', 'items', items),
      change('PurchaseXmlImport', 'vBC', vBC),
      change('PurchaseXmlImport', 'vICMS', vICMS),
      change('PurchaseXmlImport', 'vBCST', vBCST),
      change('PurchaseXmlImport', 'vST', vST),
      change('PurchaseXmlImport', 'vProd', vProd),
      change('PurchaseXmlImport', 'vFrete', vFrete),
      change('PurchaseXmlImport', 'vSeg', vSeg),
      change('PurchaseXmlImport', 'vDesc', vDesc),
      change('PurchaseXmlImport', 'vIPI', vIPI),
      change('PurchaseXmlImport', 'vPIS', vPIS),
      change('PurchaseXmlImport', 'vCOFINS', vCOFINS),
      change('PurchaseXmlImport', 'vOutro', vOutro),
      change('PurchaseXmlImport', 'vNF', vNF),
      change('PurchaseXmlImport', 'vFCP', vFCP),
      change('PurchaseXmlImport', 'vFCPST', vFCPST),
      change('PurchaseXmlImport', 'vFCPSTRet', vFCPSTRet),
      change('PurchaseXmlImport', 'nfCode', nfCode),
      change('PurchaseXmlImport', 'serie', serie),
      change('PurchaseXmlImport', 'mod', mod),
      change('PurchaseXmlImport', 'accessKey', accessKey),
      change('PurchaseXmlImport', 'dhEmi', dhEmi),
    ]);

    nextPage();
  }

  function onDropRejected(filesRejections) {
    const error = filesRejections[0].errors[0].message;

    if (error === 'Too many files') {
      toastr.error('Insira somente um único arquivo XML');
    }
  }

  const dragActive = {
    borderColor: 'rgb(4, 182, 4)',
  };

  const dragReject = {
    borderColor: '#e57878',
  };

  const DropzoneContent = () => (
    <div className="xml-import-dropzone-content">
      <FontAwesomeIcon
        style={{
          height: '80px',
          width: '80px',
          color: '#c0c0c0',
        }}
        icon={faCloudUploadAlt}
      />
      {renderMessage(isDragActive, isDragReject)}
    </div>
  );

  const renderMessage = () => {
    if (!isDragActive) {
      return (
        <>
          <span>Arraste e solte o arquivo XML</span>
          <span>
            ou{' '}
            <button
              style={{
                border: 'none',
                background: '#fff',
                color: '#4089A2',
                cursor: 'pointer',
              }}
              onClick={open}
            >
              selecione o arquivo de seu computador
            </button>
          </span>
        </>
      );
    }

    if (isDragReject) {
      return <span type="error">Arquivo não suportado</span>;
    }

    return <span type="success">Solte os arquivos aqui</span>;
  };

  return (
    <div
      {...getRootProps()}
      className="xml-page-import"
      style={{
        ...(isDragActive ? dragActive : null),
        ...(isDragReject ? dragReject : null),
      }}
    >
      <input {...getInputProps()} />
      <DropzoneContent />
    </div>
  );
}

export default ImportPage;
