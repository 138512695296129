import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { endOfMonth, startOfMonth, format, isAfter } from 'date-fns';
import ReactTable from 'react-table';
import { toastr } from 'react-redux-toastr';

import customersRepository from 'repositories/Customers';
import salesRepository from 'repositories/Sales';
import { useAuth } from 'contexts/auth';

import { currency } from 'client/components/ToNormalize/ToNormalize';
import Button from 'client/components/CustomButton/Button.jsx';
import { cpfOrCnpjMask } from 'client/components/ToNormalize/ToNormalize';
import CustomButton from 'client/components/CustomButton/CustomButton';
import useGroupTitlesModalStore from '../store';
import { currentBrandingName } from 'v2/helpers/brandingHelpers';

const selectStyles = {
  control: (base) => ({
    ...base,
    width: '400px',
    height: '35px',
    minHeight: '35px',
    borderColor: '#E3E3E3',
    cursor: 'pointer',
    zIndex: 10000,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  valueContainer: (provided) => ({
    ...provided,
    marginTop: '-6px',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    marginTop: '-6px',
  }),
};

function renderSaleTypeButton(props) {
  return (
    <Button
      id="botaoReactTable"
      style={{
        zIndex: '0',
        width: '100%',
        height: '100%',
        backgroundColor:
          props.value === 'Orçamento'
            ? '#363636'
            : props.value === 'Venda'
            ? 'purple'
            : props.value === 'PDV'
            ? '#ffa834'
            : '#00008B',
      }}
    >
      {props.value}
    </Button>
  );
}

const SalesTable = ({
  sales,
  isLoadingSales,
  loadedSales,
  handleSaleSelection,
  page,
  setPage,
  pageSize,
  setPageSize,
  pages,
}) => {
  const { isSaleSelected } = useGroupTitlesModalStore();

  return (
    <div style={{ marginTop: '10px', height: '100%' }}>
      <ReactTable
        style={{
          fontWeight: 'bold',
          textAlign: 'center',
          width: '100%',
          fontSize: '13px',
        }}
        data={sales}
        columns={[
          {
            Header: 'Data',
            accessor: 'Date',
            headerClassName: 'text-center',
            className: 'texto',
            width: 100,
            Cell: (props) => format(new Date(props.value), 'dd/MM/yyyy'),
          },
          {
            Header: 'Tipo',
            accessor: 'SalesType.Description',
            headerClassName: 'text-center',
            width: 120,
            className: 'texto',
            Cell: (props) => renderSaleTypeButton(props),
          },
          {
            Header: 'N.º Venda',
            accessor: 'Code',
            headerClassName: 'text-center',
            className: 'texto',
            width: 85,
          },
          {
            Header: 'Cliente',
            accessor: 'Customer.Company_Name',
            headerClassName: 'text-center',
            className: 'texto',
          },
          {
            Header: 'Placa/Obj. Manut',
            accessor: 'License_Plate',
            headerClassName: 'text-center',
            width: 130,
            className: 'texto',
            Cell: (props) => (
              <span>
                {props.original.Vehicle?.Maintenance_Object
                  ? props.original.Vehicle?.Maintenance_Object
                  : props.original.License_Plate}
              </span>
            ),
          },
          {
            Header: 'Valor',
            accessor: 'Final_Value',
            headerClassName: 'text-center',
            className: 'texto',
            width: 120,
            Cell: (props) => <a>{currency(props.value)}</a>,
          },
          {
            Header: '',
            accessor: 'id',
            headerClassName: 'text-center',
            filterable: false,
            className: 'texto',
            width: 80,
            style: {
              display: 'flex',
              justifyContent: 'center',
              margin: 'auto 0',
            },
            Cell: (props) => (
              <input
                type="checkbox"
                checked={isSaleSelected(props.original.id)}
                onChange={() => handleSaleSelection(props.original)}
              />
            ),
          },
        ]}
        sortable
        showPagination
        loading={isLoadingSales}
        defaultPageSize={10}
        showPaginationBottom
        showPageSizeOptions={false}
        defaultSorted={[{ id: 'Date', desc: true }]}
        page={page}
        pages={pages}
        onPageChange={(value) => setPage(value)}
        pageSize={pageSize}
        onPageSizeChange={(value) => {
          setPageSize(value);
        }}
        manual
        previousText="Anterior"
        nextText="Próximo"
        loadingText="Carregando..."
        noDataText={
          loadedSales
            ? 'Nenhuma informação encontrada'
            : 'Selecione o cliente e o período para buscar as Venda/OS finalizadas'
        }
        pageText="Página"
        ofText="de"
        rowsText="linhas"
      />
    </div>
  );
};

export function CustomerStep() {
  const [loadedSales, setLoadedSales] = useState(false);
  const [isLoadingSales, setIsLoadingSales] = useState(false);
  const [customersOptions, setCustomersOptions] = useState([]);

  const [page, setPage] = useState(0);
  const [pages, setPages] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const { companyId } = useAuth();

  const {
    initialDate,
    setInitialDate,
    finalDate,
    setFinalDate,
    sales,
    selectedSales,
    setSales,
    selectedCustomer,
    setSelectedCustomer,
    setSelectedSales,
    isSaleSelected,
  } = useGroupTitlesModalStore();

  async function getCompanySales() {
    if (!selectedCustomer) {
      return toastr.warning(
        currentBrandingName,
        'Selecione um cliente para fazer a busca'
      );
    }

    if (isAfter(new Date(initialDate), new Date(finalDate))) {
      return toastr.warning(
        'Busca não realizada',
        'Selecione um período válido para fazer a busca'
      );
    }

    setIsLoadingSales(true);
    try {
      const companySales = await salesRepository.getAllByCompany(companyId, {
        initialDate,
        finalDate,
        status: 5,
        limit: pageSize,
        customerId: selectedCustomer.value.id,
        page: page + 1,
        noInvoices: true,
      });

      setPages(Math.ceil(companySales.count / pageSize));
      setSales(companySales.sales);
    } catch (err) {
      console.log(err);
      toastr.error(
        'Falha ao Carregar as Vendas',
        'Tente novamente. Caso persista, contate o suporte!'
      );
    } finally {
      setLoadedSales(true);
      setIsLoadingSales(false);
    }
  }

  async function getActiveCustomers() {
    try {
      const customers = await customersRepository.getAllActiveByCompany(
        companyId
      );

      setCustomersOptions(
        customers.map((customer) => ({
          value: {
            id: customer.id,
            name: customer.Trading_Name || customer.Company_Name,
            cpfCnpj: customer.Cpf_Cnpj,
            email: customer.Email,
            phone: customer.Phones[0]?.Number_Phone2,
          },
          label: `${
            customer.Cpf_Cnpj && cpfOrCnpjMask(customer.Cpf_Cnpj) + ' - '
          }${customer.Trading_Name || customer.Company_Name}`,
        }))
      );
    } catch (err) {
      console.log(err);
      toastr.error(
        'Falha ao Carregar os Clientes',
        'Tente novamente. Caso persista, contate o suporte!'
      );
    }
  }

  function handleSaleSelection(sale) {
    const isSelected = isSaleSelected(sale.id);

    if (isSelected) {
      setSelectedSales(
        selectedSales.filter((currSale) => currSale.id !== sale.id)
      );

      return;
    }

    setSelectedSales([...selectedSales, sale]);
  }

  useEffect(() => {
    getActiveCustomers();
  }, []);

  useEffect(() => {
    if (selectedCustomer) {
      getCompanySales();
    }
  }, [page, pageSize]);

  return (
    <div style={{ paddingTop: '30px' }}>
      <div style={{ display: 'flex', gap: '10px', alignItems: 'flex-end' }}>
        <div>
          <Select
            options={customersOptions}
            onChange={(opt) => {
              setPage(0);
              setSelectedCustomer(opt);
            }}
            value={selectedCustomer}
            noOptionsMessage={() => 'Nenhuma opção encontrada'}
            isSearchable
            placeholder={'Pesquisa por CPF/CNPJ ou Nome/Razão Social'}
            id="customerSelect"
            styles={selectStyles}
          />
        </div>
        <div>
          <label htmlFor="initialDateInput">Data Inicial:</label>
          <input
            className="form-control"
            type="date"
            max={finalDate}
            name="initialDate"
            onChange={(e) => setInitialDate(e.target.value)}
            value={initialDate}
            id="initialDateInput"
          />
        </div>
        <div>
          <label htmlFor="finalDateInput">Data Final:</label>
          <input
            className="form-control"
            type="date"
            min={initialDate}
            onChange={(e) => setFinalDate(e.target.value)}
            value={finalDate}
            name="initialDate"
            id="finalDateInput"
          />
        </div>
        <div>
          <CustomButton
            fill
            style={{
              background: '#5DB85B',
              borderColor: '#5DB85B',
              fontSize: '14px',
              height: '35px',
              display: 'flex',
              alignItems: 'center',
            }}
            onClick={getCompanySales}
          >
            Processar
          </CustomButton>
        </div>
      </div>

      <SalesTable
        sales={sales}
        page={page}
        setPage={setPage}
        setPageSize={setPageSize}
        pageSize={pageSize}
        isLoadingSales={isLoadingSales}
        pages={pages}
        getCompanySales={getCompanySales}
        loadedSales={loadedSales}
        selectedCustomer={selectedCustomer}
        handleSaleSelection={handleSaleSelection}
      />

      {/* {isConfirmationModalOpen && (
        <AlertModal
          show={true}
          onCancel={() => setIsConfirmationModalOpen(false)}
          onHide={() => null}
          onSubmit={handleSubmit}
          message={generateConfirmationMessage()}
        />
      )} */}
    </div>
  );
}
