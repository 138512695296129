import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReactTable from 'react-table';
import {
  faTrashAlt,
  faCheckSquare,
  faTimes,
  faEdit,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PercentInput from 'client/components/Percent';
import CurrencyInput from 'client/components/Currency';
import {
  currency,
  percentage,
} from 'client/components/ToNormalize/ToNormalize';
import {
  editItem,
  handleChange,
  removeItem,
  confirmEraseItem,
  handleConfirmEditItem,
  handleChangeItemEditable,
} from './redux/actions';
import AlertModal from 'components/AlertModal/AlertModal';
import { toastr } from 'react-redux-toastr';
import './styles.css';
import InputNumberDecimal from 'client/components/InputNumberDecimal';
import { useAuth } from 'contexts/auth';
import { ItemCancellationActionButtons } from './components/ItemCancellationActionButtons';
import { ModalDiscountReleaseWithPassword } from 'v2/components/Modals/DiscountRelease/ModalDiscountReleaseWithPassword';
import { useSidebar } from 'v2/hooks/useSidebar/useSidebar';

const getItemColorByCurrentStatus = (isCancelled) => {
  if (isCancelled) {
    return 'text-red';
  }
};

export default function ItemsTable() {
  const { saleReducer } = useSelector((state) => state);
  const [
    isModalDiscountReleaseWithPasswordOpen,
    setIsModalDiscountReleaseWithPasswordOpen,
  ] = useState(false);
  const {
    items,
    loading,
    eraseItemModal,
    itemToRemove,
    itemToEdit,
    saleStatusId,
    companyConfig,
  } = saleReducer;
  const dispatch = useDispatch();
  const { company } = useAuth();
  const [itemToEditIndex, setItemToEditIndex] = useState(null);
  const { validateFeatureAvailability } = useSidebar();

  const isAllowedDiscountGreater = validateFeatureAvailability(
    {
      subFeatureId: 118,
    },
    false
  );

  const checkDiscount = async (value) => {
    let discount = parseFloat(value);

    if (discount > 99.99) discount = 0;

    await dispatch(
      handleChangeItemEditable(discount, 'Discount_Value', itemToEdit)
    );

    document.getElementById('discountInput').focus();
  };

  const checkTotal = async (value) => {
    let total = parseFloat(value);

    if (total === 0) {
      toastr.warning(
        'Não autorizado',
        `O valor do item não pode ser R$ 0,00, informe no minímo R$ 0,01 como valor do item`
      );
      return;
    }

    if (itemToEdit.Quantity === 0) {
      let amount =
        itemToEdit.Discount_Value === 0
          ? itemToEdit.Unit_Value.toFixed(2)
          : (
              itemToEdit.Unit_Value -
              (itemToEdit.Unit_Value * itemToEdit.Discount_Value) / 100
            ).toFixed(2);
      if (total > parseFloat(amount)) {
        toastr.warning(
          'Total inválido',
          'Valor total não deve ser maior que o somatório'
        );
        total = amount;
      }
    } else {
      let amount =
        itemToEdit.Discount_Value === 0
          ? (
              itemToEdit.Unit_Value *
              parseFloat(itemToEdit.Quantity.toString().replace(',', '.'))
            ).toFixed(2)
          : (
              parseFloat(itemToEdit.Quantity.toString().replace(',', '.')) *
              (itemToEdit.Unit_Value -
                (itemToEdit.Unit_Value * itemToEdit.Discount_Value) / 100)
            ).toFixed(2);
      if (total > parseFloat(amount)) {
        toastr.warning(
          'Total inválido',
          'Valor total não deve ser maior que o somatório'
        );
        total = amount;
      }
    }

    await dispatch(handleChangeItemEditable(total, 'Amount', itemToEdit));

    document.getElementById('totalInput').focus();
  };

  const handleChangeEditableQuantity = async (value) => {
    await dispatch(handleChangeItemEditable(value, 'Quantity', itemToEdit));
    document.getElementById('input-number-sale').focus();
  };

  const handleUnityValue = async (inputValue) => {
    const value = Number(inputValue);

    if (value === 0)
      return toastr.warning('Não é possível informar um valor R$ 0,00.');

    if (value > 0) {
      await dispatch(handleChangeItemEditable(value, 'Unit_Value', itemToEdit));
      document.getElementById('unityInput').focus();
    }
  };

  const handleChangeDescriptionUpdate = async (value) => {
    await dispatch(
      handleChangeItemEditable(value.toUpperCase(), 'Description', itemToEdit)
    );
    document.getElementById('description-input').focus();
  };

  const handleEditIemClick = (props) => {
    if (props.original.isCancelled) {
      toastr.warning(
        'Item cancelado',
        'Não é possível editar um item cancelado'
      );
      return;
    }

    return (
      saleStatusId !== 5 &&
      saleStatusId !== 2 &&
      dispatch(editItem(props.index, items))
    );
  };

  const ContentDeleteConfirmationModal = () => (
    <div>
      <span>
        <strong>Deseja realmente excluir o item?</strong>
      </span>
      <br />
      <br />
      <span>
        A exclusão de item é permanente, mas poderá ser adicionado novamente via
        busca itens.
      </span>
      <br />
      <br />
      <span
        style={{
          fontSize: '12px',
          color: '#d43f3a',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        Ao excluir o item, se houver apontamento vinculado, também será
        excluído.
      </span>
    </div>
  );

  const handleConfirmItem = async (item) => {
    const isAboveLimit =
      item.hasDiscountLimit &&
      parseFloat(item.discountLimit) < item.Discount_Value;

    if (isAboveLimit) {
      if (company.companyConfig.discountAboveLimit === 'PASSWORD_REQUIRED') {
        await dispatch(
          handleConfirmEditItem(
            itemToEditIndex,
            itemToEdit,
            items,
            isAllowedDiscountGreater,
            setIsModalDiscountReleaseWithPasswordOpen
          )
        );
        return;
      } else {
        toastr.warning(
          'Não autorizado',
          `O desconto inserido é maior que o valor permitido (Desconto máximo: ${item.discountLimit}%)`
        );
        return;
      }
    }

    await dispatch(
      handleConfirmEditItem(
        itemToEditIndex,
        itemToEdit,
        items,
        isAllowedDiscountGreater,
        setIsModalDiscountReleaseWithPasswordOpen,
        true
      )
    );
  };

  return (
    <>
      <ReactTable
        style={{
          width: '100%',
          fontWeight: 'bold',
          textAlign: 'center',
          height: '250px',
          fontSize: '12px',
        }}
        data={items}
        columns={[
          {
            Header: 'Código',
            accessor: 'Code',
            headerClassName: 'text-left',
            width: 80,
            Cell: (props) => (
              <div
                className={getItemColorByCurrentStatus(
                  props.original.isCancelled
                )}
              >
                {props.value}
              </div>
            ),
          },
          {
            Header: 'Ref. Fabricante',
            accessor: 'Manufacturer_Ref',
            headerClassName: 'text-left',
            width: 90,
            Cell: (props) => (
              <div
                className={getItemColorByCurrentStatus(
                  props.original.isCancelled
                )}
              >
                {props.value}
              </div>
            ),
          },
          {
            Header: 'Tp',
            accessor: 'Type',
            headerClassName: 'text-left',
            width: 50,
            Cell: (props) => (
              <div>
                <strong
                  className={getItemColorByCurrentStatus(
                    props.original.isCancelled
                  )}
                >
                  {props.value === 'Produto' ? 'P' : 'S'}
                </strong>
              </div>
            ),
          },
          {
            Header: 'Descrição',
            accessor: 'Description',
            width: '100%',
            headerClassName: 'text-left',
            Cell: (props) =>
              (props.original.edit &&
                props.original.Type === 'Produto' &&
                props.original.updateDescriptionOnSale) ||
              (props.original.edit &&
                props.original.Type === 'Serviço' &&
                companyConfig.updateServiceDescriptionOnSale) ? (
                <input
                  type="text"
                  id="description-input"
                  contentEditable={true}
                  className="form-control foco-input"
                  defaultValue={itemToEdit.Description}
                  onChange={(e) =>
                    handleChangeDescriptionUpdate(e.target.value)
                  }
                />
              ) : (
                <span
                  className={getItemColorByCurrentStatus(
                    props.original.isCancelled
                  )}
                >
                  {props.original.Description}
                </span>
              ),
          },
          {
            Header: 'Marca',
            accessor: 'Brand',
            headerClassName: 'text-left',
            width: 100,
            Cell: (props) => (
              <div
                className={getItemColorByCurrentStatus(
                  props.original.isCancelled
                )}
              >
                {props.value}
              </div>
            ),
          },
          {
            Header: 'Qtd',
            accessor: 'Quantity',
            headerClassName: 'text-left',
            width: 115,
            Cell: (props) =>
              !props.original.edit ? (
                <span
                  style={{
                    color:
                      props.original.QuantityItemsReturned > 0 ||
                      props.original.isCancelled
                        ? 'red'
                        : 'unset',
                  }}
                >
                  {props.value - (props.original.QuantityItemsReturned || 0)}
                </span>
              ) : (
                <InputNumberDecimal
                  id="input-number-sale"
                  className="form-control foco-input"
                  onChange={handleChangeEditableQuantity}
                  value={itemToEdit.Quantity}
                />
              ),
          },
          {
            Header: 'R$ Unit.',
            accessor: 'Unit_Value',
            headerClassName: 'text-left',
            maxWidth: 100,
            Cell: (props) =>
              props.original.edit ? (
                <CurrencyInput
                  className="form-control foco-input"
                  id="unityInput"
                  style={{ width: '100%' }}
                  value={itemToEdit.Unit_Value}
                  onChangeEvent={(e) => handleUnityValue(e.target.value)}
                  disabled={!company.changePriceOnSale}
                />
              ) : (
                <div
                  className={getItemColorByCurrentStatus(
                    props.original.isCancelled
                  )}
                >
                  {currency(props.value)}
                </div>
              ),
          },
          {
            Header: 'Desc. (%)',
            accessor: 'Discount_Value',
            headerClassName: 'text-left',
            width: 90,
            Cell: (props) => (
              <>
                {!props.original.edit ? (
                  <span
                    className={getItemColorByCurrentStatus(
                      props.original.isCancelled
                    )}
                  >
                    {percentage(props.value)}
                  </span>
                ) : (
                  <PercentInput
                    suffix="%"
                    id="discountInput"
                    className="form-control foco-input"
                    name="Discount_Value"
                    decimalSeparator=","
                    inputType="text"
                    onChangeEvent={(e) => checkDiscount(e.target.value)}
                    value={itemToEdit.Discount_Value}
                  />
                )}
              </>
            ),
          },
          {
            Header: 'Valor Total',
            accessor: 'Amount',
            headerClassName: 'text-left',
            width: 90,
            Cell: (props) => (
              <div>
                {!props.original.edit ? (
                  <span
                    style={{
                      color:
                        props.original.QuantityItemsReturned > 0 ||
                        props.original.isCancelled
                          ? 'red'
                          : 'unset',
                    }}
                  >
                    {currency(props.value)}
                  </span>
                ) : (
                  <CurrencyInput
                    className="form-control foco-input"
                    id="totalInput"
                    onChangeEvent={(e) => checkTotal(e.target.value)}
                    value={itemToEdit.Amount / 1}
                  />
                )}
              </div>
            ),
          },
          {
            Header: '',
            accessor: 'Add',
            headerClassName: 'text-left',
            width: 90,
            Cell: (props) => (
              <div className="flex center gap-075">
                <div
                  className="flex center"
                  style={{ width: '16px', height: '16px' }}
                >
                  {saleStatusId !== 7 &&
                    (!props.original.edit ? (
                      <FontAwesomeIcon
                        cursor="pointer"
                        style={{
                          cursor:
                            saleStatusId === 5 || saleStatusId === 2
                              ? 'not-allowed'
                              : 'pointer',
                          width: '1.2em',
                          height: '1.2em',
                        }}
                        icon={faEdit}
                        onClick={() => {
                          if (
                            saleReducer.saleStatusId !== 5 &&
                            saleReducer.saleStatusId !== 2
                          ) {
                            handleEditIemClick(props);
                            setItemToEditIndex(props.index);
                          }
                        }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faCheckSquare}
                        style={{
                          color: 'green',
                          cursor: 'pointer',
                          width: '1.2em',
                          height: '1.2em',
                        }}
                        aria-hidden="false"
                        onClick={() => {
                          handleConfirmItem(itemToEdit);
                        }}
                      />
                    ))}
                </div>
                <div
                  className="flex center"
                  style={{ width: '16px', height: '16px' }}
                >
                  {saleStatusId !== 7 &&
                    (!props.original.edit ? (
                      <FontAwesomeIcon
                        icon={faTrashAlt}
                        color="red"
                        style={{
                          cursor:
                            saleStatusId === 5 || saleStatusId === 2
                              ? 'not-allowed'
                              : 'pointer',
                          color: 'red',
                          fontSize: '1.2em',
                        }}
                        onClick={() =>
                          saleStatusId !== 5 &&
                          saleStatusId !== 2 &&
                          dispatch(confirmEraseItem(props.original))
                        }
                        aria-hidden="false"
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faTimes}
                        style={{ color: 'red', cursor: 'pointer' }}
                        aria-hidden="false"
                        onClick={() => dispatch(editItem(props.index, items))}
                      />
                    ))}
                </div>
                {!props.original.edit && (
                  <div
                    className="flex center"
                    style={{ width: '16px', height: '16px' }}
                  >
                    {companyConfig?.saleItemApprovalCancellation && (
                      <ItemCancellationActionButtons
                        item={props.original}
                        index={props.index}
                      />
                    )}
                  </div>
                )}
              </div>
            ),
          },
        ]}
        defaultPageSize={5}
        loading={loading}
        showPagination={true}
        sortable={false}
        showPaginationTop={false}
        showPaginationBottom={true}
        pageSizeOptions={[5, 10, 20, 25, 50, 100]}
        previousText="Anterior"
        nextText="Próximo"
        loadingText="Carregando..."
        noDataText='Clique em "+ Adicionar Item" para adicionar itens na venda'
        pageText="Página"
        ofText="de"
        rowsText="linhas"
      />
      {/* MODAL PARA CONFIRMAÇÃO DE EXCLUSÃO DE ITEM */}
      <AlertModal
        show={eraseItemModal}
        title="Exclusão de Item"
        onHide={() => dispatch(handleChange(false, 'eraseItemModal'))}
        animation={true}
        message={<ContentDeleteConfirmationModal />}
        onCancel={() => dispatch(handleChange(false, 'eraseItemModal'))}
        onSubmit={() => dispatch(removeItem(itemToRemove))}
      />
      <ModalDiscountReleaseWithPassword
        open={isModalDiscountReleaseWithPasswordOpen}
        onClose={() => setIsModalDiscountReleaseWithPasswordOpen(false)}
        itemToEdit={itemToEdit}
        itemToEditIndex={itemToEditIndex}
        items={items}
      />
    </>
  );
}
