import React, { useState, useEffect } from 'react';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';
import useFilters from 'hooks/useFilters';
import { cpfOrCnpjMask } from 'client/components/ToNormalize/ToNormalize';
import financingBankSlipRepository from 'repositories/FinancingBankSlip';
import FinancingBankSlipTable from './FinancingBankSlipTable';
import FinancingBankSlipFilter from './FinancingBankSlipFilter';
import useDebounce from 'hooks/useDebounce';
import '../styles.css';

const FinancingBankSlipMain = ({ history }) => {
  const [loading, setLoading] = useState(false);

  const [query, setQuery] = useState('');
  const [status, setStatus] = useState('');
  const [initialDate, setInitialDate] = useState(null);
  const [finalDate, setFinalDate] = useState(null);
  const [partnerId, setPartnerId] = useState(null);

  const [financingBankSlipList, setFinancingBankSlipList] = useState([]);

  const [pageLimit, setPageLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);

  const [queryDebounced, setQueryDebounced] = useState('');
  const debouncedSaveQuery = useDebounce(setQueryDebounced, 600);

  const handleChangeQuery = (value) => {
    setQuery(value);
    debouncedSaveQuery(value);
  };

  async function loadFinancingBankSlip() {
    setLoading(true);
    try {
      const data = await financingBankSlipRepository.index({
        limit: pageLimit,
        page: currentPage + 1,
        query: queryDebounced,
        status: status ? parseInt(status) : null,
        initialDate,
        finalDate,
        partner: partnerId ? parseInt(partnerId) : null,
      });

      setFinancingBankSlipList(data.rows);
      setTotalPages(Math.ceil(data.count / pageLimit));
    } catch (err) {
      console.log(err);
      return toastr.error(
        'Erro',
        'Ocorreu um erro ao carregar os dados. Por favor, tente novamente.'
      );
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    loadFinancingBankSlip();
  }, [
    currentPage,
    pageLimit,
    queryDebounced,
    status,
    initialDate,
    finalDate,
    partnerId,
  ]);

  return (
    <main className="boleto-flex-wrapper">
      <FinancingBankSlipFilter
        query={query}
        handleChangeQuery={handleChangeQuery}
        status={status}
        partnerId={partnerId}
        setPartnerId={setPartnerId}
        setStatus={setStatus}
        initialDate={initialDate}
        setInitialDate={setInitialDate}
        finalDate={finalDate}
        setFinalDate={setFinalDate}
        history={history}
      />
      <FinancingBankSlipTable
        data={financingBankSlipList}
        totalPages={totalPages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setPageLimit={setPageLimit}
        loading={loading}
        history={history}
      />
    </main>
  );
};

export default withRouter(FinancingBankSlipMain);
