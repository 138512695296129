import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { format } from 'date-fns'
import { change, initialize, reduxForm } from 'redux-form'
import { toastr } from 'react-redux-toastr'

import CardForm from 'client/components/CardForm'

import MainDataNFCe from '../../../../views/NFCe/newNFCe/FormNewNFCe/MainData'
import ItemsNFCe from '../../../../views/NFCe/newNFCe/FormNewNFCe/Items'

import MainDataSAT from '../../../../views/SATCFe/newSATCFe/FormNewSATCFe/MainData'
import ItemsSAT from '../../../../views/SATCFe/newSATCFe/FormNewSATCFe/Items'

import NFCesRepository from '../../../../../repositories/NFCes'
import SATRepository from 'repositories/SATCFe'

import companiesRepository from '../../../../../repositories/Companies'
import {
  cpfMask,
  cnpjMask,
  onlyNumbers,
} from 'client/components/ToNormalize/ToNormalize'
import { INVOICE_STATUS_DESCRIPTION } from 'utils/constants'
import { useAuth } from '../../../../../contexts/auth'

import './styles.css'

const InvoiceDetailsModal = ({
  invoiceId,
  fiscalDocumentType,
  onInitNewSale,
  onRetry,
}) => {
  const [nfce, setNFCe] = useState(null)
  const [sat, setSAT] = useState(null)

  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(false)

  const dispatch = useDispatch()

  const { companyId } = useAuth()

  useEffect(() => {
    if (invoiceId) {
      fiscalDocumentType === 'SAT' ? loadSAT() : loadNFCe()
    }
  }, [])

  async function loadNFCe() {
    setLoading(true)

    try {
      const nfce = await NFCesRepository.show(invoiceId)
      const statusNFCe = getNFCeStatus(nfce)

      if (nfce.status === 'Aberta' || nfce.status === 'Aberta c/ Erro') {
        setDisabled(false)
      } else {
        setDisabled(true)
      }

      const nfceObject = {
        id: nfce.id,
        nfc: nfce.code,
        serie: nfce.serie,
        venda: nfce.Sale.Code,
        statusNFCe: statusNFCe,
        dataCriacao: format(new Date(), 'yyyy-MM-dd'),
        dataEmissao: !nfce.issuedAt
          ? ''
          : format(new Date(nfce.issuedAt), 'yyyy-MM-dd') +
            'T' +
            format(new Date(nfce.issuedAt), 'hh:mm'),
        chaveDeAcesso: formatAccessKey(nfce.accessKey),
        customerId: nfce.customerId,
        situationCode: nfce.situationCode,
        cliente: nfce.Customer.Trading_Name
          ? nfce.Customer.Trading_Name
          : nfce.Customer.Company_Name,
        cnpjCpf:
          nfce.Customer.Cpf_Cnpj.length === 11
            ? cpfMask(nfce.Customer.Cpf_Cnpj)
            : cnpjMask(nfce.Customer.Cpf_Cnpj),
        subTotal: nfce.subtotal,
        totalProdutos: nfce.total,
        discount: nfce.discount,
        NFCeItems: nfce.NFCeItems,
        bcICMS: nfce.bcICMS,
        valueICMS: nfce.valueICMS,
        bcPIS: nfce.bcPIS,
        valuePIS: nfce.valuePIS,
        bcCOFINS: nfce.bcCOFINS,
        valueCOFINS: nfce.valueCOFINS,
        total: nfce.total,
        status: nfce.status,
        isInvoiceOpen: nfce.status === INVOICE_STATUS_DESCRIPTION.OPEN,
        isInvoiceCanceled: nfce.status === INVOICE_STATUS_DESCRIPTION.CANCELED,
        isInvoiceIssued: nfce.status === INVOICE_STATUS_DESCRIPTION.ISSUED,
        isInvoiceOpenedWithErrors:
          nfce.status === INVOICE_STATUS_DESCRIPTION.OPEN_WITH_ERRORS,
        pdvOnline: true,
      }

      setNFCe(nfceObject)

      dispatch(initialize('invoiceForm', nfceObject))
      loadCompanyTaxRegime()
    } catch (err) {
      console.error(err)
      toastr.warning(
        'Ocorreu um erro ao carregar a NFC-e. Por favor, tente novamente'
      )
    }
    setLoading(false)
  }

  async function loadSAT() {
    setLoading(true)

    try {
      const sat = await SATRepository.show(invoiceId)
      const statusSAT = getNFCeStatus(sat)

      if (sat.status === 'Aberta' || sat.status === 'Aberta c/ Erro') {
        setDisabled(false)
      } else {
        setDisabled(true)
      }

      const satData = {
        id: sat.id,
        sat: sat.code,
        serie: sat.serie,
        venda: sat.SATSale.Code,
        statuSATCFe: statusSAT,
        dataCriacao: format(new Date(), 'yyyy-MM-dd'),
        dataEmissao: !sat.issuedAt
          ? ''
          : format(new Date(sat.issuedAt), 'yyyy-MM-dd') +
            'T' +
            format(new Date(sat.issuedAt), 'hh:mm'),
        chaveDeAcesso: formatAccessKey(sat.accessKey),
        customerId: sat.customerId,
        situationCode: sat.situationCode,
        cliente: sat.SATCustomer.Trading_Name
          ? sat.SATCustomer.Trading_Name
          : sat.SATCustomer.Company_Name,
        cnpjCpf:
          sat.SATCustomer.Cpf_Cnpj.length === 11
            ? cpfMask(sat.SATCustomer.Cpf_Cnpj)
            : cnpjMask(sat.SATCustomer.Cpf_Cnpj),
        subTotal: sat.subTotal,
        totalProdutos: sat.total,
        discount: sat.discount,
        SATItems: sat.SATItems,
        bcICMS: sat.bcICMS,
        valueICMS: sat.valueICMS,
        bcPIS: sat.bcPIS,
        valuePIS: sat.valuePIS,
        bcCOFINS: sat.bcCOFINS,
        valueCOFINS: sat.valueCOFINS,
        total: sat.total,
        status: sat.status,
        isInvoiceOpen: sat.status === INVOICE_STATUS_DESCRIPTION.OPEN,
        isInvoiceCanceled: sat.status === INVOICE_STATUS_DESCRIPTION.CANCELED,
        isInvoiceIssued: sat.status === INVOICE_STATUS_DESCRIPTION.ISSUED,
        isInvoiceOpenedWithErrors:
          sat.status === INVOICE_STATUS_DESCRIPTION.OPEN_WITH_ERRORS,
        pdvOnline: true,
      }

      setSAT(satData)

      dispatch(initialize('invoiceForm', satData))
      loadCompanyTaxRegime()
    } catch (err) {
      console.error(err)
      toastr.warning(
        'Ocorreu um erro ao carregar o SAT. Por favor, tente novamente'
      )
    }
    setLoading(false)
  }

  async function loadCompanyTaxRegime() {
    try {
      const taxData = await companiesRepository.getTaxData(companyId)
      dispatch([
        change(
          'invoiceForm',
          'isTaxRegimeSimpleNational',
          taxData.TaxRegime === 'Simples Nacional'
        ),
        change('invoiceForm', 'companyPIS', taxData.PIS),
        change('invoiceForm', 'companyCOFINS', taxData.COFINS),
      ])
    } catch (err) {
      console.error(err)
      toastr.warning(
        'Ocorreu um erro ao buscar os dados fiscais da empresa. Por favor, tente novamente'
      )
    }
  }

  function formatAccessKey(value) {
    if (!value) return value
    value = onlyNumbers(value)
    return (
      value.substring(0, 4) +
      ' ' +
      value.substring(4, 8) +
      ' ' +
      value.substring(8, 12) +
      ' ' +
      value.substring(12, 16) +
      ' ' +
      value.substring(16, 20) +
      ' ' +
      value.substring(20, 24) +
      ' ' +
      value.substring(24, 28) +
      ' ' +
      value.substring(28, 32) +
      ' ' +
      value.substring(32, 36) +
      ' ' +
      value.substring(36, 40) +
      ' ' +
      value.substring(40, 44)
    )
  }

  function getNFCeStatus({ status, situationDescription, situationCode }) {
    if (status !== 'Aberta' && status !== 'Contingência') {
      return situationCode + ' - ' + situationDescription
    }
    return status
  }

  if (loading) return <div />

  const MainData = fiscalDocumentType === 'SAT' ? MainDataSAT : MainDataNFCe
  const Items = fiscalDocumentType === 'SAT' ? ItemsSAT : ItemsNFCe

  const title = fiscalDocumentType === 'SAT' ? 'Dados do SAT' : 'Dados da NFC-e'

  return (
    <Modal show animation dialogClassName="nfc-details-modal-wrapper">
      <Modal.Header>
        <Modal.Title>
          <strong>
            {fiscalDocumentType}{' '}
            {fiscalDocumentType === 'SAT' ? sat?.sat : nfce?.nfc}
          </strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CardForm show title={title}>
          <MainData isMultiInvoiceModal={true} />
        </CardForm>

        <CardForm show title="Itens">
          <Items isMultiInvoiceModal={true} />
        </CardForm>
      </Modal.Body>
      <Modal.Footer>
        <button className="danger" onClick={onInitNewSale}>
          Voltar
        </button>
        <button className="success" onClick={onRetry}>
          Enviar
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default reduxForm({
  form: 'invoiceForm',
  initialValues: {},
})(InvoiceDetailsModal)
