import React, { useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { toastr } from 'react-redux-toastr'
import productBaseRepository from 'repositories/ProductBase'

import './styles.css'

const ImportProgressModal = ({
  companyId,
  cars,
  motorcycles,
  heavy,
  finish,
}) => {
  useEffect(() => {
    importProducts()
  }, [])

  async function importProducts() {
    try {
      const response = await productBaseRepository.store({
        companyId,
        cars,
        motorcycles,
        heavy,
      })

      if (response.status === 400) throw new Error(response.error.message)
      if (response.status === 500)
        throw new Error(
          'Ocorreu um erro na importação dos produtos, tente novamente'
        )

      toastr.success('Importação concluída com sucesso')
    } catch (err) {
      toastr.error('Houve um erro ao importar os produtos, tente novamente')
    } finally {
      return finish()
    }
  }

  return (
    <Modal animation show>
      <Modal.Body>
        <Modal.Title style={{ textAlign: 'center' }}>
          Por favor, aguarde
        </Modal.Title>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexFlow: 'column wrap',
            margin: '10px 0',
          }}
        >
          <div className='lds-ring-blue'>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <label>O processo terminará em breve...</label>
          <div style={{ textAlign: 'center', marginTop: '10px' }}>
            <p>Estamos adicionando os produtos a base de dados.</p>
            <strong>Não feche ou atualize a pagina</strong>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ImportProgressModal
