import React from 'react'
import { useRecoilState } from 'recoil'
import { toastr } from 'react-redux-toastr'
import { useHistory } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'

import Button from 'client/components/CustomButton/CustomButton.jsx'
import { onlyNumbers } from 'client/components/ToNormalize/ToNormalize'

import {
  FINANCING_BOLETOFLEX_REDIRECT_URL,
  FINANCING_CURRENT_STEP,
  FINANCING_CUSTOMER_PHONE,
  FINANCING_KOIN_PAYMENTID,
  FINANCING_PARTNERID,
  FINANCING_SHOW_CANCEL_CONFIRMATION,
  FINANCING_TRIGGER_CREATE_TRANSACTION,
  FINANCING_TRIGGER_SAVE_CLIENT,
} from '../recoil'

import financingStrings from '../strings'

const FinishFinancingButtonLabel = ({ partnerId }) => {
  switch (partnerId) {
    case 1:
      return <span>Concluir Processo de Financiamento</span>
    default:
      return (
        <>
          <FontAwesomeIcon icon={faWhatsapp} fontSize={'1.2em'} /> &nbsp;
          <span>Compartilhar via WhatsApp</span>
        </>
      )
  }

}

export default function FinancingModalFooter({ handleCloseFinancingModal }) {
  const [currentStep, setCurrentStep] = useRecoilState(FINANCING_CURRENT_STEP)
  const [partnerId] = useRecoilState(FINANCING_PARTNERID)

  const [koinPaymentId] = useRecoilState(FINANCING_KOIN_PAYMENTID)
  const [boletoFlexUrl] = useRecoilState(FINANCING_BOLETOFLEX_REDIRECT_URL)

  const [triggerSaveClient, setTriggerSaveClient] = useRecoilState(
    FINANCING_TRIGGER_SAVE_CLIENT
  )

  const [triggerCreateTransaction, setTriggerCreateTransaction] =
    useRecoilState(FINANCING_TRIGGER_CREATE_TRANSACTION)

  const [showCancelConfirmationDialog, setShowCancelConfirmationDialog] =
    useRecoilState(FINANCING_SHOW_CANCEL_CONFIRMATION)

  const [customerPhone, setCustomerPhone] = useRecoilState(
    FINANCING_CUSTOMER_PHONE
  )

  const history = useHistory()

  function handleTransactionStep() {
    if (partnerId === 1 && !koinPaymentId) {
      return toastr.warning(
        'OS Digital',
        financingStrings.FINANCING_KOIN_BLANK_PAYMENTID
      )
    }

    setTriggerCreateTransaction(true)
  }

  function handleBackButton() {
    switch (true) {
      case currentStep === 1:
        setShowCancelConfirmationDialog(true)
        return
      case currentStep === 3:
        toastr.success('OS Digital', financingStrings.FINANCING_SUCCESS)
        history.push('/client/sales')
        return
      case currentStep > 3 || currentStep === 2:
        handleCloseFinancingModal()
        return
      default:
        handleDecreaseStep()
    }
  }

  function handleNextButton() {
    switch (true) {
      case currentStep === 1:
        setTriggerSaveClient(true)
        return
      case currentStep === 2:
        handleTransactionStep()
        return
      default:
        handleIncreaseStep()
    }
  }

  function handleBackButtonLabel() {
    switch (true) {
      case currentStep === 4:
        return 'Fechar'
      case currentStep === 2:
        return 'Desistir do Financiamento'
      default:
        return 'Voltar'
    }
  }

  function handleIncreaseStep() {
    setCurrentStep(currentStep + 1)
  }

  function handleDecreaseStep() {
    setCurrentStep(currentStep - 1)
  }

  function handleFinishFinancing() {
    switch (partnerId) {
      case 1:
        toastr.success('OS Digital', financingStrings.FINANCING_KOIN_SUCCESS)
        return handleCloseFinancingModal()
      case 2:
        window.open(
          `https://api.whatsapp.com/send?phone=55${onlyNumbers(
            customerPhone
          )}&text=${boletoFlexUrl
            .replaceAll('/', '%2F')
            .replaceAll(':', '%3A')
            .replaceAll('#', '%23')}`,
          '_blank'
        )
      default:
        handleCloseFinancingModal()
    }
  }

  return (
    <>
      {currentStep !== 3 && (
        <Button bsStyle="danger" onClick={handleBackButton} fill>
          {handleBackButtonLabel()}
        </Button>
      )}

      {currentStep < 3 && (
        <Button bsStyle="info" onClick={handleNextButton} fill>
          Avançar
        </Button>
      )}

      {currentStep === 3 && (
        <Button bsStyle="info" fill onClick={handleFinishFinancing}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FinishFinancingButtonLabel partnerId={partnerId} />
          </div>
        </Button>
      )}
    </>
  )
}
