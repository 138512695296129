import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Field, change } from 'redux-form';
import Toggle from 'react-toggle';
import { toastr } from 'react-redux-toastr';

import { InputLabel } from '@material-ui/core';
import { InputContainer } from 'v2/components/Input';
import { useSelector, useDispatch } from 'react-redux';
import { usePlanSignatureContext } from '../../../../../contexts/plan-signature';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import PrintingSheetsViewModal from '../PrintingSheetsViewModal';

import RenderField from 'components/RenderField';
import printingSheetsRepository from '../../../../../repositories/PrintingSheets';
import UnavailableFeatureModal from 'client/components/UnavailableFeatureModal';
import AlertModal from 'components/AlertModal/AlertModal';

const optionsVehiclesAnnotationType = [
  {
    id: 1,
    description: 'Sem desenho do veículo',
  },
  {
    id: 2,
    description: 'Carros',
  },
  {
    id: 3,
    description: 'Motos',
  },
  {
    id: 4,
    description: 'Pesados',
  },
];

export default function Printed() {
  const [printingSheets, setPrintingSheets] = useState([]);
  const [
    isDisplayNotesInChecklistModalOpen,
    setIsDisplayNotesInChecklistModalOpen,
  ] = useState(false);
  const [isOpenFeatureNotAvailableModal, setIsOpenFeatureNotAvailableModal] =
    useState(false);
  const [visualizeSheets, setVisualizeSheets] = useState(false);
  const [sheetId, setSheetId] = useState(0);
  const {
    isPlanFree,
    isPlanStart,
    isPlanBasic,
    isPlanPrime,
    isPlanWorkmotorFree,
    isPaidWorkmotor,

    isWorkmotor: isPlanWorkmotor,
  } = usePlanSignatureContext();
  const isPlanWmFree = isPlanWorkmotorFree;
  const isPlanFreeOrStart = isPlanFree || isPlanStart;
  const isPlanFreeStartOrEssencial = isPlanFree || isPlanStart || isPlanBasic;
  const dispatch = useDispatch();

  const {
    orcamentPrintingSheetId,
    salePrintingSheetId,
    orderOfServicePrintingSheetId,
    schedulingOnSale,
    sendSMSWhenFinished,
    boxPrismaOnSale,
    responsibleSeller,
    changePriceOnSale,
    showPurchasePriceOnSale,
    updateServiceDescriptionOnSale,
    showSheetSignatureField,
    closeBudgetAutomatically,
    daysToCloseBudgetAutomatically,
    lossReasonId,
    sendSmsAndEmailToRememberSchedule,
    sendEmailToRememberSchedule,
    printSaleWhenCreate,
    allowOrderOfServiceWithoutVehicle,
    displayNotesInChecklist,
    salesRepresentative,
    typeOfService,
    advancedSearchType,
    stockWriteOff,
    kitRegistration,
    saleItemApprovalCancellation,
    budgetPaymentSuggestions,
    AutoAddSingleItemToPDV,
    comissionProrate,
    defaultSalesOrderFooterText,
    defaultOrcamentFooterText,
    defaultServiceCommissionRate,
    defaultProductCommissionRate,
    productCommissionCalculatedBy,
    serviceCommissionCalculatedBy,
  } = useSelector((state) => state.form.salesConfiguration.values);

  const displayNotesInChecklistInitialValue = useSelector(
    (state) =>
      state.form.salesConfiguration.initial &&
      state.form.salesConfiguration.initial.displayNotesInChecklist
  );

  useEffect(() => {
    loadPrintingSheets();
  }, []);

  const handleChangeDefaultOrcamentFooterText = (value) => {
    dispatch(change('salesConfiguration', 'defaultOrcamentFooterText', value));

    setTimeout(() => {
      const textarea = document.getElementById(
        'textarea_defaultOrcamentFooterText'
      );
      textarea.focus();
      textarea.selectionStart = textarea.selectionEnd = textarea.value.length;
    }, [60]);
  };

  async function loadPrintingSheets() {
    try {
      const printingSheets = await printingSheetsRepository.getAll();
      setPrintingSheets(printingSheets);
    } catch (err) {
      toastr.warning(
        'Ocorreu um erro ao carregar os impressos. Por favor, tente novamente'
      );
    }
  }

  const handleVisualizeSheets = (type) => {
    switch (type) {
      case 'orc':
        setSheetId(Number(orcamentPrintingSheetId));
        break;
      case 'sale':
        setSheetId(Number(salePrintingSheetId));
        break;
      case 'os':
        setSheetId(Number(orderOfServicePrintingSheetId));
        break;
      default:
        break;
    }

    setVisualizeSheets(true);
  };

  function validateIsPlanPrime() {
    if (isPlanFree || isPlanStart || isPlanBasic) {
      setIsOpenFeatureNotAvailableModal(true);
    }
  }
  const handleChangeDefaultSalesOrderFooterText = (value) => {
    dispatch(
      change('salesConfiguration', 'defaultSalesOrderFooterText', value)
    );

    setTimeout(() => {
      const textarea = document.getElementById(
        'textarea_defaultSalesOrderFooterText'
      );
      textarea.focus();
      textarea.selectionStart = textarea.selectionEnd = textarea.value.length;
    }, [60]);
  };

  function handleBudgetPaymentSuggestions() {
    if (isPlanWmFree || !isPlanWorkmotor) {
      setIsOpenFeatureNotAvailableModal(true);
    } else {
      dispatch(
        change(
          'salesConfiguration',
          'budgetPaymentSuggestions',
          !budgetPaymentSuggestions
        )
      );
    }
  }

  function handleDisplayNotesInChecklistChange() {
    dispatch(
      change(
        'salesConfiguration',
        'displayNotesInChecklist',
        !displayNotesInChecklist
      )
    );

    if (displayNotesInChecklistInitialValue !== true) {
      if (!displayNotesInChecklist === true) {
        setIsDisplayNotesInChecklistModalOpen(true);
      }
    }
  }

  return (
    <div className="flex column gap-050">
      <div className="flex row gap-050">
        <Col xs={2} sm={2} md={2} lg={2} style={{ position: 'relative' }}>
          <Field
            name="orcamentPrintingSheetId"
            component={RenderField}
            label="Tipo de Orçamento:"
            as="select"
          >
            {printingSheets.map((printingSheet) => (
              <option value={printingSheet.id} key={printingSheet.id}>
                {printingSheet.description}
              </option>
            ))}
          </Field>
          <span className="sales" onClick={() => handleVisualizeSheets('orc')}>
            Visualizar Modelo
          </span>
        </Col>
        <Col xs={2} sm={2} md={2} lg={2} style={{ position: 'relative' }}>
          <Field
            name="salePrintingSheetId"
            component={RenderField}
            label="Tipo de Venda:"
            as="select"
          >
            {printingSheets.map((printingSheet) => (
              <option value={printingSheet.id} key={printingSheet.id}>
                {printingSheet.description}
              </option>
            ))}
          </Field>
          <span className="sales" onClick={() => handleVisualizeSheets('sale')}>
            Visualizar Modelo
          </span>
        </Col>
        <Col xs={2} sm={2} md={2} lg={2} style={{ position: 'relative' }}>
          <Field
            name="orderOfServicePrintingSheetId"
            component={RenderField}
            label="Tipo de O.S:"
            as="select"
          >
            {printingSheets.map((printingSheet) => (
              <option value={printingSheet.id} key={printingSheet.id}>
                {printingSheet.description}
              </option>
            ))}
          </Field>
          <span className="sales" onClick={() => handleVisualizeSheets('os')}>
            Visualizar Modelo
          </span>
        </Col>
        <Col xs={2} sm={2} md={2} lg={2} style={{ position: 'relative' }}>
          <Field
            name="pdvPrintingSheetId"
            component={RenderField}
            label="Tipo de PDV:"
            as="select"
          >
            {printingSheets.map((printingSheet) => (
              <option value={printingSheet.id} key={printingSheet.id}>
                {printingSheet.description}
              </option>
            ))}
          </Field>
          <span className="sales" onClick={() => handleVisualizeSheets('pdv')}>
            Visualizar Modelo
          </span>
        </Col>
        <Col
          xs={12}
          sm={12}
          md={2}
          lg={2}
          style={{ position: 'relative', width: '350px' }}
        >
          <div>
            <label>Modelo de anotações do veículo:</label>
            <FontAwesomeIcon
              title="Selecione o modelo de anotações do mecânico"
              cursor="pointer"
              icon={faInfoCircle}
              style={{ height: '1.1em', width: '1.1em' }}
              color="#0088cc"
            />
          </div>
          <Field
            name="vehiclesAnnotationType"
            component={RenderField}
            as="select"
          >
            {optionsVehiclesAnnotationType.map((option) => (
              <option value={option.id} key={option.id}>
                {option.description}
              </option>
            ))}
          </Field>
        </Col>
      </div>

      {!isPlanFree && (
        <Col xs={10} sm={10} md={10} lg={10}>
          <div
            className="flex row mt-075"
            style={{
              display: 'flex',
              flexDirection: 'column',

              height: '100%',
            }}
          >
            <InputContainer variant="column" justify="space-between">
              <div className="flex between">
                <InputLabel
                  style={{
                    fontSize: '14px',
                    fontWeight: 'bold',
                  }}
                >
                  Texto padrão de rodapé (Orçamento):
                </InputLabel>
                <span
                  style={{
                    alignSelf: 'flex-end',
                    fontWeight: 'bold',
                    color: '#00a4d3',
                  }}
                >
                  {defaultOrcamentFooterText.length}/500 caracteres
                </span>
              </div>
              <textarea
                id="textarea_defaultOrcamentFooterText"
                name="defaultOrcamentFooterText"
                className="form-control foco-input"
                maxLength={500}
                style={{ borderRadius: '4px', resize: 'none', height: '70px' }}
                value={defaultOrcamentFooterText}
                onChange={(e) => {
                  handleChangeDefaultOrcamentFooterText(e.target.value);
                }}
              />
            </InputContainer>
          </div>
        </Col>
      )}

      {!isPlanFree && (
        <Col xs={10} sm={10} md={10} lg={10}>
          <div
            className="flex row mt-075"
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '70%',
            }}
          >
            <InputContainer variant="column" justify="space-between">
              <div className="flex between">
                <InputLabel
                  style={{
                    fontSize: '14px',
                    fontWeight: 'bold',
                  }}
                >
                  Texto padrão de rodapé (Venda/Ordem de serviço):
                </InputLabel>
                <span
                  style={{
                    alignSelf: 'flex-end',
                    fontWeight: 'bold',
                    color: '#00a4d3',
                  }}
                >
                  {defaultSalesOrderFooterText.length}/500 caracteres
                </span>
              </div>
              <textarea
                style={{ borderRadius: '4px', resize: 'none', height: '70px' }}
                id="textarea_defaultSalesOrderFooterText"
                className="form-control foco-input"
                maxLength={500}
                value={defaultSalesOrderFooterText}
                onChange={(e) => {
                  handleChangeDefaultSalesOrderFooterText(e.target.value);
                }}
              />
            </InputContainer>
          </div>
        </Col>
      )}
      {visualizeSheets && (
        <PrintingSheetsViewModal
          sheetId={sheetId}
          printingSheets={printingSheets}
          onCancel={() => setVisualizeSheets(false)}
        />
      )}
      <div className="flex row gap-050">
        {!isPlanFreeOrStart && (
          <Col
            xs={12}
            sm={12}
            md={2}
            lg={2}
            className="toggle-column"
            style={{ width: 'fit-content' }}
          >
            <div>
              <label>Exibir assinatura no impresso da OS</label>
            </div>
            <Toggle
              checked={showSheetSignatureField}
              onChange={() =>
                dispatch(
                  change(
                    'salesConfiguration',
                    'showSheetSignatureField',
                    !showSheetSignatureField
                  )
                )
              }
            />
          </Col>
        )}
        {!isPlanFreeOrStart && (
          <Col
            xs={12}
            sm={12}
            md={2}
            lg={2}
            className="toggle-column"
            style={{ marginRight: '10px', width: 'fit-content' }}
          >
            <div>
              <label>Imprimir Venda/OS ao criar</label>
            </div>
            <Toggle
              checked={printSaleWhenCreate}
              onChange={() =>
                dispatch(
                  change(
                    'salesConfiguration',
                    'printSaleWhenCreate',
                    !printSaleWhenCreate
                  )
                )
              }
            />
          </Col>
        )}
        {!isPlanFreeOrStart && (
          <Col
            xs={12}
            sm={12}
            md={2}
            lg={2}
            className="toggle-column"
            style={{ width: 'fit-content' }}
          >
            <div>
              <label>Exibir Observações no Checklist</label>
            </div>

            <Toggle
              checked={displayNotesInChecklist}
              onChange={() => handleDisplayNotesInChecklistChange()}
            />
          </Col>
        )}
        {isPaidWorkmotor && (
          <Col
            xs={10}
            sm={10}
            md={2}
            lg={2}
            className="toggle-column"
            style={{ width: 'fit-content' }}
          >
            <div>
              <label>Sugestão de Pagto. no orçamento</label>
            </div>

            <Toggle
              checked={budgetPaymentSuggestions}
              onChange={() => handleBudgetPaymentSuggestions()}
            />
          </Col>
        )}
      </div>

      <UnavailableFeatureModal
        show={isOpenFeatureNotAvailableModal}
        onHide={() => setIsOpenFeatureNotAvailableModal(false)}
      />

      <AlertModal
        show={isDisplayNotesInChecklistModalOpen}
        message={
          <>
            <p>
              <strong>
                Você tem certeza que deseja exibir as observações no checklist?
              </strong>
            </p>
            <p>
              Ao ativar, será exibido o texto inserido em Observações, Defeitos
              e Avarias no impresso. Devido ao espaço, o impresso poderá ter 2
              páginas, aumentando o tamanho do texto inserido.
            </p>
          </>
        }
        onCancel={() => {
          setIsDisplayNotesInChecklistModalOpen(false);
          dispatch(
            change('salesConfiguration', 'displayNotesInChecklist', false)
          );
        }}
        onSubmit={() => setIsDisplayNotesInChecklistModalOpen(false)}
        onHide={() => {
          setIsDisplayNotesInChecklistModalOpen(false);
          dispatch(
            change('salesConfiguration', 'displayNotesInChecklist', false)
          );
        }}
      />
    </div>
  );
}
