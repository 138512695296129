const financingStrings = {
  FINANCING_SUCCESS:
    'A solicitação de financiamento foi enviada e a OS/Venda será bloqueada até o resultado final. Informe o cliente para checar o seu WhatsApp.',

  FINANCING_LOADING_CLIENT: 'Carregando dados do cliente',
  FINANCING_SAVING_CLIENT: 'Atualizando os dados do cliente',
  FINANCING_SAVING_CLIENT_ERROR:
    'Ocorreu um erro ao atualizar os dados do cliente. Tente novamente!',

  FINANCING_SAVING_SALE: 'Salvando a venda',
  FINANCING_SAVING_SALE_ERROR:
    'Ocorreu um erro ao salvar a venda/os. Tente novamente!',

  FINANCING_KOIN_SUCCESS:
    'Seu cliente receberá as informações por telefone ou e-mail',

  FINANCING_SIMULATION_LOADING: 'Estamos nos comunicando com a financiadora',
  FINANCING_SIMULATION_ERROR:
    'Ocorreu um erro ao se comunicar com a financiadora. Tente novamente!',

  FINANCING_KOIN_BLANK_PAYMENTID: 'Selecione uma opção de parcelamento',

  FINANCING_CLIENT_BLANK_FIELDS:
    'É obrigatório que todos os campos sejam preenchidos. Preencha os campos e tente novamente!',

  INVALID_PARTNER: 'Parceiro inválido',
}

export default financingStrings
