import React, { useState } from 'react';
import { format } from 'date-fns';

import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { cpfMask, cnpjMask } from 'client/components/ToNormalize/ToNormalize';
import ViewClientModal from 'admin/components/ViewClientModal';
import { ClientUsersModal } from 'admin/components/ClientUsersModal';

import { BrandingLabel } from 'v2/components/BrandingLabel';
import { Table } from 'v2/components/Table';

import { getDateOnlyFromDate } from 'utils/dateHelpers';
import constants from 'utils/constants';
import { validatePlanEssencial } from 'utils/signatureHelpers';
import { validatePlanPrime } from 'utils/signatureHelpers';
import { validatePlanStart } from 'utils/signatureHelpers';
import { validatePlanWorkmotorEquivalent } from 'utils/signatureHelpers';

const ClientCpfCnpjModalTrigger = ({ cpfCnpj, client, handleReload }) => {
  const [isViewClientModalOpen, setIsViewClientModalOpen] = useState(false);

  function handleOpenClientDetails() {
    setIsViewClientModalOpen(true);
  }

  return (
    <>
      <button className="btn-link" onClick={() => handleOpenClientDetails()}>
        {cpfCnpj.length <= 11 ? cpfMask(cpfCnpj) : cnpjMask(cpfCnpj)}
      </button>
      {isViewClientModalOpen && (
        <ViewClientModal
          isOpen={true}
          handleClose={() => setIsViewClientModalOpen(false)}
          clientId={client.id}
          handleReloadClients={handleReload}
        />
      )}
    </>
  );
};

const CustomLabel = ({ text, backgroundColor = 'black', ...rest }) => (
  <button
    className="admin-custom-label"
    style={{
      backgroundColor,
    }}
    {...rest}
  >
    {text}
  </button>
);

function getPlanNameShort(planName) {
  const planLowerCase = planName?.toLowerCase();
  const isFiscal = planName?.toLowerCase().includes('fiscal');

  let planNameShort = '-';

  switch (true) {
    case planLowerCase?.includes('free'):
      planNameShort = `Free`;
      break;
    case planLowerCase?.includes('start'):
      planNameShort = 'Start';
      break;
    case planLowerCase?.includes('essencial'):
      planNameShort = 'Essencial';
      break;
    case planLowerCase?.includes('prime'):
      planNameShort = 'Prime';
      break;
    case planLowerCase?.includes('start'):
      planNameShort = 'Start';
      break;
    case planLowerCase?.includes('workmotor'):
      planNameShort = 'Workmotor';
      break;
    case planLowerCase?.includes('enterprise'):
      planNameShort = 'Enterprise';
      break;
    default:
      planNameShort = '-';
      break;
  }

  return `${planNameShort}${isFiscal ? ' Fiscal' : ''}`;
}

function getPlanLabelColor(planId) {
  switch (true) {
    case validatePlanEssencial(planId):
      return '#428bca';
    case validatePlanPrime(planId):
      return '#5cb85c';
    case validatePlanStart(planId):
      return '#ffa834';
    case validatePlanWorkmotorEquivalent(planId):
      return '#142a3d';
    default:
      return '#c1c1c1';
  }
}

function getStatusLabelColor(statusId) {
  const statusLabelColors = {
    2: '#5cb85c',
    3: '#f0ad4e',
    4: '#d9534f',
  };

  return statusLabelColors[statusId] || '#c1c1c1';
}

export default function ClientsTable({
  isRoleAdministrator,
  clients,
  loading,
  pageLimit,
  setPageLimit,
  currentPage,
  setCurrentPage,
  totalPages,
  totalClients,
  loadClients,
}) {
  const tableColumns = [
    {
      Header: 'ID',
      accessor: 'id',
      width: 40,
    },
    {
      Header: 'ID SL',
      accessor: 'Sacado_id',
      width: 40,
    },
    {
      Header: 'CPF/CNPJ',
      accessor: 'Cpf_Cnpj',
      width: 140,
      Cell: (props) => (
        <ClientCpfCnpjModalTrigger
          cpfCnpj={props.value}
          handleReload={loadClients}
          client={props.original}
        />
      ),
    },
    {
      Header: 'Tipo',
      accessor: 'Type',
      width: 80,
      Cell: (props) => (
        <CustomLabel
          text={props.value}
          backgroundColor={props.value === 'Física' ? '#d9534f' : '#5cb85c'}
        />
      ),
    },
    {
      Header: 'Produto',
      accessor: 'brandingId',
      width: 70,
      Cell: (props) => (
        <BrandingLabel brandingId={props.value} noBrandingText="Sem Plano" />
      ),
    },
    {
      Header: 'Nome/Razão Social',
      accessor: 'Company_Name',
      width: 230,
      Cell: (props) => {
        const [isUsersModalOpen, setIsUsersModalOpen] = useState(false);
        if (!isRoleAdministrator) return props.value;

        return (
          <div className="flex between gap-075 w-100">
            <div></div>
            <div className="overflow-ellipsis">{props.value}</div>
            <div>
              {
                <FontAwesomeIcon
                  icon={faUser}
                  color="#142a3d"
                  style={{ cursor: 'pointer' }}
                  title="Usuários do Cliente"
                  onClick={() => setIsUsersModalOpen(true)}
                />
              }
            </div>

            {isUsersModalOpen && (
              <ClientUsersModal
                isOpen={isUsersModalOpen}
                handleClose={() => setIsUsersModalOpen(false)}
                companyId={props.original.id}
              />
            )}
          </div>
        );
      },
    },
    {
      Header: 'Plano',
      accessor: 'planId',
      width: 130,
      Cell: (props) =>
        props.original?.signatureStatusId !==
        constants.SIGNATURE_STATUS.ACTIVE ? (
          '---'
        ) : (
          <CustomLabel
            text={getPlanNameShort(props.original.planDescription)}
            backgroundColor={getPlanLabelColor(props.value)}
          />
        ),
    },
    {
      Header: 'Período',
      accessor: 'SignaturePeriod',
      width: 100,
      Cell: (props) =>
        props.original?.signatureStatusId !==
        constants?.SIGNATURE_STATUS.ACTIVE ? (
          '---'
        ) : (
          <CustomLabel
            text={props.value ?? 'NULL'}
            backgroundColor={
              props.value === 'Mensal'
                ? '#5bc0de'
                : props.value === 'Trimestral'
                ? '#f0ad4e'
                : '#d9534f'
            }
          />
        ),
    },
    {
      Header: 'Dt. Cadastro',
      accessor: 'createdAt',
      width: 90,
      Cell: (props) =>
        !props.value ? '' : format(new Date(props.value), 'dd/MM/yyyy'),
    },
    {
      Header: 'Dt. Vigência',
      accessor: 'SignatureEnd_Date',
      width: 90,
      Cell: (props) =>
        !props.value
          ? ''
          : format(getDateOnlyFromDate(new Date(props.value)), 'dd/MM/yyyy'),
    },
    {
      Header: 'Últ. Venda',
      accessor: 'lastSaleDate',
      width: 85,
      Cell: (props) =>
        !props.value ? '---' : format(new Date(props.value), 'dd/MM/yyyy'),
    },
    {
      Header: 'Status',
      accessor: 'signatureStatusDescription',
      width: 90,
      Cell: (props) =>
        props.value ? (
          <CustomLabel
            text={props.value}
            backgroundColor={getStatusLabelColor(
              props.original?.signatureStatusId
            )}
          />
        ) : (
          '---'
        ),
    },
  ];

  return (
    <div>
      <Table
        data={clients}
        columns={tableColumns}
        defaultPageSize={10}
        pageSize={pageLimit}
        page={currentPage}
        onPageChange={setCurrentPage}
        manual
        onPageSizeChange={(value) => {
          setPageLimit(value);
        }}
        pages={totalPages}
        loading={loading}
        fontSize="12px"
        defaultSorted={[
          {
            id: 'Company_Name',
            desc: false,
          },
        ]}
      />

      <span>Total: {totalClients}</span>
    </div>
  );
}
