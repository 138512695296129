import React from 'react'
import { format } from 'date-fns'
import { Link } from 'react-router-dom'

import { faChartPie, faEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { cnpjMask } from 'client/components/ToNormalize/ToNormalize'
import { phoneMask } from 'client/components/ToNormalize/ToNormalize'
import { cpfMask } from 'client/components/ToNormalize/ToNormalize'
import DownloadXlsButton from '../../../components/DownloadXlsButton'

const AdvertisingActions = ({ advertising }) => {
  const advertisingReport = advertising.reports.map(serializeReport)

  function serializeReport(report) {
    const cpfCnpj = !report.company ? '' : report.company.cpfCnpj

    return {
      ...report,
      clickedAt: format(new Date(report.clickedAt), 'dd/MM/yyyy'),
      cpfCnpj: !report.company
        ? ''
        : cpfCnpj.length <= 11
        ? cpfMask(cpfCnpj)
        : cnpjMask(cpfCnpj),
      companyName: !report.company ? report.user.name : report.company.name,
      phone: phoneMask(report.user.phone),
      email: report.user.email,
      segment: report.company?.segment || '-',
      plan: report.company?.signature?.Plans?.Description || '-',
    }
  }

  return (
    <div className="table-actions" style={{ alignItems: 'unset' }}>
      <Link
        to={{
          pathname: `advertising#${advertising.id}`,
        }}
      >
        <FontAwesomeIcon cursor="pointer" icon={faEdit} />
      </Link>
      {advertising.redirectUrl && (
        <DownloadXlsButton
          style={{ display: 'contents' }}
          archiveName={`${advertising.description} - ${format(
            new Date(),
            'dd/MM/aaaa HH:mm'
          )}`}
          data={advertisingReport}
          disable={!advertisingReport.length}
          columns={[
            {
              name: 'Data do Clique',
              acessor: 'clickedAt',
            },
            {
              name: 'CNPJ',
              acessor: 'cpfCnpj',
            },
            {
              name: 'Razão Social',
              acessor: 'companyName',
            },
            {
              name: 'Plano',
              acessor: 'plan',
            },
            {
              name: 'Segmento',
              acessor: 'segment',
            },
            {
              name: 'Telefone',
              acessor: 'phone',
            },
            {
              name: 'E-mail',
              acessor: 'email',
            },
          ]}
        >
          <FontAwesomeIcon icon={faChartPie} cursor="pointer" />
        </DownloadXlsButton>
      )}
    </div>
  )
}

export default AdvertisingActions
