import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { reduxForm, Field, change } from 'redux-form';
import { useSelector, useDispatch } from 'react-redux';
import renderField from '../../../../components/RenderField';
import { Modal } from 'react-bootstrap';
import Button from '../../CustomButton/CustomButton';
import { phoneMask } from 'client/components/ToNormalize/ToNormalize';
import { licensePlateMask } from '../../../../utils/masks';

import AlertModal from '../../../../components/AlertModal';
import { setClient } from '../../../../client/views/Sales/NewSale/FormSale/redux/actions';
import SelectCustomers from 'v2/client/components/SelectCustomers';

const Icon = () => (
  <i
    id="iconepesquisar"
    style={{ cursor: 'pointer', marginLeft: '-40px' }}
    className="fa fa-search"
  />
);

const FormPrintQuickQuoteModal = ({
  show,
  onHide,
  handleSubmit,
  isFromSaveButton,
}) => {
  const [isConfirmSaveModalOpen, setIsConfirmSaveModalOpen] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [query, setQuery] = useState('');
  const dispatch = useDispatch();

  const submitInfo = () => {
    handleSubmit();
    onHide();
  };

  const handleSave = async () => {
    await isFromSaveButton();
    submitInfo();
  };

  const handleChangeCustomer = (value) => {
    setSelectedCustomer(value);
    dispatch([
      change('printQuickQuote', 'name', value.customer_name),
      change('printQuickQuote', 'email', value.customer_email),
      change('printQuickQuote', 'phone', phoneMask(value.customer_phone)),
      change('printQuickQuote', 'model', value.vehicle_model),
      change(
        'printQuickQuote',
        'licensePlate',
        value.vehicle_plate?.replace('-', '')
      ),
    ]);
  };

  return (
    <>
      <Modal show={show} onHide={onHide} animation>
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>Imprimir Orçamento Rápido</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <SelectCustomers
              value={query}
              onChange={(item) => handleChangeCustomer(item.value)}
            />
          </div>
          <div className="print-quick-quote-modal-container">
            <Field label="Nome:" name="name" component={renderField} />
            <Field label="Email:" name="email" component={renderField} />
            <Field
              label="Telefone:"
              name="phone"
              component={renderField}
              normalize={phoneMask}
            />
            <div className="print-quick-quote-modal-vehicle-info">
              <Field label="Veículo:" name="model" component={renderField} />
              <Field
                label="Placa:"
                name="licensePlate"
                component={renderField}
                {...licensePlateMask}
              />
            </div>
            <Field
              label="Observações:"
              name="observations"
              component={renderField}
              as="textarea"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="print-quick-quote-modal-footer">
            <Button bsStyle="info" pullRight fill onClick={submitInfo}>
              Salvar e Imprimir
            </Button>
            <Button
              bsStyle="primary"
              pullRight
              fill
              onClick={() => {
                setIsConfirmSaveModalOpen(true);
              }}
            >
              Salvar
            </Button>
            <Button bsStyle="danger" pullRight fill onClick={onHide}>
              Cancelar
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      {
        <AlertModal
          show={isConfirmSaveModalOpen}
          message="Deseja salvar o orçamento rápido com as informações aplicadas?"
          onHide={() => setIsConfirmSaveModalOpen(false)}
          onCancel={() => setIsConfirmSaveModalOpen(false)}
          onSubmit={handleSave}
        />
      }
    </>
  );
};

export default reduxForm({
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  form: 'printQuickQuote',
})(FormPrintQuickQuoteModal);
