import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { toastr } from 'react-redux-toastr';

import Form from './Form';

import NFCesRepository from '../../../../repositories/NFCes';
import NFesRepository from '../../../../repositories/NFes';
import { useAuth } from '../../../../contexts/auth';

export default function DisableInvoiceModal({
  onHide,
  onCancel,
  onSuccess,
  show,
}) {
  const [loading, setLoading] = useState(false);

  const { companyId } = useAuth();

  async function handleSubmit(values) {
    setLoading(true);
    await validations(values);
    setLoading(false);
  }

  async function validations(values) {
    const {
      nfType,
      environment,
      firstInvoiceNumber,
      lastInvoiceNumber,
      serie,
      justification,
    } = values;

    if (!firstInvoiceNumber || !lastInvoiceNumber || !serie || !justification)
      return toastr.warning(
        'Preencha todos os campos corretamente para inutilizar a numeração'
      );

    if (justification.length < 15)
      return toastr.warning(
        'O campo de justificativa deve ter ao menos 15 caracteres.'
      );

    if (Number(firstInvoiceNumber) > Number(lastInvoiceNumber))
      return toastr.warning(
        'A numeração final não pode ser menor que a inicial.'
      );

    await submit(
      {
        companyId,
        serie,
        firstInvoiceNumber: Number(firstInvoiceNumber),
        lastInvoiceNumber: Number(lastInvoiceNumber),
        justification,
        environment,
      },
      nfType
    );
  }

  async function submit(params, type) {
    try {
      let response = [];
      if (type === 'nfe') {
        response = await NFesRepository.disabledNFe(params);
      } else {
        response = await NFCesRepository.disabledNFCe(params);
      }
      for (let i = 0; i < response.length; i++) {
        if (response[i].code === 100) {
          toastr.success('Inutilização efetuada com sucesso.');
        } else if (response[i].code === 0) {
          toastr.success('Numeração já consta como inutilizada');
        }
      }
      return onSuccess();
    } catch (err) {
      console.log(err);
      console.log(err.response);

      return toastr.error(
        'Não foi possível inutilizar',
        'A numerações não foram inutilizadas. Por favor, entre em contato com o Suporte Técnico.'
      );
    }
  }

  return (
    <Modal show={show} animation onHide={() => (loading ? null : onHide())}>
      <Form
        initialValues={{
          nfType: 'nfe',
          environment: 'Produção',
        }}
        onCancel={onCancel}
        onSubmit={handleSubmit}
        loading={loading}
      />
    </Modal>
  );
}
