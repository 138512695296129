import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { withRouter } from 'react-router';
import { reset } from 'redux-form';
import { useDispatch } from 'react-redux';

import AlertModal from '../../../components/AlertModal';
import constants from '../../../utils/constants';
import ProductsServicesTable from '../../components/ProductsServicesTable';
import ConsultPricesSheet from '../../components/ProductsServicesTable/Sheets/ConsultPricesSheet';
import Button from '../../components/CustomButton/CustomButton';
import PureLoader from '../../../components/PureLoader';
import { encrypt } from '../../components/ToNormalize/ToNormalize';

import { useAuth } from 'contexts/auth';
import { toastr } from 'react-redux-toastr';

import './styles.css';
import PrintQuickQuoteModal from './PrintQuickQuoteModal';
import quickQuotesRepository from '../../../repositories/QuickQuotes';
import companyConfigRepository from '../../../repositories/CompanyConfig';
import { cashierBankHistoricValidations } from '../BlockSalesModals/actions';
import BlockSalesModals from '../BlockSalesModals';
import { usePlanSignatureContext } from 'contexts/plan-signature';

const ConsultPricesModal = ({
  onCancel,
  history,
  quickQuoteInfo,
  onSuccess,
  allowPromotionalPrices,
}) => {
  const [items, setItems] = useState([]);
  const [itemsToDelete, setItemsToDelete] = useState([]);
  const [isConfirmCancelModalOpen, setIsConfirmCancelModalOpen] =
    useState(false);
  const [isClearSheetModalOpen, setIsClearSheetModalOpen] = useState(false);
  const [isConfirmInitiateSaleModalOpen, setIsConfirmInitiateSaleModalOpen] =
    useState(false);
  const [isPrintQuickQuoteModalOpen, setIsPrintQuickQuoteModalOpen] =
    useState(false);
  const [quickQuoteInfoToHandle, setQuickQuoteInfoToHandle] = useState(null);
  const [lastCode, setLastCode] = useState(0);
  const [isBlockAddItemModalOpen, setIsBlockAddItemModalOpen] = useState(false);

  const [hasKitRegistration, setHasKitRegistration] = useState(false);

  const [cleanInputFlag, setCleanInputFlag] = useState(false);

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const { companyId, userId, company } = useAuth();

  const { isPlanFree, isPlanStart, isPlanBasic } = usePlanSignatureContext();
  const isPlanPrime = !isPlanFree && !isPlanStart && !isPlanBasic;

  useEffect(() => {
    if (companyId) {
      getQuickQuoteLastCode();
      getCompanyConfig();
    }
  }, [companyId]);

  useEffect(() => {
    setQuickQuoteInfoToHandle(quickQuoteInfo);

    if (quickQuoteInfo?.QuickQuotesItems?.length > 0) {
      handleItemsArray(quickQuoteInfo.QuickQuotesItems);
    }
  }, [quickQuoteInfo]);

  useEffect(() => {
    if (itemsToDelete.length > 0) {
      deleteQuickQuote();
    }
  }, [itemsToDelete]);

  async function getCompanyConfig() {
    const { kitRegistration } = await companyConfigRepository.show(companyId);

    setHasKitRegistration(kitRegistration);
  }

  async function handleInitSale() {
    if (company?.hasCashierControl) {
      const isValid = await dispatch(cashierBankHistoricValidations(companyId));
      if (isValid.payload) {
        setIsConfirmInitiateSaleModalOpen(true);
      }
    } else {
      setIsConfirmInitiateSaleModalOpen(true);
    }
  }

  function handleItemsArray(quickQuoteItems) {
    var itemsNewArray = [];

    quickQuoteItems.map((item) => {
      var object = {
        id: item.type == 'Produto' ? item?.Products.id : item?.Services.id,
        Code:
          item.type == 'Produto' ? item?.Products?.Code : item?.Services?.Code,
        Manufacturer_Ref:
          item.type == 'Produto' ? item.Products.Manufacturer_Ref : '',
        Product_id:
          item.type == 'Produto' ? item?.Products.id : item?.Services.id,
        Description:
          item.type == 'Produto'
            ? item?.Products.Description
            : item?.Services.Description,
        Brand:
          item.type == 'Produto' && item?.Products?.Brands?.Description
            ? item?.Products?.Brands?.Description
            : null,
        Value_Cost: item.unitValue,
        Unit_Value: item.unitValue,
        Quantity: item.quantity,
        Type: item.type,
        WarrantyCode:
          item.type == 'Produto'
            ? item?.Products?.Warranty?.Code
            : item?.Services?.Warranty?.Code,
        WarrantyDescription:
          item.type == 'Produto'
            ? item?.Products?.Warranty?.Description
            : item?.Services?.Warranty?.Description,
        Discount_Value: 0,
        edit: false,
      };

      itemsNewArray.push(object);
    });

    setItems(itemsNewArray);
  }

  function handleAdd(item) {
    const {
      id,
      Sale_Price,
      priceType,
      Code,
      Description,
      Manufacturer_Ref,
      Price,
      Type,
      Brands,
      Warranty,
      Stock_Quantity,
      Services,
      Review_id,
      Company_Name,
    } = item;

    if (
      company.saleWithZeroStockPermissionId ===
        constants.STOCK_PERMISSIONS_ID.BLOCK &&
      Stock_Quantity <= 0
    )
      return setIsBlockAddItemModalOpen(true);

    const newItems = [...items];
    const index = newItems.findIndex(
      (item) => item.Product_id === id && item.Type === Type
    );
    if (index !== -1) {
      newItems[index].Quantity++;
    } else if (Type === 'Kit') {
      item.Products.map((product) => {
        const findAlreadyAdded = newItems.findIndex(
          (item) => item.Product_id === product.productId
        );

        if (findAlreadyAdded !== -1) {
          newItems[findAlreadyAdded].Quantity++;
        } else
          newItems.push({
            id: product.productId,
            Code: product.code,
            Manufacturer_Ref: product.manufacturerRef,
            Product_id: product.productId,
            Description: product.description,
            Unit_Value: product.kitPrice,
            Value_Cost: product.kitPrice,
            Quantity: 1,
            Type: 'Produto',
          });
      });
    } else {
      const product = {
        id,
        Code,
        Manufacturer_Ref,
        Product_id: id,
        Description,
        Brand: Type === 'Produto' && Brands ? Brands.Description : null,
        Value_Cost: Type === 'Produto' ? Sale_Price : Price,
        Unit_Value: Type === 'Produto' ? Sale_Price : Price,
        priceType,
        Quantity: 1,
        Type,
        WarrantyCode: Warranty ? Warranty.Code : 0,
        WarrantyDescription: Warranty
          ? Warranty.Description +
            ' ' +
            (Warranty.Months === '1'
              ? Warranty.Months + ' mês'
              : Warranty.Months + ' meses')
          : null,
        Discount_Value: 0,
        edit: false,
        reviewId: Review_id,
        Company_Name,
      };

      newItems.push(product);
    }

    if (Services?.length > 0) {
      Services.forEach((service) => {
        const index = newItems.findIndex((item) => item.id === service.id);

        if (index !== -1) {
          newItems[index].Quantity++;
        } else {
          newItems.push({
            id: service.serviceId || service.id,
            Code: service.code || service.Code,
            Manufacturer_Ref: null,
            Product_id: service.serviceId || service.id,
            Description: service.Description || service.description,
            Brand: null,
            Value_Cost: service.Price || service.kitPrice,
            Unit_Value: service.Price || service.kitPrice,
            Quantity: 1,
            Type: 'Serviço',
            WarrantyCode: 0,
            WarrantyDescription: null,
            Discount_Value: 0,
            edit: false,
            Commission_Rate: service.Comission_Rate,
            reviewId: Review_id,
          });
        }
      });
    }

    setItems(newItems);
    setCleanInputFlag(true);
  }

  function handleClearSheet() {
    if (quickQuoteInfo) {
      setItemsToDelete(quickQuoteInfo.QuickQuotesItems);
    }

    setItems([]);
    dispatch(reset('printQuickQuote'));
    setIsClearSheetModalOpen(false);
  }

  const getQuickQuoteLastCode = async () => {
    const lastCode = await quickQuotesRepository.getLastCode(companyId);
    return setLastCode(lastCode);
  };

  function handleInitiateSale(quickQuoteId) {
    localStorage.setItem('COTATIONS', JSON.stringify(items));
    setIsConfirmInitiateSaleModalOpen(false);

    const quickHash = encrypt(quickQuoteId, '@OS-dig:quickQuoteId');
    history.push(`${constants.ROUTES.SALE}?quickQuoteId=${quickHash}`);
  }

  function handleSubmitToSale() {
    if (!!quickQuoteInfoToHandle) {
      update(items);
    } else {
      create(items);
    }
  }

  const deleteQuickQuote = async () => {
    try {
      const response = await quickQuotesRepository.deleteQuickQuote(
        quickQuoteInfo.id
      );

      return setQuickQuoteInfoToHandle(null);
    } catch (err) {
      console.log(err);
    }
  };

  const create = async (items) => {
    try {
      setLoading(true);
      const objectToCreate = await buildObjectToCreate(items, 1);
      const { data } = await quickQuotesRepository.create(objectToCreate);

      handleInitiateSale(data.id);
    } catch (err) {
      if (err?.response) {
        return toastr.warning(err?.response?.message);
      }
      return toastr.warning(err.message);
    } finally {
      setLoading(false);
    }
  };

  const update = async (items) => {
    try {
      setLoading(true);
      const objectToUpdate = await buildObjectToUpdate(
        quickQuoteInfoToHandle,
        items
      );
      await quickQuotesRepository.update(
        quickQuoteInfoToHandle.id,
        objectToUpdate
      );

      handleInitiateSale(quickQuoteInfoToHandle.id);
    } catch (err) {
      if (err?.response) {
        return toastr.warning(err?.response?.message);
      }
      return toastr.warning(err.message);
    } finally {
      setLoading(false);
    }
  };

  const buildObjectToCreate = async (items) => {
    let totalProducts = 0,
      totalServices = 0,
      subtotal = 0,
      finalValue = 0;

    var itemsArray = [];

    items.map((item) => {
      if (item.Type == 'Serviço') {
        totalServices += item.Quantity * item.Value_Cost;
      }

      if (item.Type == 'Produto') {
        totalProducts += item.Quantity * item.Value_Cost;
      }

      let newItem = {
        type: item.Type,
        productId: item.Type == 'Produto' ? item.Product_id : '',
        serviceId: item.Type == 'Serviço' ? item.Product_id : '',
        description: item.Description,
        quantity: item.Quantity,
        unitValue: item.Value_Cost,
        unitDiscountValue: 0,
        discountValue: 0,
        amount: item.Quantity * item.Value_Cost,
      };

      return itemsArray.push(newItem);
    });

    var quickQuote = {
      date: new Date(),
      quickQuoteStatusId: 1,
      productsValue: totalProducts,
      productsDiscountValue: 0,
      servicesValue: totalServices,
      servicesDiscountValue: 0,
      totalDiscountValue: 0,
      subtotal: totalProducts + totalServices,
      discountType: '%',
      finalValue: totalProducts + totalServices,
      companyId: companyId,
      userId: userId,
      code: lastCode,
      productsDiscountPercentage: 0,
      servicesDiscountPercentage: 0,
      totalDiscountPercentage: 0,
    };

    var object = {
      quickQuote,
      items: itemsArray,
    };

    return object;
  };

  const buildObjectToUpdate = async (values, items) => {
    let totalProducts = 0,
      totalServices = 0;

    var itemsArray = [];

    items.map((item) => {
      if (item.Type == 'Serviço') {
        totalServices += item.Quantity * item.Value_Cost;
      }

      if (item.Type == 'Produto') {
        totalProducts += item.Quantity * item.Value_Cost;
      }

      let newItem = {
        type: item.Type,
        productId: item.Type == 'Produto' ? item.Product_id : '',
        serviceId: item.Type == 'Serviço' ? item.Product_id : '',
        description: item.Description,
        quantity: item.Quantity,
        unitValue: item.Value_Cost,
        unitDiscountValue: 0,
        discountValue: 0,
        amount: item.Quantity * item.Value_Cost,
      };

      return itemsArray.push(newItem);
    });

    var quickQuote = {
      date: values.date,
      quickQuoteStatusId: 1,
      vehicle: values.model,
      licensePlate: values.licensePlate,
      observations: values.observations,
      productsValue: totalProducts,
      productsDiscountValue: 0,
      servicesValue: totalServices,
      servicesDiscountValue: 0,
      totalDiscountValue: 0,
      subtotal: totalProducts + totalServices,
      discountType: '%',
      finalValue: totalProducts + totalServices,
      customerName: values.customerName,
      customerTelephone: values.customerTelephone,
      customerEmail: values.customerEmail,
      companyId: companyId,
      userId: userId,
      code: values.code,
      productsDiscountPercentage: 0,
      servicesDiscountPercentage: 0,
      totalDiscountPercentage: 0,
    };

    return {
      quickQuote,
      items: itemsArray,
    };
  };

  return (
    <>
      <Modal
        show
        dialogClassName="consult-prices-modal-wrapper"
        onHide={() =>
          items.length ? setIsConfirmCancelModalOpen(true) : onCancel()
        }
        animation
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div style={{ position: 'relative' }}>
              <strong>Orçamento Rápido</strong>

              <span
                style={{
                  fontSize: '12px',
                  marginTop: '15px',
                  right: '0',
                }}
                id="required-field-label"
              >
                * Serão exibidos apenas os produtos e serviços ativos e com
                preço
              </span>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <ProductsServicesTable
              productsList
              servicesList
              hideConsumption
              onInclude={handleAdd}
              cleanInputFlag={cleanInputFlag}
              setCleanInputFlag={setCleanInputFlag}
              allowPromotionalPrices={allowPromotionalPrices}
              hasKitSearch={hasKitRegistration && (isPlanPrime || isPlanBasic)}
              hasWholesalePriceSelect
            />
          </div>
          <div>
            <ConsultPricesSheet items={items} setItems={setItems} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="flex end align-center">
            <Button
              pullRight
              style={{
                backgroundColor: '#5bc0de',
                borderColor: '#5bc0de',
                height: '35px',
              }}
              onClick={onCancel}
              fill
            >
              Consulta Orçamentos Rápidos
            </Button>
            <Button
              style={{ height: '35px' }}
              bsStyle="warning"
              pullRight
              fill
              onClick={() => setIsPrintQuickQuoteModalOpen(true)}
            >
              Salvar Orçamento Rápido
            </Button>
            <Button
              style={{ height: '35px' }}
              bsStyle="danger"
              pullRight
              fill
              onClick={() =>
                items.length ? setIsConfirmCancelModalOpen(true) : onCancel()
              }
            >
              Cancelar
            </Button>
            <Button
              style={{ height: '35px' }}
              disabled={!items.length}
              pullRight
              bsStyle="primary"
              onClick={() => setIsClearSheetModalOpen(true)}
              fill
            >
              Limpar Itens
            </Button>
            <Button
              style={{ height: '35px' }}
              bsStyle="info"
              pullRight
              fill
              onClick={handleInitSale}
              disabled={!items.length}
            >
              Iniciar Venda
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      <AlertModal
        show={isConfirmCancelModalOpen}
        animation={false}
        message="Deseja sair da tela de Orçamento Rápido? As alterações não serão salvas."
        onHide={() => setIsConfirmCancelModalOpen(false)}
        onCancel={() => setIsConfirmCancelModalOpen(false)}
        onSubmit={onCancel}
      />

      <AlertModal
        show={isClearSheetModalOpen}
        message="Deseja limpar todos os itens?"
        onHide={() => setIsClearSheetModalOpen(false)}
        onCancel={() => setIsClearSheetModalOpen(false)}
        onSubmit={handleClearSheet}
      />

      <AlertModal
        show={isConfirmInitiateSaleModalOpen}
        message="Deseja iniciar a venda com os itens selecionados?"
        onHide={() => setIsConfirmInitiateSaleModalOpen(false)}
        onCancel={() => setIsConfirmInitiateSaleModalOpen(false)}
        onSubmit={handleSubmitToSale}
      />

      <PrintQuickQuoteModal
        show={isPrintQuickQuoteModalOpen}
        onHide={() => setIsPrintQuickQuoteModalOpen(false)}
        items={items}
        quickQuoteInfo={quickQuoteInfoToHandle}
        onSuccess={() => {
          setIsPrintQuickQuoteModalOpen(false);
          onSuccess();
        }}
      />

      {loading && <PureLoader message="Aguarde..." />}
      <BlockSalesModals
        onSubmit={() => setIsConfirmInitiateSaleModalOpen(true)}
      />

      {loading && <PureLoader message="Aguarde..." />}
      <Modal
        show={isBlockAddItemModalOpen}
        onHide={() => setIsBlockAddItemModalOpen(false)}
        animation
      >
        <Modal.Header>
          <Modal.Title>
            <strong>Produto sem estoque</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>
            <strong>
              Não é possível adicionar o produto na venda pois ele não tem
              estoque.
            </strong>
          </span>
          <br />
          <br />
          <span>Selecione outro produto ou seu similar.</span>
        </Modal.Body>
        <Modal.Footer>
          <Button
            bsStyle="danger"
            pullRight
            fill
            onClick={() => setIsBlockAddItemModalOpen(false)}
          >
            Voltar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default withRouter(ConsultPricesModal);
