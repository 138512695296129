import React, { useState } from 'react'
import { reduxForm } from 'redux-form'
import { withRouter } from 'react-router-dom'

import CardForm from '../../../../components/CardForm'
import AlertModal from '../../../../../components/AlertModal/AlertModal'
import FormSubmitButtons from '../../../../components/FormSubmitButtons'
import BasicData from './BasicData'
import Address from '../../../Clients/NewClient/FormClient/Endereco'

function FormNewProvider({
  providerId,
  handleSubmit,
  edit,
  loading,
  onCancel,
}) {
  const [basicData, setBasicData] = useState(true)
  const [address, setAddress] = useState(false)
  const [cancelModal, setCancelModal] = useState(false)

  return (
    <form
      onSubmit={handleSubmit}
      style={{ display: 'flex', flexDirection: 'column' }}
    >
      <CardForm
        show={basicData}
        title='Dados Básicos'
        onClick={() => setBasicData(!basicData)}
        proceedButton
        onProceed={() => {
          setBasicData(false)
          setAddress(true)
        }}
      >
        <BasicData edit={edit} />
      </CardForm>
      <CardForm
        show={address}
        title='Endereço (Opcional)'
        onClick={() => setAddress(!address)}
      >
        <Address form='formProvider' edit={edit} />
      </CardForm>

      <FormSubmitButtons
        loading={loading}
        onCancel={() => setCancelModal(true)}
        hasId={!!providerId}
        onSubmit={handleSubmit}
      />

      {cancelModal && (
        <AlertModal
          show={cancelModal}
          onHide={() => setCancelModal(false)}
          onCancel={() => setCancelModal(false)}
          message='Essa ação não salvará nenhuma das informações apresentadas nessa tela. Deseja realmente cancelar?'
          onSubmit={onCancel}
        />
      )}
    </form>
  )
}

export default reduxForm({
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  form: 'formProvider',
})(withRouter(FormNewProvider))
