import { ERROR_TAX_SETTINGS, ERROR_CLIENTS, ERROR_PRODUCTS, INVOICE_FINALITY } from '../../../../../utils/constants';

const today = new Date().toISOString().substring(0, 10);

export function fiscal(values, finality) {
  const errors = [];
  if (finality === INVOICE_FINALITY.NORMAL) {
    if (!values.PIS) {
      errors.push(ERROR_TAX_SETTINGS[0]);
    };
    if (!values.COFINS) {
      errors.push(ERROR_TAX_SETTINGS[1]);
    };
    if (!values.tokenIBPT) {
      errors.push(ERROR_TAX_SETTINGS[3]);
    };
    if (!values.isDigitalCertificateImported) {
      errors.push(ERROR_TAX_SETTINGS[5]);
    };
    if (values.isDigitalCertificateImported && values.digitalCertificateExpireDate < today) {
      errors.push(ERROR_TAX_SETTINGS[6]);
    };
  }

  return errors;
};

export function client(values, finality) {
  const errors = [];

  if (values.Type === "Juridica" && finality === INVOICE_FINALITY.NORMAL) {
    if (!values.Status_IE) {
      errors.push({ ...ERROR_CLIENTS[0], client: values });
    }

    const deliveryAddress = values.Address.filter(i => i.Type_Address === "Entrega")
    const principalAddress = values.Address.filter(i => i.Type_Address === "Principal")

    if (deliveryAddress.length === 0) {
      errors.push({ ...ERROR_CLIENTS[1], client: values });
    }

    if (principalAddress.length === 0) {
      errors.push({ ...ERROR_CLIENTS[2], client: values });
    }
  };

  return errors;
};

export async function product(values, taxRegime) {
  const errors = [];

  if (!values.origin && values.origin !== 0) {
    errors.push({ ...ERROR_PRODUCTS[2], item: values });
  };
  if (!values.CSTCSOSN) {
    if (taxRegime === 'Simples Nacional') { //CSOSN
      errors.push({ ...ERROR_PRODUCTS[3], item: values });
    } else { //CST
      errors.push({ ...ERROR_PRODUCTS[4], item: values });
    }
  };
  if (!values.CFOP || values.CFOP === "0" || values.CFOP === 'null') {
    errors.push({ ...ERROR_PRODUCTS[5], item: values });
  };
  if (!values.NCM) {
    errors.push({ ...ERROR_PRODUCTS[6], item: values });
  };
  if (!values.STPIS) {
    errors.push({ ...ERROR_PRODUCTS[7], item: values });
  };
  if (!values.STCOFINS) {
    errors.push({ ...ERROR_PRODUCTS[8], item: values });
  };


  if (
    values.CFOP === 5650 ||
    values.CFOP === 5655 ||
    values.CFOP === 5656
  ) {
    if (!values.anpCode) {
      errors.push({ ...ERROR_PRODUCTS[9], item: values });
    };
    if (!values.anpUF) {
      errors.push({ ...ERROR_PRODUCTS[10], item: values });
    };
  };

  return errors;
};