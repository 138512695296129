import React from 'react'
import { Document, Text, Page, StyleSheet, View } from '@react-pdf/renderer'
import { currency } from '../../../client/components/ToNormalize/ToNormalize'
import { format } from 'date-fns'

const ItemLabel = ({ label, value, width }) => (
  <View style={{ display: 'flex', flexDirection: 'row', width: width }}>
    <Text style={{ fontWeight: 'bold' }}>{label}: </Text>
    <Text>{value}</Text>
  </View>
)

const SaleTable = ({ data, type, total }) => {
  return (
    <View>
      <View style={styles.containerRow}>
        <Text style={[styles.boldText, { width: '10%' }]}>Data Criação</Text>
        <Text style={[styles.boldText, { width: '10%' }]}>
          Data&nbsp;{type === 'canceled' ? 'Canc.' : 'Fech.'}
        </Text>
        <Text style={[styles.boldText, { width: '15%' }]}>Tipo</Text>
        <Text style={[styles.boldText, { width: '10%', textAlign: 'center' }]}>
          Número
        </Text>
        <Text style={[styles.boldText, { width: '20%' }]}>Cliente</Text>
        <Text style={[styles.boldText, { width: '10%' }]}>Placa</Text>
        <Text style={[styles.boldText, { width: '10%' }]}>Valor</Text>
        <Text style={[styles.boldText, { width: '20%', textAlign: 'center' }]}>
          Vendedor
        </Text>
      </View>

      <View style={[styles.line]} />

      {data.map((sale) => (
        <View key={sale.saleId} style={styles.containerRow}>
          <Text style={[{ width: '10%' }]}>
            {format(new Date(sale.createdAt), 'dd/MM/yyyy')}
          </Text>
          <Text style={[{ width: '10%' }]}>
            {type !== 'open'
              ? format(
                  new Date(
                    type === 'canceled' ? sale.updatedAt : sale.createdAt
                  ),
                  'dd/MM/yyyy'
                )
              : ''}
          </Text>
          <Text style={[{ width: '15%' }]}>{sale.type}</Text>
          <Text style={[{ width: '10%', textAlign: 'center' }]}>
            {sale.code}
          </Text>
          <Text style={[{ width: '20%' }]}>{sale.name}</Text>
          <Text style={[{ width: '10%' }]}>{sale.plate}</Text>
          <Text style={[{ width: '10%' }]}>{currency(sale.valueTotal)}</Text>
          <Text style={[{ width: '20%', textAlign: 'center' }]}>
            {sale.nameSeller}
          </Text>
        </View>
      ))}

      <View style={[styles.line]} />

      <View
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          flexDirection: 'row',
          marginTop: '4px',
          marginBottom: '4px',
        }}
      >
        <Text style={[styles.boldText]}>Total: {currency(total)}</Text>
      </View>
    </View>
  )
}

const TotalsByStatus = ({ data }) => {
  return (
    <View>
      <View style={styles.containerRow}>
        <Text style={[styles.boldText, { width: '16%' }]}>Tipo</Text>
        <Text style={[styles.boldText, { width: '15%' }]}>Status</Text>
        <Text style={[styles.boldText, { width: '18%', textAlign: 'center' }]}>
          Atendimento
        </Text>
        <Text style={[styles.boldText, { width: '14%', textAlign: 'center' }]}>
          Quantidade
        </Text>
        <Text style={[styles.boldText, { width: '20%' }]}>Total</Text>
      </View>

      <View style={styles.line} />

      {data.map((totalStatus, index) => (
        <View style={styles.containerRow} key={index}>
          <View style={{ width: '16%' }}>
            <Text style={styles.text}>{totalStatus.type}</Text>
          </View>
          <View style={{ width: '15%' }}>
            <Text style={styles.text}>{totalStatus.status}</Text>
          </View>
          <View style={{ width: '18%', textAlign: 'center' }}>
            <Text style={styles.text}>{totalStatus.attendance}</Text>
          </View>
          <View style={{ width: '14%', textAlign: 'center' }}>
            <Text style={styles.text}>{totalStatus.quantity}</Text>
          </View>
          <View style={{ width: '20%' }}>
            <Text style={styles.text}>{currency(totalStatus.total)}</Text>
          </View>
        </View>
      ))}

      <View style={styles.line} />
    </View>
  )
}

export default function SalesByAttendanceSyntheticPDF({
  initialDate,
  finalDate,
  reportData,
}) {
  return (
    <Document title="listagem-de-clientes">
      <Page orientation="landscape" style={styles.page}>
        <View>
          <Text style={styles.title}>Vendas por Atendimento - Sintético</Text>
        </View>

        <View style={styles.containerRow}>
          <ItemLabel
            label="Data Inicial"
            value={format(new Date(initialDate), 'dd/MM/yyyy')}
            width="15%"
          />
          <ItemLabel
            label="Data Final"
            value={format(new Date(finalDate), 'dd/MM/yyyy')}
          />
        </View>

        <View style={styles.line} />

        <View>
          <Text style={[styles.title, { textAlign: 'center' }]}>
            Totais por Tipo/Status
          </Text>
        </View>

        <View style={styles.line} />

        <TotalsByStatus data={reportData.totalStatus} />

        <View>
          <Text style={[styles.title, { textAlign: 'center' }]}>
            Atendimentos Internos
          </Text>
        </View>

        {reportData.attendanceInternal.attendanceInternalFinished.length > 0 ? (
          <View>
            <View style={styles.line} />

            <View>
              <Text
                style={[
                  styles.title,
                  { textAlign: 'center', fontSize: '14px' },
                ]}
              >
                Finalizadas
              </Text>
            </View>

            <View style={styles.line} />

            <SaleTable
              type="finished"
              data={reportData.attendanceInternal.attendanceInternalFinished}
              total={reportData.totalStatus
                ?.filter(
                  (item) =>
                    item.status === 'Finalizado(a)' &&
                    item.attendance === 'Interno'
                )
                ?.reduce((prev, curr) => prev + Number(curr.total), 0)}
            />
          </View>
        ) : (
          <View />
        )}

        {reportData.attendanceInternal.attendanceInternalOpen.length > 0 ? (
          <View>
            <View style={styles.line} />

            <View>
              <Text
                style={[
                  styles.title,
                  { textAlign: 'center', fontSize: '14px' },
                ]}
              >
                Abertas
              </Text>
            </View>

            <View style={styles.line} />

            <SaleTable
              type="open"
              data={reportData.attendanceInternal.attendanceInternalOpen}
              total={reportData.totalStatus
                ?.filter(
                  (item) =>
                    item.status === 'Em Aberto(a)' &&
                    item.attendance === 'Interno'
                )
                ?.reduce((prev, curr) => prev + Number(curr.total), 0)}
            />
          </View>
        ) : (
          <View />
        )}

        {reportData.attendanceInternal.attendanceInternalCanceled.length > 0 ? (
          <View>
            <View style={styles.line} />

            <View>
              <Text
                style={[
                  styles.title,
                  { textAlign: 'center', fontSize: '14px' },
                ]}
              >
                Canceladas
              </Text>
            </View>

            <View style={styles.line} />

            <SaleTable
              type="open"
              data={reportData.attendanceInternal.attendanceInternalCanceled}
              total={reportData.totalStatus
                ?.filter(
                  (item) =>
                    item.status === 'Cancelado(a)' &&
                    item.attendance === 'Interno'
                )
                ?.reduce((prev, curr) => prev + Number(curr.total), 0)}
            />
          </View>
        ) : (
          <View />
        )}

        <View style={styles.line} />

        <View>
          <Text style={[styles.title, { textAlign: 'center' }]}>
            Atendimentos Externos
          </Text>
        </View>

        {reportData.attendanceExternal.attendanceExternalFinished.length > 0 ? (
          <View>
            <View style={styles.line} />

            <View>
              <Text
                style={[
                  styles.title,
                  { textAlign: 'center', fontSize: '14px' },
                ]}
              >
                Finalizadas
              </Text>
            </View>

            <View style={styles.line} />

            <SaleTable
              type="finished"
              data={reportData.attendanceExternal.attendanceExternalFinished}
              total={reportData.totalStatus
                ?.filter(
                  (item) =>
                    item.status === 'Finalizado(a)' &&
                    item.attendance === 'Externo'
                )
                ?.reduce((prev, curr) => prev + Number(curr.total), 0)}
            />
          </View>
        ) : (
          <View />
        )}

        {reportData.attendanceExternal.attendanceExternalOpen.length > 0 ? (
          <View>
            <View style={styles.line} />

            <View>
              <Text
                style={[
                  styles.title,
                  { textAlign: 'center', fontSize: '14px' },
                ]}
              >
                Abertas
              </Text>
            </View>

            <View style={styles.line} />

            <SaleTable
              type="open"
              data={reportData.attendanceExternal.attendanceExternalOpen}
              total={reportData.totalStatus
                ?.filter(
                  (item) =>
                    item.status === 'Em Aberto(a)' &&
                    item.attendance === 'Externo'
                )
                ?.reduce((prev, curr) => prev + Number(curr.total), 0)}
            />
          </View>
        ) : (
          <View />
        )}

        {reportData.attendanceExternal.attendanceExternalCanceled.length > 0 ? (
          <View>
            <View style={styles.line} />

            <View>
              <Text
                style={[
                  styles.title,
                  { textAlign: 'center', fontSize: '14px' },
                ]}
              >
                Canceladas
              </Text>
            </View>

            <View style={styles.line} />

            <SaleTable
              type="open"
              data={reportData.attendanceExternal.attendanceExternalCanceled}
              total={reportData.totalStatus
                ?.filter(
                  (item) =>
                    item.status === 'Cancelado(a)' &&
                    item.attendance === 'Externo'
                )
                ?.reduce((prev, curr) => prev + Number(curr.total), 0)}
            />
          </View>
        ) : (
          <View />
        )}
      </Page>
    </Document>
  )
}

const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 10,
    fontFamily: 'Roboto',
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  boldText: {
    fontSize: 9,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  text: {
    fontSize: 9,
    fontFamily: 'Roboto',
  },
  line: {
    width: '100%',
    borderBottomWidth: 1,
    height: 2,
  },
  containerRow: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'left',
  },
  bold: {
    fontWeight: 'bold',
  },
})
