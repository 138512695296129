import React from 'react'
import Logo from 'assets/img/os-digital-letra-branca.png'
import '../styles.css'

const Footer = () => {
  return (
    <>
      <div className="income-statement__footer-rectangle">
        <center>
          <img
            style={{
              width: '150px',
              height: '45px',
            }}
            src={Logo}
            alt="os-digital-logo"
          />
        </center>
      </div>
    </>
  )
}

export default Footer
