import React, { useState, useEffect } from 'react';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';
import { initialize } from 'redux-form';
import { useDispatch } from 'react-redux';

import { onlyNumbers } from '../../../../components/ToNormalize/ToNormalize';
import {
  cepMask,
  phoneMask,
  cpfMask,
  cnpjMask,
} from '../../../../components/ToNormalize/ToNormalize';
import {
  validateCpf,
  validateCnpj,
  validadeEmail,
  validadePhone,
} from '../../../../components/Validations/Validations';
import customersRepository from '../../../../../repositories/Customers';
import { useAuth } from '../../../../../contexts/auth';
import constants from '../../../../../utils/constants';

import Form from './Form';
import FormModalVehicle from '../../../../components/FormModals/Vehicle';
import './styles.css';
import AlertModal from 'components/AlertModal';

function FormClient({ history, ClientId, modal, handleModal, hideModal }) {
  const [Client_id, setClientid] = useState('');
  const [isNewVehicleModalOpen, setIsNewVehicleModalOpen] = useState(false);
  const [isVehicleFormModalOpen, setIsVehicleFormModalOpen] = useState(false);
  const [isCancelVehicleModalOpen, setIsCancelVehicleModalOpen] =
    useState(false);
  const [loading, setLoading] = useState(false);

  const { companyId } = useAuth();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!!ClientId) {
      loadCustomer();
    }
  }, []);

  async function loadCustomer() {
    setLoading(true);
    try {
      const customer = await customersRepository.show(ClientId);
      loadInputs(customer);
    } catch (err) {
      console.log(err);
      toastr.error('Falha ao carregar dados do Cliente', 'Tente novamente');
    }
    setLoading(false);
  }

  function loadInputs(client) {
    const {
      id,
      Type,
      Status,
      Cpf_Cnpj,
      Company_Name,
      Trading_Name,
      Email,
      createdAt,
      Observation,
      IE,
      Date_Birth,
      Rg,
      Status_IE,
      IM,
      Contact,
      Address,
      Phones,
      withholdsISS,
      sellingPriceType,
      tradeRepresentativeId,
      debtLimit,
      availableCredit,
      approachId,
    } = client;

    const clientValues = {
      hasCpfCnpj: !!Cpf_Cnpj,
      Client_id: id,
      Type,
      Status: Status === 'Ativo',
      Cpf_Cnpj: Type === 'Fisica' ? cpfMask(Cpf_Cnpj) : cnpjMask(Cpf_Cnpj),
      Company_Name,
      Trading_Name,
      Email,
      createdAt: new Date(createdAt).toLocaleDateString(),
      Observation,
      IE,
      Status_IE,
      IM,
      Date_Birth: Date_Birth
        ? new Date(Date_Birth).toISOString().substring(0, 10)
        : '',
      Rg,
      Contact,
      withholdsISS,
      debtLimit,
      availableCredit,
      sellingPriceType,
      tradeRepresentativeId,
      approachId,

      Phone_id: Phones[0].id,
      Number_Phone1: phoneMask(Phones[0].Number_Phone1),
      Number_Phone2: phoneMask(Phones[0].Number_Phone2),
      Contact: Phones[0].Contact,
    };
    const principalAddress = Address.find(
      (address) => address.Type_Address === 'Principal'
    );
    const deliveryAddress = Address.find(
      (address) => address.Type_Address === 'Entrega'
    );

    if (!!principalAddress) {
      const PrincipalAddress = {
        Address: principalAddress?.Address,
        Address_Number: principalAddress?.Address_Number,
        City: principalAddress?.City,
        Complement: principalAddress?.Complement,
        Neighborhood: principalAddress?.Neighborhood,
        Reference_Point: principalAddress?.Reference_Point,
        State: principalAddress?.State,
        Zipcode: cepMask(principalAddress.Zipcode),
        cityIbgeCode: principalAddress.cityIbgeCode,
      };
      clientValues.PrincipalAddress_id = principalAddress.id;
      clientValues.PrincipalAddress = PrincipalAddress;
    }

    if (!!deliveryAddress) {
      const DeliveryAddress = {
        Address: deliveryAddress?.Address,
        Address_Number: deliveryAddress?.Address_Number,
        City: deliveryAddress?.City,
        Complement: deliveryAddress?.Complement,
        Neighborhood: deliveryAddress?.Neighborhood,
        Reference_Point: deliveryAddress?.Reference_Point,
        State: deliveryAddress?.State,
        Zipcode: cepMask(deliveryAddress?.Zipcode),
        cityIbgeCode: deliveryAddress.cityIbgeCode,
      };
      clientValues.DeliveryAddress_id = deliveryAddress.id;
      clientValues.DeliveryAddress = DeliveryAddress;
    }
    dispatch(initialize('cadastroCliente', clientValues));
  }

  async function validations(values) {
    const { PrincipalAddress, DeliveryAddress } = values;
    setLoading(true);
    if (!values.Company_Name || !values.Number_Phone2) {
      setLoading(false);
      return toastr.warning('Campos obrigatórios não preenchidos');
    }

    if (values.Status_IE === 'Contribuinte de ICMS' && !values.IE) {
      setLoading(false);
      return toastr.warning('Campos obrigatórios não preenchidos');
    }

    if (values.Type === 'Juridica' && !values.Contact) {
      setLoading(false);
      return toastr.warning('Campos obrigatórios não preenchidos');
    }

    if (values.Status_IE === 'Contribuinte de ICMS' && !values.IE) {
      setLoading(false);
      return toastr.warning(
        'Para Contribuinte de ICMS, é necessário preencher o campo Inscrição Estadual'
      );
    }

    // VALIDAÇÃO CPF/CNPJ

    if (values.Cpf_Cnpj) {
      let Cpf_Cnpj = onlyNumbers(values.Cpf_Cnpj);

      if (values.Type === 'Fisica') {
        if (!validateCpf(Cpf_Cnpj)) {
          setLoading(false);
          return toastr.warning('CPF inválido', 'Preencha corretamente');
        }
      } else {
        if (!validateCnpj(Cpf_Cnpj)) {
          setLoading(false);
          return toastr.warning('CNPJ inválido', 'Preencha corretamente');
        }
      }

      const response = await customersRepository.getByCompanyAndCpfCnpj({
        cpfCnpj: Cpf_Cnpj,
        companyId: values.Company_id,
      });
      if (response.status) {
        if (
          !ClientId ||
          (ClientId && String(response.data.id) !== String(ClientId))
        ) {
          setLoading(false);
          return toastr.warning(
            `${values.Type === 'Fisica' ? 'CPF' : 'CNPJ'} já existe`,
            `Já existe um cliente com este ${
              values.Type === 'Fisica' ? 'CPF' : 'CNPJ'
            }`
          );
        }
      }
    }
    //VALIDAÇÃO CPF/CNPJ - FIM

    if (values.Email) {
      if (!validadeEmail(values.Email)) {
        setLoading(false);
        return toastr.warning('E-mail inválido', 'Preencha corretamente');
      }
    }

    if (!validadePhone(values.Number_Phone2)) {
      setLoading(false);
      return toastr.warning('Telfone inválido', 'Preencha corretamente');
    }

    if (PrincipalAddress?.Zipcode) {
      if (onlyNumbers(PrincipalAddress.Zipcode ?? '').length < 8) {
        setLoading(false);
        return toastr.warning('CEP inválido em Endereço Principal');
      }
    } else {
      values.PrincipalAddress = null;
    }

    if (DeliveryAddress?.Zipcode) {
      if (onlyNumbers(DeliveryAddress.Zipcode ?? '').length < 8) {
        setLoading(false);
        return toastr.warning('CEP inválido em Endereço de Entrega');
      }
    } else {
      values.DeliveryAddress = null;
    }

    if (ClientId) return update(values);
    else return create(values);
  }

  async function create(values) {
    const {
      Company_id,
      Company_Name,
      Trading_Name,
      Cpf_Cnpj,
      Type,
      IE,
      Status_IE,
      IM,
      Date_Birth,
      Status,
      Email,
      Rg,
      Observation,
      withholdsISS,
      tradeRepresentativeId,
      debtLimit,
      availableCredit,
      sellingPriceType,
      approachId,

      PrincipalAddress,
      DeliveryAddress,

      Number_Phone1,
      Number_Phone2,
      Contact,
    } = values;
    console.log(values);
    try {
      const customer = await customersRepository.create({
        customer: {
          Company_Name,
          Trading_Name,
          Cpf_Cnpj: Cpf_Cnpj && Cpf_Cnpj.replace(/\D/g, ''),
          Type,
          IE,
          Status_IE,
          IM,
          Date_Birth: new Date(Date_Birth),
          Status: Status ? 'Ativo' : 'Inativo',
          Email,
          Rg,
          Observation,
          Company_id,
          withholdsISS: withholdsISS ? withholdsISS : 2,
          tradeRepresentativeId: tradeRepresentativeId,
          debtLimit,
          availableCredit,
          sellingPriceType,
          approachId: approachId === '' ? null : approachId,
        },
        principalAddress: PrincipalAddress
          ? getFormatedPrincipalAddress(PrincipalAddress)
          : undefined,
        deliveryAddress: DeliveryAddress
          ? getFormatedDeliveryAddress(DeliveryAddress)
          : PrincipalAddress
          ? getFormatedDeliveryAddress(PrincipalAddress)
          : undefined,
        phones: {
          Type_Phone1: 'Telefone',
          Type_Phone2: 'Celular',
          Number_Phone1: Number_Phone1
            ? Number_Phone1.replace(/\D/g, '')
            : undefined,
          Number_Phone2: Number_Phone2
            ? Number_Phone2.replace(/\D/g, '')
            : undefined,
          Contact,
        },
      });

      setClientid(customer.id);

      if (modal) {
        hideModal();

        return handleModal(customer.id);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
      return toastr.error(
        'Erro no salvamento',
        'Algo inesperado aconteceu. Por favor, repita o procedimento'
      );
    }

    setLoading(false);
    toastr.success('Sucesso', `Cliente ${Company_Name} salvo com sucesso`);

    return setIsNewVehicleModalOpen(true);
  }

  async function update(values) {
    const {
      Company_Name,
      Trading_Name,
      Cpf_Cnpj,
      Type,
      IE,
      Status_IE,
      IM,
      Date_Birth,
      Status,
      Email,
      Rg,
      Observation,
      withholdsISS,
      tradeRepresentativeId,
      debtLimit,
      availableCredit,
      sellingPriceType,

      PrincipalAddress,
      DeliveryAddress,

      Number_Phone1,
      Number_Phone2,
      Contact,
      Phone_id,
      DeliveryAddress_id,
      PrincipalAddress_id,

      isSameDeliveryAddress,
      approachId,
    } = values;

    try {
      await customersRepository.update(ClientId, {
        identifiers: {
          PrincipalAddress_id: PrincipalAddress_id,
          DeliveryAddress_id: DeliveryAddress_id,
          Phone_id,
        },
        customer: {
          Company_Name,
          Trading_Name: Trading_Name ?? '',
          Cpf_Cnpj: Cpf_Cnpj && Cpf_Cnpj.replace(/\D/g, ''),
          Type,
          IE: IE ?? '',
          Status_IE: Status_IE ?? '',
          IM: IM ?? '',
          Date_Birth: new Date(Date_Birth),
          Status: Status ? 'Ativo' : 'Inativo',
          Email,
          Rg: Rg ?? '',
          Observation: Observation ?? '',
          withholdsISS: withholdsISS ? withholdsISS : 2,
          debtLimit,
          availableCredit,
          tradeRepresentativeId: tradeRepresentativeId,
          sellingPriceType,
          approachId: approachId === '' ? null : approachId,
        },
        phones: {
          Number_Phone1: Number_Phone1 ? Number_Phone1.replace(/\D/g, '') : '',
          Number_Phone2: Number_Phone2 ? Number_Phone2.replace(/\D/g, '') : '',
          Contact,
        },
        principalAddress: PrincipalAddress
          ? getFormatedPrincipalAddress(PrincipalAddress)
          : undefined,
        deliveryAddress: isSameDeliveryAddress
          ? getFormatedDeliveryAddress(PrincipalAddress)
          : DeliveryAddress
          ? getFormatedDeliveryAddress(DeliveryAddress)
          : PrincipalAddress
          ? getFormatedDeliveryAddress(PrincipalAddress)
          : undefined,
      });

      toastr.success('Sucesso', 'Cliente atualizado com sucesso');
      if (modal)
        return handleModal(Cpf_Cnpj.replace(/\D/g, ''), ClientId, Company_Name);
      history.push(constants.ROUTES.CUSTOMERS);
    } catch (err) {
      console.log(err);
      return toastr.error(
        'Erro ao atualizar',
        'Algo inesperado aconteceu. Por favor, repita o procedimento'
      );
    } finally {
      setLoading(false);
    }
  }

  function getFormatedPrincipalAddress(address) {
    return {
      ...address,
      Zipcode: address?.Zipcode ? onlyNumbers(address?.Zipcode) : '',
      Address_Number:
        address?.Address_Number === '0' ? 'S/N' : address?.Address_Number,
      Country: 'Brasil',
      Type_Address: 'Principal',
    };
  }

  function getFormatedDeliveryAddress(address) {
    return {
      ...address,
      Zipcode: address?.Zipcode ? onlyNumbers(address?.Zipcode) : '',
      Address_Number:
        address?.Address_Number === '0' ? 'S/N' : address?.Address_Number,
      Complement: address?.Complement ?? '',
      Reference_Point: address?.Reference_Point ?? '',
      Country: 'Brasil',
      Type_Address: 'Entrega',
    };
  }

  function handleNavigateToCustomersList() {
    if (isNewVehicleModalOpen) {
      setIsNewVehicleModalOpen(false);
    }
    if (isVehicleFormModalOpen) {
      setIsVehicleFormModalOpen(false);
    }
    if (isCancelVehicleModalOpen) {
      setIsCancelVehicleModalOpen(false);
    }
    history.push(constants.ROUTES.CUSTOMERS);
  }

  const initialValues = {
    Type: 'Fisica',
    Status: true,
    createdAt: new Date().toLocaleDateString(),
    Company_id: companyId,
    isSameDeliveryAddress: false,
    approachId: null,
  };

  return (
    <>
      <Form
        onSubmit={validations}
        initialValues={initialValues}
        ClientId={ClientId}
        loading={loading}
        hideModal={hideModal}
      />

      <AlertModal
        show={isNewVehicleModalOpen}
        title="Cadastrar Veículo"
        message="Deseja cadastrar veículo?"
        onCancel={handleNavigateToCustomersList}
        onHide={handleNavigateToCustomersList}
        onSubmit={() => {
          setIsNewVehicleModalOpen(false);
          setIsVehicleFormModalOpen(true);
        }}
      />

      {isVehicleFormModalOpen && (
        <FormModalVehicle
          show
          companyId={companyId}
          customerId={Client_id}
          onCancel={() => setIsCancelVehicleModalOpen(true)}
          onSubmit={handleNavigateToCustomersList}
        />
      )}

      <AlertModal
        show={isCancelVehicleModalOpen}
        title="Cadastrar Veículo"
        message="Deseja realmente cancelar o cadastro do veículo?"
        onCancel={() => setIsCancelVehicleModalOpen(false)}
        onHide={() => setIsCancelVehicleModalOpen(false)}
        onSubmit={handleNavigateToCustomersList}
      />
    </>
  );
}

export default withRouter(FormClient);
