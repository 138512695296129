import React, { useEffect } from 'react';
import { useState } from 'react';

import { HeaderExternalWorkshop } from './components/HeaderExternalWorkshop';
import { ExternalWorkshopContent } from './components/ExternalWorkshopContent';
import { FooterExternalWorkshop } from './components/FooterExternalWorkshop';

import maintenanceRepository from '../../../../repositories/Maintenance';
import { decrypt } from 'client/components/ToNormalize/ToNormalize';

export const ExternalWorkshopPanel = ({ history, match, location }) => {
  const [maintenance, setMaintenance] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const [companyId, setCompanyId] = useState(null);
  const [saleId, setSaleId] = useState(null);

  useEffect(() => {
    if (companyId && saleId) {
      loadMaintenance();
      reloadMaintenance();
    }
  }, [companyId, saleId]);

  useEffect(() => {
    readHash();
  }, []);

  const readHash = async () => {
    const url = window.location.pathname;

    const companyIdHash = url.split('/')[2];
    const saleIdHash = url.split('/')[3];

    const decryptedCompanyId = await decrypt(
      companyIdHash,
      '@OS-dig:companyId'
    );
    const decryptedSaleId = await decrypt(saleIdHash, '@OS-dig:saleId');
    setCompanyId(decryptedCompanyId);
    setSaleId(decryptedSaleId);
  };

  const reloadMaintenance = () => {
    setInterval(() => {
      loadMaintenance();
    }, 9000);
  };

  const loadMaintenance = async () => {
    setIsLoading(true);
    try {
      const data = await maintenanceRepository.getMaintenanceBySaleId(
        companyId,
        saleId
      );

      setMaintenance(data);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="external-workshop-panel">
        <HeaderExternalWorkshop />
        <div className="external-workshop-panel__content">
          <ExternalWorkshopContent
            maintenance={maintenance}
            isLoading={isLoading}
          />
        </div>
        <FooterExternalWorkshop />
      </div>
    </>
  );
};
