import React from 'react';
import {
  Document,
  Text,
  Image,
  Font,
  Page,
  StyleSheet,
  View,
} from '@react-pdf/renderer';
import { format } from 'date-fns';

import {
  cepMask,
  cnpjMask,
  percentage,
  cpfMask,
  milhar,
  phoneMask,
  currency,
} from '../../../client/components/ToNormalize/ToNormalize';
import { getDateOnlyFromDate } from 'utils/dateHelpers';

import fontRobotoRegular from '../../../assets/fonts/Roboto-Regular.ttf';
import fontRobotoBold from '../../../assets/fonts/Roboto-Bold.ttf';
import fontRobotoThin from '../../../assets/fonts/Roboto-Thin.ttf';
import fontRobotoItalic from '../../../assets/fonts/Roboto-Italic.ttf';
import { getDefaultText } from 'v2/helpers/sheetHelpers';

const ItemsHeader = ({ children, isProductionOrder, style = {}, ...rest }) => (
  <View
    style={{
      flexDirection: 'row',
      height: 15,
      fontWeight: 'bold',
      marginBottom: 5,
    }}
  >
    <View
      style={{
        width: '10%',
        textAlign: 'center',
        justifyContent: 'center',
        borderRightColor: 'black',
        borderRightWidth: 1,
      }}
    >
      <Text>Código</Text>
    </View>
    <View
      style={{
        width: '40%',
        paddingLeft: 15,
        justifyContent: 'center',
        borderRightColor: 'black',
        borderRightWidth: 1,
      }}
    >
      <Text>Descrição</Text>
    </View>
    <View
      style={{
        width: '10%',
        textAlign: 'center',
        justifyContent: 'center',
        borderRightColor: 'black',
        borderRightWidth: 1,
      }}
    >
      <Text>Qtd</Text>
    </View>
    {!isProductionOrder ? (
      <>
        <View
          style={{
            width: '12%',
            textAlign: 'center',
            justifyContent: 'center',
            borderRightColor: 'black',
            borderRightWidth: 1,
          }}
        >
          <Text>Valor Unit.</Text>
        </View>
        <View
          style={{
            width: '12%',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          <Text>Desc. Unit.</Text>
        </View>
        <View
          style={{
            width: '16%',
            paddingLeft: 15,
            justifyContent: 'center',
            borderLeftColor: 'black',
            borderLeftWidth: 1,
          }}
        >
          <Text>Valor Total</Text>
        </View>
      </>
    ) : (
      <View />
    )}
  </View>
);

const ItemRow = ({
  code,
  description,
  quantity,
  unitPrice,
  discount,
  total,
  isProductionOrder,
  ...rest
}) => (
  <View {...rest} style={{ flexDirection: 'row', height: 13 }}>
    <View
      style={{ width: '10%', textAlign: 'center', justifyContent: 'center' }}
    >
      <Text style={styles.span}>{code}</Text>
    </View>
    <View style={{ width: '40%', paddingLeft: 15, justifyContent: 'center' }}>
      <Text style={styles.span}>{description}</Text>
    </View>
    <View
      style={{ width: '10%', textAlign: 'center', justifyContent: 'center' }}
    >
      <Text style={styles.span}>{quantity}</Text>
    </View>
    {!isProductionOrder ? (
      <>
        <View
          style={{ width: '12%', paddingLeft: 15, justifyContent: 'center' }}
        >
          <Text style={styles.span}>{currency(unitPrice)}</Text>
        </View>
        <View
          style={{ width: '12%', paddingLeft: 15, justifyContent: 'center' }}
        >
          <Text style={styles.span}>{percentage(discount)}</Text>
        </View>
        <View
          style={{ width: '16%', paddingLeft: 15, justifyContent: 'center' }}
        >
          <Text style={styles.span}>{currency(total)}</Text>
        </View>
      </>
    ) : (
      <View />
    )}
  </View>
);

const Card = ({ children, style, ...rest }) => (
  <View style={[styles.cardBorder, style]} {...rest}>
    {children}
  </View>
);

function translateDate(date) {
  const engDate = format(date, 'ccc');

  let portugueseDay;

  switch (engDate) {
    case 'Sun':
      portugueseDay = 'Domingo';
      break;
    case 'Mon':
      portugueseDay = 'Segunda-feira';
      break;
    case 'Tue':
      portugueseDay = 'Terça-feira';
      break;
    case 'Wed':
      portugueseDay = 'Quarta-feira';
      break;
    case 'Thu':
      portugueseDay = 'Quinta-feira';
      break;
    case 'Fri':
      portugueseDay = 'Sexta-feira';
      break;
    case 'Sat':
      portugueseDay = 'Sábado';
      break;
  }

  return portugueseDay;
}

const SalePrintingSheet2 = ({
  sale,
  quickQuote,
  config,
  isProductionOrder,
  showPaymentSuggestions,
  companyBranch,
}) => {
  const company = sale.Company;
  const cpfCnpj =
    company.Cpf_Cnpj.length <= 11
      ? cpfMask(company.Cpf_Cnpj)
      : cnpjMask(company.Cpf_Cnpj);
  const isJuridica = company.Type === 'Jurídica';
  const companyAddress = company.Address[0];
  const companyPhones = company.Phones[0];
  const address = !companyAddress
    ? ''
    : `${companyAddress.Address} ${companyAddress.Address_Number}, ${companyAddress.Neighborhood}`;
  const city = !companyAddress
    ? ''
    : `${companyAddress.City} /${companyAddress.State} - CEP: ${cepMask(
        companyAddress.Zipcode
      )}`;
  const phone = !companyPhones ? '' : phoneMask(companyPhones.Number_Phone1);
  const salesType =
    sale.SalesType.id === 3
      ? 'OS'
      : `${sale.SalesType.Description}`.toUpperCase();

  const products = sale.SalesItems.filter((item) => item.Type === 'Produto');
  const services = sale.SalesItems.filter((item) => item.Type === 'Serviço');

  let scheduling;

  sale.SalesScheduling?.startScheduling
    ? (scheduling = new Date(sale.SalesScheduling?.startScheduling))
    : (scheduling = null);

  const customer = {
    name: sale.Customer.Company_Name,
    phone: !sale.Customer.Phones[0]
      ? ''
      : phoneMask(sale.Customer.Phones[0].Number_Phone2),
    cpfCnpj:
      sale.Customer.Cpf_Cnpj.length <= 11
        ? cpfMask(sale.Customer.Cpf_Cnpj)
        : cnpjMask(sale.Customer.Cpf_Cnpj),
    street: !sale.Customer.Address[0] ? '' : sale.Customer.Address[0].Address,
    addressNumber: !sale.Customer.Address[0]
      ? ''
      : sale.Customer.Address[0].Address_Number,
    neighborhood: !sale.Customer.Address[0]
      ? ''
      : sale.Customer.Address[0].Neighborhood,
    zipCode: !sale.Customer.Address[0]
      ? ''
      : cepMask(sale.Customer.Address[0].Zipcode),
    city: !sale.Customer.Address[0] ? '' : sale.Customer.Address[0].City,
    UF: !sale.Customer.Address[0] ? '' : sale.Customer.Address[0].State,
    complement: !sale.Customer.Address[0]
      ? ''
      : sale.Customer.Address[0].Complement,
    referencePoint: !sale.Customer.Address[0]
      ? ''
      : sale.Customer.Address[0].Reference_Point,
  };

  const vehicle = {
    description: sale.Vehicle?.Model || '',
    licensePlate: sale.Vehicle?.License_Plate || '',
    color: sale.Vehicle?.Color || '',
    manufactureYear: sale.Vehicle?.Year_Manufacture || '',
    modelYear: sale.Vehicle?.Year_Model || '',
    kilometers: milhar(sale.Kilometers) || '',
    Maintenance_Object: sale.Vehicle?.Maintenance_Object || '',

    serialIdentification: sale.Vehicle?.serialIdentification || '',
    vehicleTypeId: sale.Vehicle?.vehicleTypeId || '',
    brand: sale.Vehicle?.Brand || '',
    model: sale.Vehicle?.Model || '',
    brakeType: sale.Vehicle?.BicycleBrakeType?.description || '',
    rearGears: sale.Vehicle?.BicycleRearGears?.quantity || '',
    frontGears: sale.Vehicle?.BicycleFrontGears?.quantity || '',
    frameSize: sale.Vehicle?.BicycleFrameSize?.description || '',
    frameMaterial: sale.Vehicle?.BicycleFrameMaterial?.description || '',
    modality: sale.Vehicle?.BicycleModality?.description || '',
    rearDerailleur: sale.Vehicle?.BicycleRearDerailleurVehicle?.description
      ? sale.Vehicle?.BicycleRearDerailleurVehicle?.description
      : sale.Vehicle?.BicycleRearDerailleur?.description || '',
    rimSize: sale.Vehicle?.BicycleRimSize?.description || '',
    suspensionType: sale.Vehicle?.BicycleSuspensionType?.description || '',
  };

  const payment = {
    paymentCondition: sale.Payments?.CondPayment?.Description || '',
    parcels:
      sale.Payments?.Parcels?.map((parcel) => ({
        id: parcel.id,
        dueDate: format(
          getDateOnlyFromDate(new Date(parcel.Due_Date)),
          'dd/MM/yyyy'
        ),
        paymentForm: parcel.FormOfPayment?.Desciption || '',
        value: currency(parcel.Amount),
      })) || [],
  };

  return (
    <Document
      title={`impresso-${sale.SalesType.Description}-${format(
        new Date(),
        'dd/MM/yyyy'
      )}`}
      subject={`${sale.SalesType.Description} Nº ${sale.Code}`}
    >
      <Page style={styles.page}>
        <View fixed style={{ flexDirection: 'row', marginBottom: 10 }}>
          {company.Url_Logo && (
            <View
              style={{
                width: '16%',
                borderRightWidth: 1,
                borderRightColor: 'black',
                padding: 3,
              }}
            >
              <Image
                src={company.Url_Logo}
                style={{
                  width: 75,
                  height: 72,
                }}
              />
            </View>
          )}
          <View
            fixed
            style={{ width: company.Url_Logo ? '54%' : '60%', paddingLeft: 5 }}
          >
            <Text style={styles.mainTitle}>{company.Trading_Name}</Text>
            <Text>
              {isJuridica ? 'CNPJ' : 'CPF'}: {cpfCnpj}{' '}
              {company.IE && `IE: ${company.IE}`}
            </Text>
            <Text>Endereço: {address}</Text>
            <Text>Cidade: {city}</Text>
            <Text>
              Telefone: {phone} Email: {company.Email}
            </Text>
          </View>
          <View fixed style={{ width: '40%', textAlign: 'right' }}>
            <Text style={styles.mainTitle}>
              {quickQuote
                ? `ORC. RÁPIDO #${sale.QuickQuoteCode}`
                : `${salesType} #${sale.Code}`}
            </Text>
            <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
              <Card
                style={{
                  justifyContent: 'center',
                  textAlign: 'center',
                  height: 30,
                  width: 80,
                }}
              >
                <Text style={styles.p}>Data de Emissão</Text>
                <Text style={[styles.bold, styles.p]}>
                  {format(new Date(sale.Date), 'dd/MM/yyyy')}
                </Text>
              </Card>

              <Card
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  textAlign: 'center',
                  marginLeft: 5,
                  height: 30,
                  width: 40,
                }}
              >
                <Text style={styles.p}>Hora</Text>
                <Text style={[styles.bold, styles.p]}>
                  {format(new Date(sale.Date), 'HH:mm')}
                </Text>
              </Card>
            </View>
          </View>
        </View>

        {!customer.name.trim() && !customer.phone ? null : (
          <>
            <Text style={styles.cardTitle}>DADOS DO CLIENTE</Text>
            <Card>
              <View
                style={[
                  styles.customerRow,
                  !quickQuote ? styles.borderBottom : {},
                ]}
              >
                <View style={{ width: '55%', paddingTop: 1, paddingLeft: 3 }}>
                  <Text style={styles.cardLabel}>Nome/Razão Social</Text>
                  <Text>{customer.name}</Text>
                </View>
                <View style={{ width: '23%' }}>
                  {!customer.phone ? null : (
                    <>
                      <Text style={styles.cardLabel}>Celular</Text>
                      <Text>{customer.phone}</Text>
                    </>
                  )}
                </View>
                <View style={{ width: '22%' }}>
                  {!quickQuote && (
                    <>
                      <Text style={styles.cardLabel}>CPF/CNPJ</Text>
                      <Text>{customer.cpfCnpj}</Text>
                    </>
                  )}
                </View>
              </View>

              {!quickQuote && (
                <>
                  <View style={[styles.customerRow, styles.borderBottom]}>
                    <View style={{ width: '50%' }}>
                      <Text style={styles.cardLabel}>Endereço</Text>
                      <Text>
                        {customer.street}
                        {customer.addressNumber
                          ? `, ${customer.addressNumber}`
                          : ''}
                      </Text>
                    </View>
                    <View style={{ width: '35%' }}>
                      <Text style={styles.cardLabel}>Bairro</Text>
                      <Text>{customer.neighborhood}</Text>
                    </View>
                    <View style={{ width: '15%' }}>
                      <Text style={styles.cardLabel}>CEP</Text>
                      <Text>{customer.zipCode}</Text>
                    </View>
                  </View>

                  <View style={[styles.customerRow, styles.borderBottom]}>
                    <View style={{ width: '40%' }}>
                      <Text style={styles.cardLabel}>Cidade</Text>
                      <Text>{customer.city}</Text>
                    </View>
                    <View style={{ width: '20%' }}>
                      <Text style={styles.cardLabel}>UF</Text>
                      <Text>{customer.UF}</Text>
                    </View>
                    <View style={{ width: '40%' }}>
                      <Text style={styles.cardLabel}>Complemento</Text>
                      <Text>{customer.complement}</Text>
                    </View>
                  </View>

                  <View style={styles.customerRow}>
                    <View style={{ width: '100%' }}>
                      <Text style={styles.cardLabel}>Ponto de referência</Text>
                      <Text>{customer.referencePoint}</Text>
                    </View>
                  </View>
                </>
              )}
            </Card>
          </>
        )}

        {(!customer.name.trim() || customer.name === 'Cliente Consumidor') && (
          <>
            <Text style={styles.cardTitle}>DADOS DO CLIENTE</Text>
            <Card>
              <View style={[styles.customerRow]}>
                <View style={{ width: '100%', paddingTop: 1, paddingLeft: 3 }}>
                  <Text style={styles.cardLabel}>Nome/Razão Social</Text>
                  <Text>{customer.name.trim() || 'Cliente Consumidor'}</Text>
                </View>
              </View>
            </Card>
          </>
        )}

        {!!sale.Vehicle ? (
          (!vehicle.licensePlate ||
            !vehicle.Maintenance_Object ||
            vehicle.serialIdentification) &&
          !vehicle.description ? null : (
            <>
              <Text style={styles.cardTitle}>
                DADOS DO{' '}
                {+companyBranch === 5 ? 'ITEM DE MANUTENÇÃO' : 'VEÍCULO'}
              </Text>
              {vehicle?.vehicleTypeId !== 4 ? (
                <Card>
                  <View
                    style={{
                      flexDirection: 'row',
                      height: 35,
                      paddingTop: 5,
                      paddingLeft: 3,
                    }}
                  >
                    {quickQuote ? (
                      <View>
                        <View style={{ width: '100%', paddingLeft: 3 }}>
                          {vehicle.Maintenance_Object ? (
                            <View>
                              <Text style={styles.cardLabel}>
                                {+companyBranch === 5
                                  ? 'Item de Manutenção'
                                  : 'Veículo'}
                              </Text>
                              <Text>
                                {vehicle.Maintenance_Object} -{' '}
                                {vehicle.description}
                              </Text>
                            </View>
                          ) : vehicle.licensePlate ? (
                            <View>
                              <Text style={styles.cardLabel}>
                                {+companyBranch === 5
                                  ? 'Item de Manutenção'
                                  : 'Veículo'}
                              </Text>
                              <Text>
                                {vehicle.licensePlate} - {vehicle.description}
                              </Text>
                            </View>
                          ) : null}
                        </View>
                      </View>
                    ) : (
                      <>
                        <View style={{ width: '35%' }}>
                          <Text style={styles.cardLabel}>
                            {+companyBranch === 5
                              ? 'Item de Manutenção'
                              : 'Veículo'}
                          </Text>
                          <Text>{vehicle?.description}</Text>
                        </View>

                        <View style={{ width: '15%', paddingLeft: 3 }}>
                          {vehicle?.Maintenance_Object ? (
                            <>
                              <Text style={styles.cardLabel}>Placa</Text>
                              <Text>{vehicle?.Maintenance_Object}</Text>
                            </>
                          ) : (
                            vehicle?.licensePlate && (
                              <>
                                <Text style={styles.cardLabel}>Placa</Text>
                                <Text>{vehicle?.licensePlate}</Text>
                              </>
                            )
                          )}
                        </View>
                      </>
                    )}

                    <View style={{ width: '15%' }}>
                      {!quickQuote && (
                        <>
                          <Text style={styles.cardLabel}>Cor</Text>
                          <Text>{vehicle.color}</Text>
                        </>
                      )}
                    </View>

                    <View style={{ width: '10%' }}>
                      {!quickQuote && (
                        <>
                          <Text style={styles.cardLabel}>Ano Fab.</Text>
                          <Text>{vehicle.manufactureYear}</Text>
                        </>
                      )}
                    </View>
                    <View style={{ width: '10%' }}>
                      {!quickQuote && (
                        <>
                          <Text style={styles.cardLabel}>Ano Mod.</Text>
                          <Text>{vehicle.modelYear}</Text>
                        </>
                      )}
                    </View>

                    <View style={{ width: '15%' }}>
                      {!quickQuote && (
                        <>
                          <Text style={styles.cardLabel}>KM atual.</Text>
                          <Text>{vehicle.kilometers}</Text>
                        </>
                      )}
                    </View>
                  </View>
                </Card>
              ) : (
                <Card>
                  <View
                    style={{
                      flexDirection: 'row',
                      height: 35,
                      paddingTop: 5,
                      paddingLeft: 3,
                      borderBottom: '1px solid #ccc',
                    }}
                  >
                    {quickQuote ? (
                      <View>
                        <View style={{ width: '100%', paddingLeft: 3 }}>
                          <View>
                            <Text style={styles.cardLabel}>
                              {+companyBranch === 5
                                ? 'Item de Manutenção'
                                : 'Veículo'}
                            </Text>
                            <Text>
                              {vehicle.brand} - {vehicle.model}
                            </Text>
                          </View>
                        </View>
                      </View>
                    ) : (
                      <>
                        <View style={{ width: '35%' }}>
                          <Text style={styles.cardLabel}>
                            {+companyBranch === 5
                              ? 'Item de Manutenção'
                              : 'Veículo'}
                          </Text>
                          <Text>
                            {vehicle?.brand} - {vehicle?.model}
                          </Text>
                        </View>

                        <View style={{ width: '15%', paddingLeft: 3 }}>
                          <>
                            <Text style={styles.cardLabel}>
                              Série/Identificação
                            </Text>
                            <Text>{vehicle?.serialIdentification}</Text>
                          </>
                        </View>
                      </>
                    )}

                    <View style={{ width: '15%' }}>
                      {!quickQuote && (
                        <>
                          <Text style={styles.cardLabel}>Cor</Text>
                          <Text>{vehicle?.color}</Text>
                        </>
                      )}
                    </View>

                    <View style={{ width: '10%' }}>
                      {!quickQuote && (
                        <>
                          <Text style={styles.cardLabel}>Ano</Text>
                          <Text>{vehicle?.manufactureYear}</Text>
                        </>
                      )}
                    </View>
                    <View style={{ width: '10%' }}>
                      {!quickQuote && (
                        <>
                          <Text style={styles.cardLabel}>Aro</Text>
                          <Text>{vehicle?.rimSize}</Text>
                        </>
                      )}
                    </View>

                    <View style={{ width: '15%' }}>
                      {!quickQuote && (
                        <>
                          <Text style={styles.cardLabel}>Tamanho Qd.</Text>
                          <Text>{vehicle?.frameSize}</Text>
                        </>
                      )}
                    </View>
                  </View>

                  <View
                    style={{
                      flexDirection: 'row',
                      height: 35,
                      paddingTop: 5,
                      paddingLeft: 3,
                      borderBottom: '1px solid #ccc',
                    }}
                  >
                    <View style={{ width: '25%' }}>
                      <Text style={styles.cardLabel}>Modalidade</Text>
                      <Text>{vehicle?.modality}</Text>
                    </View>

                    <View style={{ width: '20%', paddingLeft: 3 }}>
                      <>
                        <Text style={styles.cardLabel}>Material Quadro</Text>
                        <Text>{vehicle?.frameMaterial}</Text>
                      </>
                    </View>

                    <View style={{ width: '20%' }}>
                      {!quickQuote && (
                        <>
                          <Text style={styles.cardLabel}>Freio</Text>
                          <Text>{vehicle?.brakeType}</Text>
                        </>
                      )}
                    </View>

                    <View style={{ width: '20%' }}>
                      {!quickQuote && (
                        <>
                          <Text style={styles.cardLabel}>Marc. Diant.</Text>
                          <Text>{vehicle?.frontGears}</Text>
                        </>
                      )}
                    </View>

                    <View style={{ width: '20%' }}>
                      {!quickQuote && (
                        <>
                          <Text style={styles.cardLabel}>Marc. Traseira.</Text>
                          <Text>{vehicle?.rearGears}</Text>
                        </>
                      )}
                    </View>
                  </View>

                  <View
                    style={{
                      flexDirection: 'row',
                      height: 35,
                      paddingTop: 5,
                      paddingLeft: 3,
                    }}
                  >
                    <View style={{ width: '50%' }}>
                      {!quickQuote && (
                        <>
                          <Text style={styles.cardLabel}>Suspensão</Text>
                          <Text>{vehicle?.suspensionType}</Text>
                        </>
                      )}
                    </View>
                    <View style={{ width: '50%' }}>
                      {!quickQuote && (
                        <>
                          <Text style={styles.cardLabel}>Câmbio Traseiro</Text>
                          <Text>{vehicle?.rearDerailleur}</Text>
                        </>
                      )}
                    </View>
                  </View>
                </Card>
              )}
            </>
          )
        ) : (
          <View />
        )}

        {(sale.SalesType.Description === 'Orçamento' ||
          sale.SalesType.Description === 'Ordem de Serviço') &&
          sale?.SalesEmployees?.length > 0 && (
            <>
              <Card style={{ marginTop: 7 }}>
                <View
                  style={{
                    flexDirection: 'row',
                    paddingTop: 2,
                    paddingBottom: 2,
                    paddingLeft: 3,
                  }}
                >
                  <View style={{ width: '100%', paddingLeft: '3px' }}>
                    <Text>
                      <Text style={styles.bold}>
                        {+companyBranch === 5
                          ? 'Técnicos Responsáveis:'
                          : 'Mecânicos Responsáveis:'}
                      </Text>{' '}
                      {sale.SalesEmployees.map(
                        (item) => item.Employee.name
                      ).join(', ')}
                    </Text>
                  </View>
                </View>
              </Card>
            </>
          )}

        {products.length ? (
          <>
            <Text style={styles.cardTitle}>DADOS DO PRODUTO</Text>
            <Card>
              <ItemsHeader isProductionOrder={isProductionOrder} />
            </Card>

            {products.map((item) => (
              <ItemRow
                key={item.id}
                code={item.Products.Code}
                description={item.Description}
                quantity={item.Quantity}
                unitPrice={item.Unit_Value}
                discount={item.Discount_Value}
                total={item.Amount}
                isProductionOrder={isProductionOrder}
              />
            ))}
          </>
        ) : (
          <View />
        )}

        {services.length ? (
          <>
            <Text style={styles.cardTitle}>DADOS DO SERVIÇO</Text>
            <Card>
              <ItemsHeader isProductionOrder={isProductionOrder} />
            </Card>

            {services.map((item) => (
              <ItemRow
                key={item.id}
                code={item.Services.Code}
                description={item.Description}
                quantity={item.Quantity}
                unitPrice={item.Unit_Value}
                discount={item.Discount_Value}
                total={item.Amount}
                isProductionOrder={isProductionOrder}
              />
            ))}
          </>
        ) : (
          <View />
        )}

        {sale.freightValue > 0 && (
          <View style={{ paddingLeft: '10px', paddingRight: '10px' }}>
            <View style={styles.line} />

            <View
              style={[
                {
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                },
                styles.span,
              ]}
            >
              <Text style={styles.bold}>Frete: &nbsp;</Text>
              <Text>{currency(sale.freightValue)}</Text>
            </View>
          </View>
        )}

        {!isProductionOrder ? (
          <>
            <Card style={{ marginTop: 10 }}>
              <View
                style={{
                  flexDirection: 'row',
                  height: 30,
                  alignItems: 'center',
                  paddingLeft: 5,
                }}
              >
                <View style={{ width: '33%' }}>
                  <Text>
                    <Text style={styles.bold}>Total de Serviços:</Text>{' '}
                    {currency(sale.Service_Value)}
                  </Text>
                  <Text>
                    <Text style={styles.bold}>Descontos:</Text>{' '}
                    {currency(sale.Discount_Services)} (
                    {percentage(sale.Discount_Services_Percentage)})
                  </Text>
                </View>
                <View style={{ width: '33%' }}>
                  <Text>
                    <Text style={styles.bold}>Total de Produtos:</Text>{' '}
                    {currency(sale.Product_Value)}
                  </Text>
                  <Text>
                    <Text style={styles.bold}>Descontos:</Text>{' '}
                    {currency(sale.Discount_Products)} (
                    {percentage(sale.Discount_Products_Percentage)})
                  </Text>
                </View>
                <View style={{ width: '33%' }}>
                  <Text>
                    <Text style={styles.bold}>Total:</Text>{' '}
                    {currency(sale.Final_Value)}
                  </Text>
                  <Text>
                    <Text style={styles.bold}>Descontos:</Text>{' '}
                    {currency(sale.Discount_Value)} (
                    {percentage(sale.Discount_Value_Percentage)})
                  </Text>
                </View>
              </View>
            </Card>

            <View
              style={{
                borderBottomWidth: 1,
                borderBottomColor: 'black',
                marginTop: 10,
                marginBottom: quickQuote ? 0 : 10,
              }}
            />
          </>
        ) : (
          <View />
        )}

        {sale.Payments && !isProductionOrder ? (
          <Card style={{ padding: 5 }}>
            <Text style={{ fontSize: 12, fontWeight: 'bold' }}>
              {payment.paymentCondition}
            </Text>
            <View style={{ flexDirection: 'row', marginTop: 5 }}>
              <View style={{ width: '20%' }}>
                <Text style={[styles.span, styles.bold]}>Vencimento</Text>
              </View>
              <View style={{ width: '25%' }}>
                <Text style={[styles.span, styles.bold]}>
                  Forma de Pagamento
                </Text>
              </View>
              <View style={{ width: '55%' }}>
                <Text style={[styles.span, styles.bold]}>Valor</Text>
              </View>
            </View>
            {payment.parcels.map((parcel) => (
              <View key={parcel.id} style={{ flexDirection: 'row' }}>
                <View style={{ width: '20%' }}>
                  <Text style={styles.span}>{parcel.dueDate}</Text>
                </View>
                <View style={{ width: '25%' }}>
                  <Text style={styles.span}>{parcel.paymentForm}</Text>
                </View>
                <View style={{ width: '55%' }}>
                  <Text style={styles.span}>{parcel.value}</Text>
                </View>
              </View>
            ))}
          </Card>
        ) : (
          <View />
        )}

        {!sale?.Payments &&
        sale?.paymentSuggestions?.length &&
        showPaymentSuggestions ? (
          <Card style={{ padding: 5, marginTop: 7 }}>
            <Text style={{ fontSize: 12, fontWeight: 'bold' }}>
              {'Sugestão de Pagamento'}
            </Text>
            {sale.paymentSuggestions.map((paymentSuggestion) => (
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                <View style={{ width: '100%' }}>
                  <Text style={{ fontSize: 9 }}>{paymentSuggestion}</Text>
                </View>
              </View>
            ))}
          </Card>
        ) : (
          <View />
        )}

        {(sale.Comments || sale.Defects || sale.Malfunctions || !!scheduling) &&
        sale.SalesType.Description !== 'PDV' ? (
          <Card style={{ marginTop: 10, flexDirection: 'row', minHeight: 80 }}>
            <View
              style={{
                width: '33%',
                padding: 5,
                borderRightWidth: 1,
                borderRightColor: 'black',
              }}
            >
              <Text style={[styles.span, styles.bold]}>Observações</Text>
              <Text style={styles.span}>{sale.Comments}</Text>
              {scheduling !== null ? (
                <Text style={styles.span}>{`Agendamento efetuado para ${
                  salesType === 'Orçamento' ? 'esse orçamento' : 'essa venda'
                } para o dia ${format(
                  scheduling,
                  'dd/MM/yyyy'
                )} (${translateDate(scheduling)}) as ${format(
                  scheduling,
                  'HH:mm'
                )}`}</Text>
              ) : null}
            </View>

            <View
              style={{
                width: '33%',
                padding: 5,
                borderRightWidth: 1,
                borderRightColor: 'black',
              }}
            >
              <Text style={[styles.span, styles.bold]}>Defeitos</Text>
              <Text style={styles.span}>{sale.Defects}</Text>
            </View>

            <View style={{ width: '33%', padding: 5 }}>
              <Text style={[styles.span, styles.bold]}>Avarias</Text>
              <Text style={styles.span}>{sale.Malfunctions}</Text>
            </View>
          </Card>
        ) : (
          <View />
        )}

        {!!sale.Comments && sale.SalesType.Description === 'PDV' && (
          <Card style={{ marginTop: 10, flexDirection: 'row', minHeight: 50 }}>
            <View
              style={{
                width: '100%',
                padding: 5,
                borderRightWidth: 1,
                borderRightColor: 'black',
              }}
            >
              <Text style={[styles.span, styles.bold]}>Observações</Text>
              <Text style={styles.span}>{sale.Comments}</Text>
            </View>
          </Card>
        )}

        {(!!sale.Company.defaultSalesOrderFooterText ||
          !!sale.Company.defaultOrcamentFooterText) && (
          <View style={{ marginTop: 8 }}>
            <Card style={{ padding: 5 }}>
              <Text style={{ fontSize: 8 }}>
                {getDefaultText(
                  !!quickQuote
                    ? 'Orçamento Rápido'
                    : sale.SalesType.Description,
                  sale
                )}
              </Text>
            </Card>
          </View>
        )}

        {sale.SalesType.Description === 'Ordem de Serviço' &&
        config.showSheetSignatureField === 1 &&
        !isProductionOrder ? (
          <Card
            style={{
              padding: 5,
              marginTop: 10,
            }}
          >
            <View style={{ flexDirection: 'column' }}>
              <View>
                <Text style={{ fontSize: 10, fontWeight: 'bold' }}>
                  Autorização do Cliente
                </Text>
              </View>
              <View style={{ marginTop: 8, width: '90%' }}>
                <Text style={{ fontSize: 10 }}>
                  Declaro, através dessa Ordem de Serviço, nº{sale.Code}, que
                  autorizo a execução do serviços apresentados no{' '}
                  {+companyBranch === 5 ? 'item de manutenção' : 'veículo'} em
                  questão.
                </Text>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                  marginTop: 8,
                }}
              >
                <Text style={{ fontSize: 10, fontStyle: 'italic' }}>
                  {companyAddress.City}, ____ /____ /____ Ass. do
                  cliente:__________________________________________________________________
                </Text>
              </View>
            </View>
          </Card>
        ) : (
          <View />
        )}
      </Page>
    </Document>
  );
};

Font.register({
  family: 'Roboto',
  fonts: [
    { src: fontRobotoRegular },
    { src: fontRobotoThin, fontWeight: 'thin' },
    { src: fontRobotoBold, fontWeight: 'bold' },
    { src: fontRobotoItalic, fontStyle: 'italic' },
  ],
});

const styles = StyleSheet.create({
  mainTitle: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  span: {
    fontSize: 9,
  },
  text: {
    fontSize: 7,
  },
  p: {
    fontSize: 8,
  },
  bold: {
    fontWeight: 'bold',
  },
  customerRow: {
    flexDirection: 'row',
    height: 30,
    paddingTop: 1,
    paddingLeft: 3,
  },
  borderBottom: {
    borderBottomWidth: 1,
    borderBottomColor: 'black',
  },
  cardTitle: {
    marginTop: 10,
    marginLeft: 5,
    fontSize: 10,
    fontWeight: 'thin',
  },
  cardLabel: {
    fontSize: 8,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  cardBorder: {
    borderWidth: 1,
    borderRadius: 10,
    borderColor: '#444444',
  },
  page: {
    padding: 20,
    fontSize: 10,
    fontFamily: 'Roboto',
  },
  line: {
    borderBottom: '1px solid black',
    marginTop: '5px',
    marginBottom: '5px',
  },
});
export default SalePrintingSheet2;
