import {
  faChartPie,
  faEdit,
  faFolder,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { phoneMask } from 'client/components/ToNormalize/ToNormalize';
import { cpfMask } from 'client/components/ToNormalize/ToNormalize';
import { format } from 'date-fns';
import React from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table';
import { getDateOnlyFromDate } from 'utils/dateHelpers';
import DownloadXlsButton from '../../../../components/DownloadXlsButton';
import CustomLabel from 'components/CustomLabel';
import { BrandingLabel } from 'v2/components/BrandingLabel';

const PollsTable = ({ loading, polls, PollsStatusSwitch }) => {
  return (
    <div>
      <ReactTable
        style={{
          fontWeight: 'bold',
          textAlign: 'center',
        }}
        data={polls}
        columns={[
          {
            Header: 'Produto',
            accessor: 'brandingId',
            width: 100,
            Cell: (props) => (
              <BrandingLabel
                height={'22px'}
                brandingId={props.value}
                noBrandingText="Ambos"
              />
            ),
          },
          {
            Header: 'Pesquisa',
            accessor: 'pollsTypes.type',
            width: 100,
            Cell: (props) => (
              <CustomLabel
                text={props.value}
                backgroundColor={
                  props.value === 'NPS'
                    ? '#428bca'
                    : props.value === 'NVS'
                    ? '#5cb85c'
                    : '#5bc0de'
                }
              />
            ),
          },
          {
            Header: 'Data Inicial',
            accessor: 'initialDate',
            width: 130,
            Cell: (props) =>
              format(getDateOnlyFromDate(new Date(props.value)), 'dd/MM/yyyy'),
          },
          {
            Header: 'Data Final',
            accessor: 'finalDate',
            width: 130,
            Cell: (props) =>
              format(getDateOnlyFromDate(new Date(props.value)), 'dd/MM/yyyy '),
          },
          {
            Header: 'Descrição',
            accessor: 'description',
            width: 380,
          },
          {
            Header: 'Avaliações',
            accessor: 'pollsAnswer',
            width: 130,
            Cell: (props) => {
              return props.value.length;
            },
          },
          {
            Header: 'Status',
            accessor: 'status',
            Cell: (props) => {
              return (
                <PollsStatusSwitch
                  status={props.value}
                  pollsId={props.original.id}
                  brandingId={props.original.brandingId}
                />
              );
            },
            width: 150,
          },
          {
            Header: 'Ações',
            accessor: 'id',
            headerClassName: 'text-left',
            filterable: false,
            className: 'texto',
            width: 90,
            Cell: (props) => (
              <div className="table-actions" style={{ alignItems: 'unset' }}>
                <Link to={{ pathname: `polls-update#${props.value}` }}>
                  <FontAwesomeIcon
                    title="Editar Pesquisa"
                    cursor="pointer"
                    icon={faEdit}
                    style={{ width: '1.5em', height: '1.5em' }}
                  />
                </Link>
                <DownloadXlsButton
                  style={{ display: 'contents' }}
                  archiveName={`Pesquisas - ${props.row.description} ${format(
                    new Date(),
                    'dd-MM-yyyy'
                  )} às ${format(new Date(), 'hh:mm').replace(':', 'h')}min`}
                  data={props.original.pollsAnswer}
                  columns={[
                    {
                      name: 'Nome',
                      acessor: 'userName',
                    },
                    {
                      name: 'Email',
                      acessor: 'userEmail',
                    },
                    {
                      name: 'CPF/CNPJ',
                      acessor: 'companyCpfCnpj',
                      Cell: (props) => cpfMask(props.value),
                    },
                    {
                      name: 'Nome/Razão Social',
                      acessor: 'companyName' || 'tradingName',
                    },
                    {
                      name: 'Plano',
                      acessor: 'companyPlan',
                    },
                    {
                      name: 'Telefone',
                      acessor: 'userPhone',
                      Cell: (props) => phoneMask(props.value),
                    },
                    {
                      name: 'Nota',
                      acessor: 'answer',
                    },
                    {
                      name: 'Observações',
                      acessor: 'notes',
                    },
                    {
                      name: 'CustomerX Id',
                      acessor: 'customerXId',
                    },
                  ]}
                >
                  <FontAwesomeIcon
                    icon={faChartPie}
                    cursor="pointer"
                    title="Exportar Relatório"
                  />
                </DownloadXlsButton>
              </div>
            ),
          },
        ]}
        defaultPageSize={10}
        loading={loading}
        showPagination
        sortable
        showPaginationTop={false}
        showPaginationBottom
        pageSizeOptions={[5, 10, 20, 25, 50, 100]}
        previousText="Anterior"
        nextText="Próximo"
        loadingText="Carregando..."
        noDataText="Nenhuma pesquisa encontrada"
        pageText="Página"
        ofText="de"
        rowsText="linhas"
      />
    </div>
  );
};

export default PollsTable;
