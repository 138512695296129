import {
  faClipboardList,
  faUserFriends,
  faCalculator,
  faTools,
  faBoxOpen,
  faFileInvoiceDollar,
  faChartPie,
  faHandshake,
  faReceipt,
  faMoneyBillAlt,
  faHome,
} from '@fortawesome/free-solid-svg-icons';

import constants from '../../../utils/constants';
import { CLIENT_PATH_ROUTES } from 'v2/constants/routes';

export const sidebarItems = [
  {
    title: 'Início',
    active: 'home',
    path: constants.ROUTES.HOME,
    icon: faHome,
  },
  {
    title: 'Vendas',
    active: 'sales',
    icon: faFileInvoiceDollar,
    groupFeatureId: 1,
    subMenu: {
      title: 'Vendas',
      items: [
        {
          subFeatureId: 1,
          title: 'Vendas/Ordem de Serviço',
          path: constants.ROUTES.SALES,
        },
        {
          subFeatureId: 2,
          title: 'PDV',
          path: constants.ROUTES.PDV_ONLINE,
          plansNotAllowed: ['Free', 'Start'],
          targetBlank: true,
        },
        {
          subFeatureId: 3,
          title: 'Caixa',
          path: constants.ROUTES.CASHIER_BANK_HISTORIC,
          plansNotAllowed: ['Free', 'Start'],
          needCashierControl: true,
        },
        {
          subFeatureId: 4,
          title: 'Agenda',
          path: constants.ROUTES.SCHEDULES,
          plansNotAllowed: ['Free', 'Start'],
        },
        {
          subFeatureId: 5,
          title: 'Painel da Oficina',
          path: constants.ROUTES.WORKSHOP_PANEL,
          needWorkshopPanel: true,
          targetBlank: true,
        },
      ],
    },
  },
  {
    title: 'Cadastros',
    active: 'cadastros',
    icon: faClipboardList,
    groupFeatureId: 2,
    subMenu: {
      title: 'Cadastros',
      items: [
        {
          title: 'Produtos/Serviços',
          mainFeatureId: 2,
          subMenuDegree: [
            {
              title: 'Famílias',
              subFeatureId: 6,
              path: constants.ROUTES.FAMILIES,
              plansNotAllowed: ['Free'],
            },
            {
              title: 'Garantias',
              subFeatureId: 7,
              path: constants.ROUTES.WARRANTIES,
            },
            {
              title: 'Marcas',
              subFeatureId: 8,
              path: constants.ROUTES.BRANDS,
            },
            {
              title: 'Revisões',
              subFeatureId: 9,
              path: constants.ROUTES.REVISIONS,
            },
            {
              title: 'Seção',
              subFeatureId: 10,
              path: 'section',
              plansNotAllowed: ['Free', 'Start', 'Essencial'],
            },
            {
              title: 'Departamento',
              subFeatureId: 11,
              path: 'department',
              plansNotAllowed: ['Free', 'Start', 'Essencial'],
            },
            {
              title: 'Conversor de CST para CSOSN',
              subFeatureId: 114,
              path: 'cst-conversor',
              plansNotAllowed: ['Free', 'Start'],
            },
          ],
        },
        {
          title: 'Fiscal',
          mainFeatureId: 3,
          plansNotAllowed: ['Free', 'Start'],
          needFiscalModule: true,
          subMenuDegree: [
            {
              title: 'CFOP',
              subFeatureId: 12,
              path: constants.ROUTES.CFOPS,
              needFiscalModule: true,
              plansNotAllowed: ['Free', 'Start'],
            },
            {
              title: 'NCM/NBS',
              subFeatureId: 13,
              path: constants.ROUTES.NCM_NBS,
              needFiscalModule: true,
              plansNotAllowed: ['Free', 'Start'],
            },
            {
              title: 'Texto para Notas Fiscais',
              subFeatureId: 14,
              path: constants.ROUTES.TEXTS_FOR_INVOICES,
              needFiscalModule: true,
              plansNotAllowed: ['Free', 'Start'],
            },
            {
              title: 'Transportadora',
              subFeatureId: 15,
              path: constants.ROUTES.SHIPPING_COMPANIES,
              needFiscalModule: true,
              plansNotAllowed: ['Free', 'Start'],
            },
            {
              title: 'Tributo por Estado',
              subFeatureId: 16,
              path: constants.ROUTES.TAXES_BY_STATE,
              needFiscalModule: true,
              plansNotAllowed: ['Free', 'Start'],
            },
            {
              title: 'CNAE',
              subFeatureId: 17,
              path: constants.ROUTES.CNAES,
              needFiscalModule: true,
              plansNotAllowed: ['Free', 'Start'],
            },
            {
              title: 'Intermediador',
              subFeatureId: 18,
              path: constants.ROUTES.INTERMEDIARIES,
              needFiscalModule: true,
              plansNotAllowed: ['Free', 'Start'],
            },
          ],
        },
        {
          title: 'Vendas',
          mainFeatureId: 4,
          subMenuDegree: [
            {
              title: 'Condições de Pagamento',
              subFeatureId: 19,
              path: constants.ROUTES.PAYMENT_CONDITIONS,
            },
            {
              title: 'Formas de Pagamento',
              subFeatureId: 20,
              path: constants.ROUTES.PAYMENT_FORMS,
            },
            {
              title: 'Box/Prisma',
              subFeatureId: 21,
              path: constants.ROUTES.BOX_PRISMA,
              plansNotAllowed: ['Free', 'Start'],
            },
            {
              title: 'Tabela de Preços Diferenciados',
              subFeatureId: 22,
              path: constants.ROUTES.DISCOUNT_GROUPS,
              plansNotAllowed: ['Free', 'Start', 'Essencial'],
            },
            {
              title: 'Tabela de Preços Promocionais',
              subFeatureId: 23,
              path: constants.ROUTES.PROMOTIONAL_PRICE_TABLE,
              plansNotAllowed: ['Free', 'Start', 'Essencial', 'Prime'],
            },
            {
              title: 'Motivos de Perda',
              subFeatureId: 24,
              path: constants.ROUTES.LOSS_REASONS,
              plansNotAllowed: ['Free', 'Start'],
            },
            {
              title: 'Meus Representantes',
              subFeatureId: 25,
              path: constants.ROUTES.TRADE_REPRESENTATIVES,
              plansNotAllowed: ['Free', 'Start'],
            },

            {
              title: 'Abordagem de Venda',
              subFeatureId: 26,
              path: 'approach',
              plansNotAllowed: ['Free', 'Start', 'Essencial', 'Prime'],
            },
            {
              title: 'Subtipo de Venda',
              subFeatureId: 27,
              path: 'sale-subtype',
              plansNotAllowed: ['Free', 'Start'],
            },
          ],
        },
        {
          title: 'Financeiro',
          mainFeatureId: 5,
          plansNotAllowed: ['Free', 'Start'],
          subMenuDegree: [
            {
              title: 'Caixa/Bancos',
              subFeatureId: 28,
              path: constants.ROUTES.CASHIER_BANKS,
              plansNotAllowed: ['Free', 'Start'],
            },
            {
              title: 'Plano de Contas',
              subFeatureId: 29,
              path: constants.ROUTES.ACCOUNT_PLANS,
              plansNotAllowed: ['Free', 'Start', 'Essencial'],
            },
            {
              title: 'Centro de Custo',
              subFeatureId: 30,
              path: constants.ROUTES.COST_CENTERS,
              plansNotAllowed: ['Free', 'Start', 'Essencial'],
            },
          ],
        },
        {
          title: 'Checklist Personalizado',
          mainFeatureId: 23,
          plansNotAllowed: ['Free', 'Start', 'Essencial'],
          subMenuDegree: [
            {
              title: 'Checklist',
              subFeatureId: 119,
              path: 'checklist',
              plansNotAllowed: ['Free', 'Start', 'Essencial'],
            },
            {
              title: 'Defeitos',
              subFeatureId: 120,
              path: 'defects',
              plansNotAllowed: ['Free', 'Start', 'Essencial'],
            },
            {
              title: 'Correções',
              subFeatureId: 121,
              path: 'corrections',
              plansNotAllowed: ['Free', 'Start', 'Essencial'],
            },
            {
              title: 'Grupos de Itens',
              subFeatureId: 122,
              path: 'group-items',
              plansNotAllowed: ['Free', 'Start', 'Essencial'],
            },
          ],
        },
      ],
    },
  },
  {
    title: 'Clientes',
    groupFeatureId: 5,
    active: 'customers',
    icon: faUserFriends,
    subMenu: {
      title: 'Clientes',
      items: [
        {
          title: 'Clientes',
          subFeatureId: 31,
          path: constants.ROUTES.CUSTOMERS,
        },
        {
          title: 'Crédito para Cliente',
          subFeatureId: 32,
          path: constants.ROUTES.CUSTOMER_CREDIT,
          needInternalCreditManagement: true,
          plansNotAllowed: ['Free', 'Start', 'Essencial', 'Prime'],
        },
      ],
    },
  },
  {
    title: 'Fornecedores',
    groupFeatureId: 4,
    active: 'providers',
    path: constants.ROUTES.PROVIDERS,
    icon: faHandshake,
    group: 'Fornecedores',
    plansNotAllowed: ['Free'],
  },
  {
    title: 'Orçamento Rápido',
    groupFeatureId: 5,
    active: 'quick-quotes',
    path: constants.ROUTES.QUICK_QUOTES,
    icon: faCalculator,
    group: 'Orçamento Rápido',
  },
  {
    title: 'Produtos',
    groupFeatureId: 6,
    active: 'products',
    icon: faBoxOpen,
    group: 'Produtos',
    subMenu: {
      title: 'Produtos',
      mainFeatureId: 9,
      items: [
        {
          title: 'Cadastro de Produto',
          subFeatureId: 35,
          path: constants.ROUTES.PRODUCTS,
        },
        {
          title: 'Entrada de Compras',
          subFeatureId: 36,
          path: constants.ROUTES.PURCHASES,
          plansNotAllowed: ['Free', 'Start'],
        },
        {
          title: 'Cotação de Peças Online',
          subFeatureId: 37,
          path: constants.ROUTES.QUOTATIONS,
          plansNotAllowed: ['Free', 'Start', 'Essencial'],
        },
        {
          title: 'Movimentação de Estoque',
          subFeatureId: 38,
          path: constants.ROUTES.STOCKS,
          plansNotAllowed: ['Free', 'Start'],
        },
        {
          title: 'Alteração de Preço em Lote',
          subFeatureId: 39,
          path: constants.ROUTES.BATCH_PRICE_CHANGES,
          plansNotAllowed: ['Free', 'Start'],
        },
        {
          title: 'Consulta de Peças Online',
          subFeatureId: 40,
          segmentsAllowed: [1, 2, 3, 7],
          plansNotAllowed: ['Free', 'Start'],
          path: constants.ROUTES.ONLINE_PARTS_CONSULTATION,
        },
        {
          title: 'Inventário/Contagem',
          subFeatureId: 41,
          path: constants.ROUTES.INVENTORY_COUNT,
          plansNotAllowed: ['Free'],
        },
        {
          title: 'Etiquetas',
          subFeatureId: 42,
          path: constants.ROUTES.HANG_TANGS,
          plansNotAllowed: ['Free', 'Start'],
        },
        {
          title: 'Requisição de Peças',
          subFeatureId: 43,
          path: constants.ROUTES.PARTS_REQUISITION,
          needPartsRequisition: true,
          plansNotAllowed: ['Free', 'Start', 'Essencial', 'Prime'],
        },
      ],
    },
  },
  {
    title: 'Serviços',
    groupFeatureId: 7,
    path: constants.ROUTES.SERVICES,
    active: 'services',
    icon: faTools,
    group: 'Serviços',
  },
  {
    title: 'Relatórios',
    groupFeatureId: 8,
    active: 'relatorios',
    icon: faChartPie,
    subMenu: {
      title: 'Relatórios',
      items: [
        {
          title: 'Clientes/Funcionários',
          mainFeatureId: 11,
          needPlanNotToBeFree: true,
          subMenuDegree: [
            {
              title: 'Aniversariantes',
              subFeatureId: 45,
              path: constants.ROUTES.BIRTHDAYS,
              plansNotAllowed: ['Free', 'Start'],
            },
            {
              title: 'Histórico de Veículo',
              subFeatureId: 46,
              path: constants.ROUTES.VEHICLE_HISTORY,
              plansNotAllowed: ['Free'],
            },
            {
              title: 'Listagem de Clientes',
              subFeatureId: 47,
              path: constants.ROUTES.CUSTOMER_LIST,
              plansNotAllowed: ['Free'],
            },
            {
              title: 'Listagem de Funcionários',
              subFeatureId: 48,
              path: constants.ROUTES.LIST_OF_EMPLOYEES,
              plansNotAllowed: ['Free', 'Start'],
            },
            {
              title: 'Abordagem de Clientes',
              subFeatureId: 49,
              path: constants.ROUTES.CUSTOMER_APPROACH_REPORT,
              plansNotAllowed: ['Free', 'Start'],
            },
          ],
        },
        {
          title: 'Produtos',
          mainFeatureId: 12,
          plansNotAllowed: ['Free', 'Start'],
          subMenuDegree: [
            {
              title: 'Listagem de Estoque Atual',
              subFeatureId: 50,
              path: constants.ROUTES.ACTUAL_STOCK_REPORT,
              plansNotAllowed: ['Free', 'Start'],
            },
            {
              title: 'Listagem de Produtos Sem Marca e Família',
              subFeatureId: 51,
              path: constants.ROUTES.BRAND_FAMILY_REPORT,
              plansNotAllowed: ['Free', 'Start'],
            },
            {
              title: 'Estoque Mínimo e Máximo',
              subFeatureId: 52,
              path: constants.ROUTES.MIN_MAX_STOCK_REPORT,
              plansNotAllowed: ['Free', 'Start'],
            },
            {
              title: 'Histórico do Produto',
              subFeatureId: 53,
              path: constants.ROUTES.PRODUCT_HISTORY_REPORT,
              plansNotAllowed: ['Free', 'Start'],
            },
            {
              title: 'Produtos sem Movimento',
              subFeatureId: 54,
              path: constants.ROUTES.PRODUCTS_WITHOUT_MOVEMENT,
              plansNotAllowed: ['Free', 'Start'],
            },
            {
              title: 'Inventário',
              subFeatureId: 55,
              path: constants.ROUTES.PRODUCTS_INVENTORY,
              plansNotAllowed: ['Free', 'Start'],
            },
            {
              title: 'Listagem de Produtos por Famílias/Marcas',
              subFeatureId: 56,
              path: constants.ROUTES.PRODUCTS_BY_FAMILIES_OR_BRANDS,
              plansNotAllowed: ['Free', 'Start'],
            },
            {
              title: 'Listagem de Movimentações de Estoque',
              subFeatureId: 57,
              path: constants.ROUTES.INVENTORY_MOVEMENT_LISTING,
              plansNotAllowed: ['Free', 'Start'],
            },
            {
              title: 'Listagem de Produtos por Localização',
              subFeatureId: 58,
              path: constants.ROUTES.PRODUCT_LISTING_LOCATION,
              plansNotAllowed: ['Free', 'Start'],
            },
            {
              title: 'Relatório Peças por Aplicação',
              subFeatureId: 59,
              path: constants.ROUTES.PARTS_PER_APPLICATION,
              plansNotAllowed: ['Free', 'Start'],
            },
            {
              title: 'Estoque por Marca/Família',
              subFeatureId: 60,
              path: constants.ROUTES.FAMILY_BRAND_STOCK_REPORT,
              plansNotAllowed: ['Free', 'Start'],
            },
          ],
        },
        {
          title: 'Vendas',
          mainFeatureId: 13,
          subMenuDegree: [
            {
              title: 'Vendas Por Família',
              subFeatureId: 61,
              path: constants.ROUTES.SALES_BY_FAMILY_REPORT,
              plansNotAllowed: ['Free', 'Start'],
            },
            {
              title: 'Vendas por Família(Produto)',
              subFeatureId: 62,
              path: constants.ROUTES.SALES_PER_FAMILY_PRODUCT,
              plansNotAllowed: ['Free', 'Start'],
            },
            {
              title: 'Vendas por Família(Serviço)',
              subFeatureId: 63,
              path: constants.ROUTES.SALES_PER_FAMILY_SERVICE,
              plansNotAllowed: ['Free', 'Start'],
            },
            {
              title: 'Vendas Por Forma de Pagamento',
              subFeatureId: 64,
              path: constants.ROUTES.SALES_BY_PAYMENT_FORM_REPORT,
            },
            {
              title: 'Vendas Por Produto/Serviço',
              subFeatureId: 65,
              path: constants.ROUTES.SALES_BY_PRODUCT_SERVICE_REPORT,
            },
            {
              title: 'Vendas Por Tipo/Status',
              subFeatureId: 66,
              path: constants.ROUTES.SALES_BY_TYPE_REPORT,
            },
            {
              title: 'Vendas Por Dia',
              subFeatureId: 67,
              path: constants.ROUTES.SALES_PER_DAY_REPORT,
              plansNotAllowed: ['Free'],
            },
            {
              title: 'Comissões/Rendimentos do Mecânico',
              subFeatureId: 68,
              path: constants.ROUTES.SALES_COMMISSIONS_REPORT,
              plansNotAllowed: ['Free', 'Start'],
            },
            {
              title: 'Histórico de Devolução',
              subFeatureId: 69,
              path: constants.ROUTES.RETURN_HISTORY,
              plansNotAllowed: ['Free', 'Start', 'Essencial'],
            },
            {
              title: 'Previsão de Retorno',
              subFeatureId: 70,
              feature: constants.FEATURES.COMISSAO,
              path: constants.ROUTES.RETURN_FORECAST,
              plansNotAllowed: ['Free', 'Start', 'Essencial'],
            },
            {
              title: 'Ranking dos Produtos/Serviços Vendidos',
              subFeatureId: 71,
              path: constants.ROUTES.RANKING_SALES_REPORT,
              plansNotAllowed: ['Free'],
            },
            {
              title: 'Faturamento por Período',
              subFeatureId: 72,
              path: constants.ROUTES.REVENUE_PER_PERIOD_REPORT,
              plansNotAllowed: ['Free', 'Start'],
            },
            {
              title: 'Listagem de Orçamentos Cancelados',
              subFeatureId: 73,
              path: constants.ROUTES.SALES_CANCELED_BUDGETS_REPORT,
              plansNotAllowed: ['Free', 'Start'],
            },
            {
              title: 'Curva ABC de Produtos',
              subFeatureId: 74,
              path: constants.ROUTES.ABC_CURVE,
              plansNotAllowed: ['Free', 'Start', 'Essencial'],
            },
            {
              title: 'Listagem de Devolução',
              subFeatureId: 75,
              path: constants.ROUTES.REPORT_RETURN_LIST,
              plansNotAllowed: ['Free', 'Start', 'Essencial'],
            },
            {
              title: 'Revisões/Garantias de Produtos e Serviços',
              subFeatureId: 76,
              path: constants.ROUTES.PRODUCT_SERVICE_REVIEWS_WARRANTY,
            },
            {
              title: 'Comissões de Representantes',
              subFeatureId: 77,
              path: constants.ROUTES.REPRESENTATIVE_COMISSIONS,
              plansNotAllowed: ['Free', 'Start'],
            },
            {
              title: 'Vendas por Atendimento',
              subFeatureId: 78,
              path: constants.ROUTES.SALES_BY_ATTENDANCE,
              plansNotAllowed: ['Free', 'Start'],
            },
            {
              title: 'Venda/OS - Passagens',
              subFeatureId: 79,
              path: constants.ROUTES.SALE_PASSING,
              plansNotAllowed: ['Free', 'Start'],
            },
            {
              title: 'Listagem de Itens Cancelados',
              subFeatureId: 80,
              feature:
                constants.FEATURES.RELATORIO_LISTAGEM_DE_ITENS_CANCELADOS,
              needSaleItemApprovalCancellationActive: true,
              path: constants.ROUTES.LIST_CANCELED_ITEMS_REPORT,
              plansNotAllowed: ['Free', 'Start'],
            },
          ],
        },
        {
          title: 'Financeiro',
          mainFeatureId: 14,
          plansNotAllowed: ['Free', 'Start'],
          subMenuDegree: [
            {
              title: 'Recebimentos por Forma de Pagamento',
              subFeatureId: 81,
              path: constants.ROUTES.RECEIVEDS_BY_PAYMENT_FORM_REPORT,
              plansNotAllowed: ['Free', 'Start'],
            },
            {
              title: 'Valores por Status',
              subFeatureId: 82,
              path: constants.ROUTES.VALUES_BY_STATUS_REPORT,
              plansNotAllowed: ['Free', 'Start'],
            },
            {
              title: 'Resumo Pagamentos por Status',
              subFeatureId: 83,
              path: constants.ROUTES.PAYMENTS_BY_STATUS_REPORT,
              plansNotAllowed: ['Free', 'Start'],
            },
            {
              title: 'Fluxo de Caixa',
              subFeatureId: 84,
              needCashierControl: true,
              path: constants.ROUTES.CASHIER_FLOW_REPORT,
              plansNotAllowed: ['Free', 'Start'],
            },
            {
              title: 'DRE - Demonstrativo de Resultado do Exercício',
              subFeatureId: 85,
              path: constants.ROUTES.FINANCIAL_STATEMENT_REPORT,
              plansNotAllowed: ['Free', 'Start', 'Essencial'],
            },
            {
              title: 'Boletos Emitidos',
              subFeatureId: 86,
              needBankSlipModuleActive: true,
              path: constants.ROUTES.ISSUED_SLIP_REPORT,
              plansNotAllowed: ['Free', 'Start'],
            },
            {
              title: 'Taxa de Cartão',
              subFeatureId: 87,
              path: constants.ROUTES.CARD_TAXES_REPORT,
              plansNotAllowed: ['Free', 'Start'],
            },
            {
              title: 'Listagem de Títulos',
              subFeatureId: 88,
              path: constants.ROUTES.TITLE_LISTING,
              plansNotAllowed: ['Free', 'Start'],
            },
            {
              title: 'Relatório de Vales Emitidos',
              subFeatureId: 89,
              path: constants.ROUTES.VOUCHER_ISSUED,
              plansNotAllowed: ['Free', 'Start'],
            },
            {
              title: 'Pendências de Clientes/Fornecedor',
              subFeatureId: 90,
              path: constants.ROUTES.PENDING_CUSTOMERS_SUPPLIERS,
              plansNotAllowed: ['Free', 'Start'],
            },
            {
              title: 'Contas a Receber',
              subFeatureId: 91,
              path: constants.ROUTES.ACCOUNTS_RECEIVABLE_REPORT,
              plansNotAllowed: ['Free', 'Start'],
            },
            {
              title: 'Contas a Pagar',
              subFeatureId: 92,
              path: constants.ROUTES.ACCOUNTS_PAYABLE_REPORT,
              plansNotAllowed: ['Free', 'Start'],
            },
          ],
        },
        {
          title: 'Compras',
          mainFeatureId: 15,
          plansNotAllowed: ['Free', 'Start'],
          subMenuDegree: [
            {
              title: 'Compras por Status',
              subFeatureId: 93,
              path: constants.ROUTES.PURCHASES_BY_STATUS_REPORT,
              plansNotAllowed: ['Free', 'Start'],
            },
            {
              title: 'Compras Por Fornecedor',
              subFeatureId: 94,
              path: constants.ROUTES.PURCHASES_BY_PROVIDER_REPORT,
              plansNotAllowed: ['Free', 'Start'],
            },
            {
              title: 'Itens Aprovados/Não Aprovados',
              subFeatureId: 95,
              path: constants.ROUTES.APROVES_DISAPROVED_ITENS,
              plansNotAllowed: ['Free', 'Start'],
            },
            {
              title: 'Listagem de Cotação por Fornecedor',
              subFeatureId: 96,
              path: constants.ROUTES.QUOTES_SUPPLIER,
              plansNotAllowed: ['Free', 'Start', 'Essencial'],
            },
            {
              title: 'Listagem de Compras por Fornecedor',
              subFeatureId: 97,
              path: constants.ROUTES.LIST_PURCHASES_SUPPLIER,
              plansNotAllowed: ['Free', 'Start'],
            },
          ],
        },
        {
          title: 'Notas Fiscais',
          mainFeatureId: 16,
          plansNotAllowed: ['Free', 'Start'],
          subMenuDegree: [
            {
              title: 'Listagem de Notas Fiscais',
              subFeatureId: 98,
              plansNotAllowed: ['Free', 'Start'],
              path: constants.ROUTES.INVOICES_REPORT,
              needCashierControl: true,
              needFiscalModule: true,
            },
          ],
        },
        {
          title: 'Simulador de Lucratividade',
          subFeatureId: 116,
          plansNotAllowed: ['Free', 'Start', 'Essencial'],
          showNewBadge: true,
          targetBlank: true,
          path: constants.ROUTES.PROFIT_PANEL,
        },
      ],
    },
  },
  {
    title: 'Financeiro',
    groupFeatureId: 9,
    active: 'financial',
    icon: faMoneyBillAlt,
    group: 'Financeiro',
    subMenu: {
      title: 'Financeiro',
      items: [
        {
          title: 'Contas a Receber',
          subFeatureId: 99,
          path: constants.ROUTES.BILLS_TO_RECEIVE,
          plansNotAllowed: ['Free', 'Start'],
        },
        {
          title: 'Contas a Pagar',
          subFeatureId: 100,
          path: constants.ROUTES.BILLS_TO_PAY,
          plansNotAllowed: ['Free', 'Start'],
        },
        {
          title: 'Movimentação de Banco/Caixa',
          subFeatureId: 101,
          path: constants.ROUTES.FINANCIAL_MOVEMENTS,
          plansNotAllowed: ['Free', 'Start'],
        },
        {
          title: 'Pagamento de Vale',
          subFeatureId: 102,
          path: constants.ROUTES.VOUCHER_PAYMENTS,
          plansNotAllowed: ['Free', 'Start', 'Essencial'],
        },
      ],
    },
  },
  {
    title: 'Notas Fiscais',
    groupFeatureId: 10,
    active: 'invoices',
    icon: faReceipt,
    group: 'Notas Fiscais',
    needFiscalModule: true,
    plansNotAllowed: ['Free', 'Start'],
    subMenu: {
      title: 'Notas Fiscais',
      items: [
        {
          title: 'Nota Fiscal (NF-e, NFC-e e SAT-CFe)',
          subFeatureId: 103,
          path: constants.ROUTES.INVOICES,
          needFiscalModule: true,
          needNFeModule: true,
          plansNotAllowed: ['Free', 'Start'],
        },
        {
          title: 'Nota Fiscal de Serviço (NFS-e)',
          subFeatureId: 104,
          path: constants.ROUTES.SERVICE_INVOICES,
          needFiscalModule: true,
          needNFSeModule: true,
          plansNotAllowed: ['Free', 'Start'],
        },
        {
          title: 'Exportar XML',
          subFeatureId: 117,
          path: constants.ROUTES.EXPORT_XML,
          targetBlank: true,
          needFiscalModule: true,
          needNFSeModule: true,
          plansNotAllowed: ['Free', 'Start'],
        },
      ],
    },
  },
];
