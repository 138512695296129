import axios from 'axios'

export async function getUserIpv4() {
  try {
    const res = await axios.get('https://geolocation-db.com/json/')
    return res.data.IPv4
  } catch (err) {
    console.log(err)
    throw err
  }
}
