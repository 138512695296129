import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from 'client/components/CustomButton/Button'
import { cpfOrCnpjMask } from 'client/components/ToNormalize/ToNormalize'
import { format } from 'date-fns'
import React from 'react'
import { Link } from 'react-router-dom'
import ReactTable from 'react-table'

const FinancingBankSlipTable = ({
  data,
  totalPages,
  currentPage,
  setCurrentPage,
  setPageLimit,
  loading,
  history,
}) => {
  return (
    <>
      <ReactTable
        style={{
          fontWeight: 'bold',
          textAlign: 'center',
          marginTop: 15,
        }}
        data={data}
        columns={[
          {
            Header: 'Data',
            accessor: 'createdAt',
            width: Math.round((window.innerWidth - 55) * 0.09),
            Cell: (props) => format(new Date(props.value), 'dd/MM/yyyy'),
          },
          {
            Header: 'Parceiro',
            accessor: 'partner',
            width: Math.round((window.innerWidth - 55) * 0.09),
            Cell: (props) => {
              return (
                <button
                  className="admin-custom-label"
                  style={{
                    backgroundColor:
                      props.value === 'Koin' ? '#428bca' : '#5bc0de',
                  }}
                >
                  {props.value}
                </button>
              )
            },
          },
          {
            Header: 'CNPJ',
            accessor: 'companyCpfCnpj',
            width: Math.round((window.innerWidth - 55) * 0.13),
            Cell: (props) => cpfOrCnpjMask(props.value),
          },
          {
            Header: 'Razão Social',
            accessor: 'companyName',
            width: Math.round((window.innerWidth - 55) * 0.22),
          },
          {
            Header: 'Nome Fantasia',
            accessor: 'companyTradingName',
            width: Math.round((window.innerWidth - 55) * 0.22),
          },
          {
            Header: 'Status',
            accessor: 'isActive',
            width: Math.round((window.innerWidth - 55) * 0.08),
            Cell: (props) => (
              <Button
                id="botaoReactTable"
                style={{
                  width: '80%',
                  height: '100%',
                  backgroundColor: props.value === true ? '#5CB85C' : '#D9534F',
                }}
              >
                {props.value === true ? 'Ativo' : 'Inativo'}
              </Button>
            ),
          },
          {
            Header: 'Ações',
            accessor: 'id',
            Cell: (props) => {
              return (
                <span
                  onClick={() =>
                    history.push(`financing-bank-slip-config#${props.value}`, {
                      partnerId: props.original.partnerId,
                    })
                  }
                >
                  <FontAwesomeIcon
                    cursor="pointer"
                    icon={faEdit}
                    style={{ width: '1.5em', height: '1.5em' }}
                  />
                </span>
              )
            },
          },
        ]}
        pages={totalPages}
        page={currentPage}
        onPageChange={setCurrentPage}
        manual
        onPageSizeChange={(value) => {
          setPageLimit(value)
        }}
        defaultPageSize={10}
        loading={loading}
        showPagination={true}
        sortable={true}
        showPaginationTop={false}
        showPaginationBottom={true}
        pageSizeOptions={[5, 10, 20, 25, 50, 100]}
        defaultSorted={[
          {
            id: 'createdAt',
            desc: false,
          },
        ]}
        previousText="Anterior"
        nextText="Próximo"
        loadingText="Carregando..."
        noDataText="Não há informação"
        pageText="Página"
        ofText="de"
        rowsText="linhas"
      />
    </>
  )
}

export default FinancingBankSlipTable
