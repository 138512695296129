import SearchInput from 'components/SearchInput';
import { format } from 'date-fns';
import React from 'react';
import { SelectBranding } from 'v2/components/Select';

function PollsFilter({
  history,
  query,
  setQuery,
  status,
  setStatus,
  type,
  setType,
  initialDate,
  setInitialDate,
  finalDate,
  setFinalDate,
  setBranding,
  branding,
}) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div className="quotations-row">
        <div>
          <button
            className="btn btn-sucesso"
            onClick={() => history.push('polls-register')}
          >
            + Nova Pesquisa
          </button>
        </div>

        <div
          style={{
            display: 'flex',
            marginLeft: 10,
          }}
        >
          <SearchInput
            containerStyle={{ width: 370 }}
            placeholder="Pesquisa por Descrição"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
        </div>

        <div style={{ display: 'flex' }}>
          <label>
            <strong>Status:</strong>
          </label>
          <select
            className="form-control foco-input"
            onChange={(event) => setStatus(event.target.value)}
            value={status}
          >
            <option value="">Ambos</option>
            <option value="true">Ativas</option>
            <option value="false">Inativas</option>
          </select>
        </div>

        <div style={{ display: 'flex' }}>
          <label>
            <strong>Pesquisa:</strong>
          </label>
          <select
            className="form-control foco-input"
            onChange={(event) => setType(event.target.value)}
            value={type}
          >
            <option value="">Todas</option>
            <option value="1">NPS</option>
            <option value="2">NVS</option>
            <option value="3">MHV</option>
          </select>
        </div>

        <div style={{ display: 'flex' }}>
          <SelectBranding
            boldLabel
            showAllOption
            variant={'row'}
            height={'35px'}
            value={branding}
            onChange={(e) => setBranding(e)}
          />
        </div>
      </div>

      <div className="quotations-row-data">
        <div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              width: 120,
            }}
          >
            <strong>Data Inicial:</strong>
          </div>
          <input
            className="form-control foco-input"
            type="date"
            name="dataInicial"
            value={initialDate}
            max={'9999-12-31'}
            onChange={(e) => {
              setInitialDate(e.target.value);
            }}
          />
        </div>

        <div>
          <div style={{ display: 'flex', alignItems: 'center', width: 120 }}>
            <strong>Data Final:</strong>
          </div>
          <input
            className="form-control foco-input"
            type="date"
            name="dataFinal"
            value={finalDate}
            min={initialDate && format(new Date(initialDate), 'yyyy-MM-dd')}
            max={'9999-12-31'}
            onChange={(e) => {
              setFinalDate(e.target.value);
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default PollsFilter;
