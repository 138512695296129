import React, { useEffect, useState } from 'react';
import { change, Field } from 'redux-form';
import { Col, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import axios from 'axios';
import {
  cpfOrCnpjMask,
  onlyNumbers,
} from '../../../../client/components/ToNormalize/ToNormalize';
import CNAESRepository from '../../../../repositories/Cnaes';
import { useAuth } from '../../../../contexts/auth';
import { ISSMask } from '../../../../utils/masks';
import RenderField from '../../../../components/RenderField';
import FormClient from '../../Clients/NewClient/FormClient';
import SelectCustomers from 'v2/client/components/SelectCustomers';
import { indexCustomersWithVehicles } from 'v2/repositories/CustomerRepository';
import CustomerRepository from '../../../../repositories/Customers';

const MainData = ({ setLoading }) => {
  const [CNAEs, setCNAEs] = useState([]);
  const [isCustomerModalOpen, setIsCustomerModalOpen] = useState(false);
  const [reloadCustomerOptionsTrigger, setReloadCustomerOptionsTrigger] =
    useState(false);

  const {
    customerId,
    saleId,
    isInvoiceIssued,
    isInvoiceProcessing,
    isInvoiceDenied,
    isInvoiceCanceled,
    NFSPatternId,
    selectedCustomer,
  } = useSelector((state) => state.form.NFSe.values);

  const { companyId } = useAuth();
  const dispatch = useDispatch();
  const isInvoiceProcessed =
    isInvoiceDenied ||
    isInvoiceProcessing ||
    isInvoiceIssued ||
    isInvoiceCanceled;

  useEffect(() => {
    axios
      .all([loadCNAEs()])
      .then(
        axios.spread((CNAEs) => {
          setCNAEs(CNAEs);
        })
      )
      .catch((error) => {
        console.error(error);
        toastr.warning(error.message);
      });
  }, []);

  async function loadCNAEs() {
    try {
      const CNAEs = await CNAESRepository.getAllActiveByCompany(companyId);
      return CNAEs;
    } catch (err) {
      throw new Error(
        'Ocorreu um erro ao carregar as CNAEs. Por favor, tente novamente'
      );
    }
  }

  const handleSelectCustomer = (customer) => {
    dispatch([
      change('NFSe', 'customerName', customer.value.customer_name),
      change('NFSe', 'selectedCustomer', customer.value),
      change('NFSe', 'customerId', customer.value.customer_id),
      change(
        'NFSe',
        'customerIE',
        customer.value.customer_type === 'Juridica'
          ? customer.value.IE
          : 'ISENTO'
      ),
      change(
        'NFSe',
        'customerCpfCnpj',
        cpfOrCnpjMask(customer.value.customer_cpfcnpj)
      ),
    ]);
  };

  const handleChangeCustomer = async (customer) => {
    const data = await CustomerRepository.getCustomer(
      customer.value.customer_id
    );

    dispatch([
      change('NFSe', 'customerId', customer.value.customer_id),
      change('NFSe', 'customerName', customer.value.customer_name),
      change('NFSe', 'selectedCustomer', customer.value),
      change('NFSe', 'customerIE', customer.value.customer_ie),
      change(
        'NFSe',
        'customerCpfCnpj',
        cpfOrCnpjMask(customer.value.customer_cpfcnpj)
      ),
      change('NFSe', 'customerUF', data.Address[0]?.State),
    ]);
  };

  async function handleSubmitCustomerModal(cpfCnpj, id, companyName) {
    setIsCustomerModalOpen(false);

    const customersList = await indexCustomersWithVehicles({
      company_id: companyId,
      page: 1,
      limit: 10,
      query: companyName,
      show_label: false,
      show_vehicles: false,
    });

    const currentCustomer = customersList.rows.find(
      (item) => item.customer_id === id
    );

    const currentCustomerLabel = currentCustomer
      ? `${currentCustomer.customer_name} ${
          currentCustomer.customer_cpfcnpj &&
          `- ${cpfOrCnpjMask(currentCustomer.customer_cpfcnpj)}`
        }`
      : '';

    setReloadCustomerOptionsTrigger(true);
    handleChangeCustomer({
      value: { ...currentCustomer, label: currentCustomerLabel },
      label: currentCustomerLabel,
    });
    setIsCustomerModalOpen(false);
  }

  function handleOpenClientModal() {
    if (isInvoiceProcessed || !customerId) return;
    // valida se a nota tem vinculo com alguma venda também
    // if (isInvoiceProcessed || !customerId || !!saleId) return
    setIsCustomerModalOpen(true);
  }

  return (
    <div id="NFSe-main-data">
      <div>
        <Col
          xs={12}
          sm={12}
          md={1}
          lg={1}
          style={{ width: '120px' }}
          className="col-padding"
        >
          <Field name="code" component={RenderField} label="Nota" disabled />
        </Col>
        <Col xs={12} sm={12} md={1} lg={1} className="col-padding">
          <Field name="serie" component={RenderField} label="Série" disabled />
        </Col>
        <Col xs={12} sm={12} md={1} lg={1} className="col-padding">
          <Field
            name="subs"
            component={RenderField}
            label="Subs"
            normalize={onlyNumbers}
            disabled={
              isInvoiceIssued || isInvoiceCanceled || isInvoiceProcessing
            }
          />
        </Col>
        <Col xs={12} sm={12} md={1} lg={1} className="col-padding">
          <Field
            name="saleCode"
            component={RenderField}
            label="Venda"
            disabled
          />
        </Col>
        <Col xs={12} sm={12} md={7} lg={7} className="col-padding">
          <Field
            name="status"
            component={RenderField}
            label="Status da NF-e"
            disabled
            style={{
              color: isInvoiceIssued
                ? 'green'
                : isInvoiceProcessing
                ? 'blue'
                : isInvoiceDenied
                ? 'red'
                : '#888888',
            }}
          />
        </Col>
      </div>
      <div>
        <Col xs={12} sm={12} md={5} lg={5} className="col-padding">
          <Field
            name="CNAE"
            component={RenderField}
            label="CNAE"
            as="select"
            disabled={
              isInvoiceIssued ||
              isInvoiceCanceled ||
              isInvoiceProcessing ||
              NFSPatternId === 138
            }
          >
            <option value="">Selecione</option>
            {CNAEs.map((CNAE) => (
              <option key={CNAE.id} value={CNAE.code}>
                {CNAE.code} - {CNAE.description}
              </option>
            ))}
          </Field>
        </Col>
        <Col xs={12} sm={12} md={1} lg={1} className="col-padding">
          <Field
            name="ISS"
            component={RenderField}
            label="% ISS"
            {...ISSMask}
            disabled={
              isInvoiceIssued || isInvoiceCanceled || isInvoiceProcessing
            }
          />
        </Col>
        <Col xs={12} sm={12} md={2} lg={2} className="date-field col-padding">
          <Field
            name="date"
            component={RenderField}
            label="Data de Criação"
            type="date"
            disabled={
              isInvoiceIssued || isInvoiceCanceled || isInvoiceProcessing
            }
          />
        </Col>
        <Col
          xs={12}
          sm={12}
          md={3}
          lg={3}
          className="date-time-field col-padding"
        >
          <Field
            name="issuedAt"
            component={RenderField}
            label="Data de Emissão"
            type="datetime-local"
            disabled
          />
        </Col>
      </div>
      <div>
        <Col xs={12} sm={12} md={4} lg={4} className="col-padding">
          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <label>Cliente</label>
              <span
                className="field-hiperlink"
                onClick={handleOpenClientModal}
                style={{
                  cursor: 'pointer',
                }}
              >
                Visualizar Cliente
              </span>
            </div>

            <SelectCustomers
              onChange={(item) => handleSelectCustomer(item)}
              value={selectedCustomer}
              setReloadTrigger={setReloadCustomerOptionsTrigger}
              reloadTrigger={reloadCustomerOptionsTrigger}
              showDefaultLabel={false}
              showVehicles={false}
              placeholder="Selecione um cliente"
              status="ATIVO"
              isDisabled={
                !!saleId ||
                isInvoiceIssued ||
                isInvoiceCanceled ||
                isInvoiceProcessing
              }
            />
          </div>
        </Col>
        <Col
          xs={12}
          sm={12}
          md={2}
          lg={2}
          className="col-padding"
          style={{ width: '155px' }}
        >
          <Field
            name="customerCpfCnpj"
            component={RenderField}
            label="CNPJ/CPF"
            disabled
          />
        </Col>
        <Col
          xs={12}
          sm={12}
          md={2}
          lg={2}
          className="col-padding"
          style={{ width: '140px' }}
        >
          <Field
            name="customerIE"
            component={RenderField}
            label="Inscrição Estadual"
            disabled
          />
        </Col>
      </div>

      <Modal
        dialogClassName="modal-formProduto"
        show={isCustomerModalOpen}
        onHide={() => setIsCustomerModalOpen(false)}
        animation
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>Dados do Cliente</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflowY: 'scroll' }}>
          <FormClient
            modal
            hideModal={() => setIsCustomerModalOpen(false)}
            handleModal={handleSubmitCustomerModal}
            dispatch={dispatch}
            ClientId={customerId}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default MainData;
