import SearchInput from 'components/SearchInput'
import React from 'react'

const FinancingBankSlipFilter = ({
  query,
  handleChangeQuery,
  status,
  setStatus,
  partner,
  setPartnerId,
  initialDate,
  setInitialDate,
  finalDate,
  setFinalDate,
  history,
}) => {
  return (
    <>
      <section className="financing-bank-slip__row">
        <div className='new-financing-bank-slip__button'>
          <button
            className="btn btn-sucesso"
            onClick={() => history.push('financing-bank-slip-config')}
          >
            + Novo Cliente
          </button>
        </div>

        <div className="search-input-wrapper new-financing-bank-slip__button">
          <i
            id="iconepesquisar"
            style={{ cursor: 'pointer' }}
            className="fa fa-search"
          />
          <input
            type="text"
            style={{
              paddingLeft: 35,
            }}
            className="form-control foco-input"
            placeholder="Pesquisar por CNPJ ou Nome/Razão Social"
            value={query}
            onChange={(e) => handleChangeQuery(e.target.value)}
          />
        </div>
        <fieldset>
          <label style={{ width: '75px' }}>Status:</label>
          <select
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            className="form-control foco-input"
          >
            <option value="">Ambos</option>
            <option value={1}>Ativo</option>
            <option value={0}>Inativo</option>
          </select>
        </fieldset>
        <fieldset>
          <label style={{ width: '75px' }}>Parceiro:</label>
          <select
            value={partner}
            onChange={(e) => setPartnerId(e.target.value)}
            className="form-control foco-input"
          >
            <option value="">Todos</option>
            <option value={1}>Koin</option>
            <option value={2}>Boleto Flex</option>
          </select>
        </fieldset>
      </section>

      <section
        className='financing-bank-slip__row'
      >
        <fieldset>
          <label>Data Inicial:</label>
          <input
            type="date"
            className="form-control foco-input"
            value={initialDate}
            max={finalDate}
            onChange={(e) => setInitialDate(e.target.value)}
          />
        </fieldset>
        <fieldset>
          <label>Data Final:</label>
          <input
            type="date"
            className="form-control foco-input"
            value={finalDate}
            min={initialDate}
            onChange={(e) => setFinalDate(e.target.value)}
          />
        </fieldset>
      </section>
    </>
  )
}

export default FinancingBankSlipFilter
