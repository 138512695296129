import React, { useState } from 'react'
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import { currency } from 'client/components/ToNormalize/ToNormalize'
import { format } from 'date-fns'
import DownloadXlsButton from 'components/DownloadXlsButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons'
import { useAuth } from 'contexts/auth'
import rankingRepository from 'repositories/ProductsServicesRanking'
import { toastr } from 'react-redux-toastr'
import Button from 'client/components/CustomButton/Button.jsx'

const HomeRankingSales = () => {
  const { companyId } = useAuth()

  const [rankingType, setRankingType] = useState()

  const [type, setType] = useState()
  const [initialDate, setInitialDate] = useState()
  const [finalDate, setFinalDate] = useState()
  const [loading, setLoading] = useState(false)
  const [reportSalesRanking, setReportSalesRanking] = useState([])
  const [noDataText, setNoDataText] = useState(
    'Selecione o período e o filtros para gerar seu ranking de vendas'
  )

  const loadProductsAndServicesRanking = async () => {
    try {
      if (!initialDate || !finalDate)
        return toastr.warning(
          'Selecione um período e clique em Gerar relatório'
        )

      setLoading(true)

      const { data: reportSalesRanking } =
        await rankingRepository.getProductsAndServicesRanking(companyId, {
          initialDate,
          finalDate,
          type,
          rankingType,
        })

      if (!reportSalesRanking.length) {
        toastr.warning('Nenhum item foi localizado no período')
        return setNoDataText('Nenhum item foi localizado no período')
      }

      let serializedProductsAndServices = reportSalesRanking.map(
        (saleItem, index) => ({
          ...saleItem,
          numberRanking: String(index + 1) + 'º',
          code:
            saleItem.Type === 'Produto'
              ? saleItem.Products?.Code
              : saleItem.Services?.Code,
          description: saleItem.Description,
          type: saleItem.Type,
          brand:
            saleItem.Type === 'Produto'
              ? saleItem.Products?.Brands?.Description
              : '',
          soldQuantity: saleItem.soldQuantity,
          soldValue: currency(saleItem.soldValue),
        })
      )

      setReportSalesRanking(serializedProductsAndServices)
    } catch (err) {
      console.log(err)
      return toastr.warning('Ocorreu um erro ao gerar o relatório.')
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <section className='return-forecast-container'>
        <section>
          <fieldset>
            <label>Data Inicial:</label>
            <input
              type='date'
              className='form-control foco-input'
              value={initialDate}
              max={finalDate}
              onChange={(e) => setInitialDate(e.target.value)}
            />
          </fieldset>
          <fieldset>
            <label>Data Final:</label>
            <input
              type='date'
              className='form-control foco-input'
              value={finalDate}
              min={initialDate}
              onChange={(e) => setFinalDate(e.target.value)}
            />
          </fieldset>
          <fieldset>
            <label>Tipo:</label>
            <select
              className='form-control foco-input'
              onChange={(event) => setType(event.target.value)}
              value={type}
            >
              <option value=''>Ambos</option>
              <option value='Produto'>Produto</option>
              <option value='Serviço'>Serviço</option>
            </select>
          </fieldset>
          <fieldset>
            <label>Ranking por:</label>
            <select
              className='form-control foco-input'
              onChange={(event) => setRankingType(event.target.value)}
              value={rankingType}
            >
              <option value='soldQuantity'>Quantidade Vendida</option>
              <option value='soldValue'>Valor Faturado</option>
            </select>
          </fieldset>
          <div>
            <button
              className='btn btn-sucesso'
              type='submit'
              disabled={initialDate === '' || finalDate === '' || loading}
              onClick={loadProductsAndServicesRanking}
            >
              <span
                className={`${loading ? 'fa fa-spinner fa-pulse fa-1x' : ''}`}
              />{' '}
              Gerar relatório
            </button>
          </div>
          <DownloadXlsButton
            archiveName={`Ranking de Produtos/Serviços vendidos
              -${format(new Date(initialDate || null), 'dd-MM-yyyy')}
              -${format(new Date(finalDate || null), 'dd-MM-yyyy')}`}
            data={reportSalesRanking}
            className='btn btn-export export-forecast'
            disabled={loading || !reportSalesRanking.length}
            columns={[
              {
                name: '',
                acessor: 'numberRanking',
              },
              {
                name: 'Código Interno',
                acessor: 'code',
              },
              {
                name: 'Descrição',
                acessor: 'description',
              },
              {
                name: 'Tipo',
                acessor: 'type',
              },
              {
                name: 'Marca',
                acessor: 'brand',
              },
              {
                name: 'Quantidade vendida',
                acessor: 'soldQuantity',
              },
              {
                name: 'Valor vendido',
                acessor: 'soldValue',
              },
            ]}
          >
            <FontAwesomeIcon color='white' icon={faCloudDownloadAlt} /> Exportar
            .xls
          </DownloadXlsButton>
        </section>
      </section>
      <ReactTable
        style={{
          marginTop: '30px',
          width: '85%',
          fontWeight: 'bold',
          textAlign: 'center',
        }}
        data={reportSalesRanking}
        columns={[
          {
            Header: '',
            accessor: 'numberRanking',
            headerClassName: 'text-left',
            width: 40,
            className: 'texto',
            fontWeight: 'bold',
          },
          {
            Header: 'Código Interno',
            accessor: 'code',
            headerClassName: 'text-left',
            width: 130,
            className: 'texto',
          },
          {
            Header: 'Descrição',
            accessor: 'description',
            headerClassName: 'text-left',
            width: 260,
            className: 'texto',
          },
          {
            Header: 'Tipo',
            accessor: 'type',
            headerClassName: 'text-left',
            width: 130,
            Cell: (props) => (
              <Button
                id='botaoReactTable'
                style={{
                  backgroundColor:
                    props.value == 'Produto' ? '#428bca' : '#5cb85c',
                }}
              >
                {props.value}
              </Button>
            ),
          },
          {
            Header: 'Marca',
            accessor: 'brand',
            headerClassName: 'text-left',
            width: 130,
            className: 'texto',
          },
          {
            Header: 'Quantidade vendida',
            accessor: 'soldQuantity',
            headerClassName: 'text-left',
            width: 180,
            className: 'texto',
          },
          {
            Header: 'Valor vendido',
            accessor: 'soldValue',
            headerClassName: 'text-left',
            width: 120,
          },
        ]}
        defaultPageSize={10}
        showPagination={true}
        sortable={true}
        showPaginationTop={false}
        showPaginationBottom={true}
        pageSizeOptions={[5, 10, 20, 25, 50, 100]}
        previousText='Anterior'
        nextText='Próximo'
        loadingText='Carregando...'
        noDataText={noDataText}
        pageText='Página'
        ofText='de'
        rowsText='linhas'
      />
      <div>
        <p
          style={{
            textAlign: 'end',
            margin: '10px',
            marginRight: '23rem',
          }}
        >
          Total de Itens: {reportSalesRanking.length}
        </p>
      </div>
    </>
  )
}
export default HomeRankingSales
