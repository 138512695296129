import React, { useState } from 'react';
import { toastr } from 'react-redux-toastr';
import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns/esm';

import TableByFormOfPayment from './TableByFormOfPayment';
import DownloadXlsButton from 'components/DownloadXlsButton';
import { currency } from 'client/components/ToNormalize/ToNormalize';
import BillsToReceiveRepository from '../../../../../repositories/BillsToReceive';
import { getDateOnlyFromDate } from 'utils/dateHelpers';
import { xlsColumns } from './excelHelpers';
import ChartByFormOfPayment from './ChartByFormPayment';
import PDFButton from 'client/components/PDFButton';
import { encrypt } from 'client/components/ToNormalize/ToNormalize';
import { isValid } from 'date-fns';
import { useEffect } from 'react';

const ReceivedByFormOfPayment = () => {
  const [loading, setLoading] = useState(false);
  const [dateType, setDateType] = useState('payment');
  const [initialDate, setInitialDate] = useState('');
  const [finalDate, setFinalDate] = useState('');
  const [filterOpenPayment, setFilterOpenPayment] = useState(false);
  const [isReportReady, setReportReady] = useState(false);
  const [billsToReceiveInfoToExport, setBillsToReceiveInfoToExport] = useState(
    []
  );
  const [formsOfPaymentChartData, setFormsOfPaymentChartData] = useState([]);
  const [formatTableData, setFormatTableData] = useState([]);
  const companyId = localStorage.getItem('ID_EMPRESA');

  const handleSubmit = async () => {
    if (validations()) handleProcess();
  };

  const validations = () => {
    if (!isValid(new Date(initialDate)) || !isValid(new Date(finalDate))) {
      toastr.warning(
        'Não foi possível gerar o relatório.',
        ' Preencha todos os campos e tente novamente'
      );
      return false;
    }

    return true;
  };

  const handleProcess = async () => {
    setLoading(true);
    setReportReady(false);
    try {
      const data = await BillsToReceiveRepository.generateBillsReceiveds({
        companyId,
        initialDate,
        finalDate,
        filterOpenPayment,
        dateType,
      });

      if (!data?.totalizatorsByFormOfPayment.length)
        return toastr.warning(
          'Relatório não gerado',
          'Nenhuma informação foi localizada. Valide os filtros e tente novamente'
        );

      const formatedBillsTitle = data.totalizatorsByFormOfPayment.map(
        (item) => ({
          type: item.type,
          description: item.description,
          amount: item.total,
        })
      );
      const dataXLS = data.formOfPayments
        .map((item) => item.rows)
        .reduce((prev, curr) => [...prev, ...curr], [])
        .map((item) => {
          return {
            type: item.isReceived ? 'Recebido' : 'A Receber',
            saleDate: item.saleCreationDate
              ? format(new Date(item.saleCreationDate), 'dd/MM/yyyy')
              : '',
            saleCloseDate: item.saleCloseDate
              ? format(new Date(item.saleCloseDate), 'dd/MM/yyyy')
              : '',
            saleCode: item.saleCode,
            saleType: item.saleType,
            customer: item.customer,
            billValue: currency(item.amount),
            dueDate: item.dueDate
              ? format(
                  new Date(getDateOnlyFromDate(item.dueDate)),
                  'dd/MM/yyyy'
                )
              : '',
            paymentDate: item.paymentDate
              ? format(
                  new Date(getDateOnlyFromDate(item.paymentDate)),
                  'dd/MM/yyyy'
                )
              : '',
          };
        });

      setFormsOfPaymentChartData(
        data?.formOfPayments.map((item) => {
          return [
            item.formOfPayment,
            Number(item.total),
            currency(Number(item.total)),
          ];
        })
      );

      setFormatTableData(formatedBillsTitle);
      setBillsToReceiveInfoToExport(dataXLS);
      setReportReady(true);
    } catch (err) {
      console.log(err);
      toastr.error(
        err?.response?.data?.message ||
          'Ocorreu um erro com a buscar. Por favor, tente novamente.'
      );
    } finally {
      setLoading(false);
    }
  };

  const handlePrint = () => {
    const companyIdHash = encrypt(companyId, '@OS-dig:companyId');
    window.open(
      window.location.origin +
        `/report/receipts-payment-method?companyId=${companyIdHash}&initialDate=${initialDate}&finalDate=${finalDate}&filterOpenPayment=${filterOpenPayment}&dateType=${dateType}`,
      '_blank'
    );
  };

  useEffect(() => {
    if (dateType === 'payment') {
      setFilterOpenPayment(false);
    }
  }, [dateType]);

  return (
    <div className="relatorio-por-pagamento-container">
      <header>
        <>
          <div style={{ width: '130px' }}>
            <strong>Tipo de Data:</strong>
            <select
              type="text"
              id="offline"
              value={dateType}
              onChange={(e) => setDateType(e.target.value)}
              className="form-control foco-input"
            >
              <option value={'payment'} selected>
                Pagamento
              </option>
              <option value={'createdAt'}>Criação</option>
            </select>
          </div>
          <div>
            <span>
              <strong>Data Inicial:</strong>
            </span>
            <input
              className="form-control foco-input"
              type="date"
              name="initialDate"
              value={initialDate}
              max={
                finalDate
                  ? format(
                      new Date(getDateOnlyFromDate(finalDate)),
                      'yyyy-MM-dd'
                    )
                  : format(new Date(), 'yyyy-MM-dd')
              }
              onChange={(e) => setInitialDate(e.target.value)}
            />
          </div>

          <div>
            <span>
              <strong>Data Final:</strong>
            </span>
            <input
              className="form-control foco-input"
              type="date"
              name="finalDate"
              value={finalDate}
              min={
                initialDate
                  ? format(
                      new Date(getDateOnlyFromDate(initialDate)),
                      'yyyy-MM-dd'
                    )
                  : undefined
              }
              onChange={(e) => setFinalDate(e.target.value)}
            />
          </div>
          <div style={{ width: '180px' }}>
            <strong>Exibir valores a receber?</strong>
            <select
              type="text"
              id="offline"
              value={filterOpenPayment}
              onChange={(e) => setFilterOpenPayment(e.target.value)}
              className="form-control foco-input"
              disabled={dateType === 'payment'}
            >
              <option value={false} selected>
                Não
              </option>
              <option value={true}>Sim</option>
            </select>
          </div>
        </>

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            gap: '4px',
            height: '35px',
            marginTop: '33.2px',
            width: '380px',
          }}
        >
          <button
            style={{ width: '130px' }}
            className="btn btn-sucesso"
            type="submit"
            onClick={handleSubmit}
          >
            <span
              className={`${loading ? 'fa fa-spinner fa-pulse fa-1x' : ''}`}
            />{' '}
            Processar
          </button>

          <PDFButton
            disabled={loading || !isReportReady}
            onClick={handlePrint}
          />

          <DownloadXlsButton
            archiveName="RecebimentosPorFormaDePagamento"
            data={billsToReceiveInfoToExport}
            className="btn btn-export"
            disabled={loading || !billsToReceiveInfoToExport.length}
            columns={xlsColumns}
            style={{ height: '35px', border: 'none' }}
          >
            <FontAwesomeIcon color="white" icon={faCloudDownloadAlt} /> Exportar
            .xls
          </DownloadXlsButton>
        </div>
      </header>

      <section>
        <div style={{ padding: 5 }}>
          <TableByFormOfPayment data={formatTableData} loading={loading} />
        </div>

        <div
          style={{
            padding: 5,
            display: 'flex',
            flexDirection: 'column',
            marginLeft: '100px',
          }}
        >
          <ChartByFormOfPayment data={formsOfPaymentChartData} />
        </div>
      </section>
    </div>
  );
};

export default ReceivedByFormOfPayment;
