import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table';
import { format } from 'date-fns';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';
import Toggle from 'react-toggle';

import Card from 'components/Card/Card.jsx';
import Button from 'client/components/CustomButton/Button';
import SearchInput from 'components/SearchInput';
import AlertModal from 'components/AlertModal';

import useFilters from '../../../hooks/useFilters';
import { getDateOnlyFromDate } from 'utils/dateHelpers';

import advertisementsRepository from '../../../repositories/Advertisements';
import segmentsRepository from '../../../repositories/Segments';
import plansRepository from '../../../repositories/Plans';
import AdvertisingActions from './AdvertisingActions';

import { BrandingLabel } from 'v2/components/BrandingLabel';
import { SelectBranding } from 'v2/components/Select';

const Advertisements = ({ history }) => {
  const [advertisements, setAdvertisements] = useState([]);
  const [segments, setSegments] = useState([]);
  const [plans, setPlans] = useState([]);
  const [branding, setBranding] = useState({ label: 'Todos', value: '' });

  const [segmentId, setSegmentId] = useState(undefined);
  const [planId, setPlanId] = useState(undefined);
  const [advertisingId, setAdvertisingId] = useState(undefined);

  const [loading, setLoading] = useState(false);
  const [
    isConfirmActivateAdvertisingModalOpen,
    setIsConfirmActivateAdvertisingModalOpen,
  ] = useState(false);

  const { queryFilter, dateFilter, statusFilter, typeFilter } = useFilters();
  const { query, setQuery, filterByQuery } = queryFilter;
  const { initialDate, setInitialDate, finalDate, setFinalDate, filterByDate } =
    dateFilter;
  const { filterByStatus, setStatus, status } = statusFilter;

  const { setType: setBrandingFilter, filterByType: filterByBranding } =
    typeFilter;

  useEffect(() => {
    setBrandingFilter(branding.value);
  }, [branding]);

  useEffect(() => {
    setLoading(true);
    Promise.all([loadAdvertisements(), loadSegments(), loadPlans()]).then(() =>
      setLoading(false)
    );
  }, []);

  async function loadAdvertisements() {
    try {
      const advertisements = await advertisementsRepository.getAll();
      setAdvertisements(advertisements);
    } catch (err) {
      if (err.response) {
        toastr.warning(err.response.data.message);
      } else {
        toastr.warning(
          'Ocorreu um erro ao carregar as propagandas. Por favor, tente novamente'
        );
      }
    }
  }

  async function loadSegments() {
    try {
      const segments = await segmentsRepository.getAll();
      setSegments(segments);
    } catch (err) {
      toastr.warning(
        'Ocorreu um erro ao carregar os segmentos. Por favor, tente novamente'
      );
    }
  }

  async function loadPlans() {
    try {
      const plans = await plansRepository.getAll();
      setPlans(plans.map((plan) => plan.Plan));
    } catch (err) {
      toastr.warning(
        'Ocorreu um erro ao carregar os planos. Por favor, tente novamente'
      );
    }
  }

  function handleFilters(advertising) {
    const querySearch = [advertising['code'], advertising['description']];
    return (
      filterByQuery(querySearch) &&
      filterByDate('createdAt', advertising) &&
      filterByStatus('isActive', advertising) &&
      filterByPlan(advertising) &&
      filterBySegment(advertising) &&
      filterByBranding('brandingId', advertising)
    );
  }

  function filterByPlan(advertising) {
    if (!planId) return true;
    return advertising.Plans.some((plan) => String(plan.id) === planId);
  }

  function filterBySegment(advertising) {
    if (!segmentId) return true;
    return advertising.Segments.some(
      (segment) => String(segment.id) === segmentId
    );
  }

  async function handleDisableAdvertising(advertisingId) {
    setLoading(true);

    try {
      await advertisementsRepository.disable(advertisingId);
      const newAdvertisements = [...advertisements];
      const index = newAdvertisements.findIndex(
        (child) => child.id === advertisingId
      );
      if (index !== -1) {
        newAdvertisements[index].isActive = false;
      }
      setAdvertisements(newAdvertisements);
    } catch (err) {
      console.log(err);
      if (err.response) {
        toastr.warning(err.response.data.message);
      } else {
        toastr.warning('Ocorreu um erro inesperado.');
      }
    }
    setLoading(false);
  }

  async function handleActivateAdvertising() {
    setLoading(true);

    try {
      await advertisementsRepository.activate(advertisingId);
      const newAdvertisements = [...advertisements];
      const index = newAdvertisements.findIndex(
        (child) => child.id === advertisingId
      );
      if (index !== -1) {
        newAdvertisements[index].isActive = true;
      }
      setAdvertisements(newAdvertisements);
      setIsConfirmActivateAdvertisingModalOpen(false);
    } catch (err) {
      if (err.response) {
        toastr.error(err.response.data.message);
      } else {
        toastr.error('Ocorreu um erro inesperado.');
      }
    }
    setLoading(false);
  }

  function handleOpenConfirmActivateAdvertisingModal(advertisingId) {
    setAdvertisingId(advertisingId);
    setIsConfirmActivateAdvertisingModalOpen(true);
  }

  return (
    <>
      <Card
        content={
          <>
            <div
              className="col-xs-12 col-sm-12 col-md-12 col-lg-12"
              id="vendaHeader"
              style={{ marginBottom: '20px' }}
            >
              <button
                className="btn btn-sucesso"
                onClick={() => history.push('advertising')}
              >
                + Nova Propaganda
              </button>

              <div className="col-xs-12 col-sm-8 col-md-5 col-lg-5">
                <SearchInput
                  placeholder="Pesquisa por Descrição"
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  minWidth: 'fit-content',
                }}
              >
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  <strong>Status: </strong>
                </span>
                <select
                  className="form-control foco-input"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  style={{ marginLeft: '10px' }}
                >
                  <option value="">Ambos</option>
                  <option value="true">Ativa</option>
                  <option value="false">Inativa</option>
                </select>
              </div>
              <div className="flex">
                <SelectBranding
                  boldLabel
                  showAllOption
                  variant={'row'}
                  height={'35px'}
                  value={branding}
                  onChange={(e) => setBranding(e)}
                />
              </div>
            </div>
            <div
              className="col-xs-12 col-sm-12 col-md-12 col-lg-12"
              style={{ padding: '0px', marginBottom: '20px' }}
            >
              <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                <span>
                  <strong>Data Inicial:</strong>
                </span>
                <input
                  className="form-control foco-input"
                  type="date"
                  name="dataInicial"
                  max={
                    finalDate
                      ? format(
                          new Date(getDateOnlyFromDate(finalDate)),
                          'yyyy-MM-dd'
                        )
                      : format(new Date(), 'yyyy-MM-dd')
                  }
                  onChange={(e) => setInitialDate(e.target.value)}
                  value={initialDate}
                />
              </div>

              <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                <span>
                  <strong>Data Final:</strong>
                </span>
                <input
                  className="form-control foco-input"
                  type="date"
                  name="dataFinal"
                  min={
                    initialDate
                      ? format(
                          new Date(getDateOnlyFromDate(initialDate)),
                          'yyyy-MM-dd'
                        )
                      : undefined
                  }
                  value={finalDate}
                  onChange={(e) => setFinalDate(e.target.value)}
                />
              </div>
            </div>
            <ReactTable
              style={{
                fontWeight: 'bold',
                textAlign: 'center',
              }}
              data={advertisements.filter(handleFilters)}
              columns={[
                {
                  Header: 'Dt. Criação',
                  accessor: 'createdAt',
                  width: 100,
                  Cell: (props) => format(new Date(props.value), 'dd/MM/yyyy'),
                },
                {
                  Header: 'Produto',
                  accessor: 'brandingId',
                  width: 90,
                  Cell: (props) => <BrandingLabel brandingId={props.value} />,
                },
                {
                  Header: 'Tipo',
                  accessor: 'type',
                  width: 100,
                  Cell: (props) => (
                    <Button
                      id="botaoReactTable"
                      style={{
                        zIndex: '0',
                        width: '100%',
                        height: '100%',
                        backgroundColor:
                          props.value === 'GLOBAL' ? '#428bca' : '#5cb85c',
                      }}
                    >
                      {props.value === 'GLOBAL' ? 'Global' : 'Exclusivo'}
                    </Button>
                  ),
                },
                {
                  Header: 'Descrição',
                  accessor: 'description',
                },
                {
                  Header: 'Possui link?',
                  accessor: 'redirectUrl',
                  width: 100,
                  Cell: (props) => (
                    <Button
                      id="botaoReactTable"
                      style={{
                        zIndex: '0',
                        width: '100%',
                        height: '100%',
                        backgroundColor: props.value ? 'green' : 'red',
                      }}
                    >
                      {props.value ? 'Sim' : 'Não'}
                    </Button>
                  ),
                },
                {
                  Header: 'Clicks',
                  accessor: 'clicks',
                  width: 150,
                  Cell: (props) =>
                    !props.original.redirectUrl ? '----' : props.value,
                },
                {
                  Header: 'Status',
                  width: 100,
                  accessor: 'isActive',
                  Cell: (props) => (
                    <div>
                      <Toggle
                        checked={props.value}
                        name="Status"
                        style={{ position: 'fixed' }}
                        readOnly
                        onClick={() =>
                          props.value
                            ? handleDisableAdvertising(props.original.id)
                            : handleOpenConfirmActivateAdvertisingModal(
                                props.original.id
                              )
                        }
                      />
                    </div>
                  ),
                },
                {
                  Header: 'Ações',
                  accessor: 'Actions',
                  filterable: false,
                  width: 100,
                  Cell: (props) => (
                    <AdvertisingActions advertising={props.original} />
                  ),
                },
              ]}
              defaultPageSize={10}
              loading={loading}
              showPagination
              sortable
              showPaginationTop={false}
              showPaginationBottom
              pageSizeOptions={[5, 10, 20, 25, 50, 100]}
              previousText="Anterior"
              nextText="Próximo"
              loadingText="Carregando..."
              noDataText="Não há informação"
              pageText="Página"
              ofText="de"
              rowsText="linhas"
            />
          </>
        }
      />

      <AlertModal
        show={isConfirmActivateAdvertisingModalOpen}
        loading={loading}
        title="Ativar Propaganda"
        message="Deseja ativar a propaganda? Se ativa, a mesma será exibida no dashboard de todos os clientes dos segmentos e planos selecionados."
        onCancel={() => setIsConfirmActivateAdvertisingModalOpen(false)}
        onHide={() => setIsConfirmActivateAdvertisingModalOpen(false)}
        onSubmit={handleActivateAdvertising}
      />
    </>
  );
};

export default withRouter(Advertisements);
