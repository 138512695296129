import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import AlertModal from 'components/AlertModal/AlertModal';
import { FormParcels } from 'v2/client/components/FormParcels';
import { handleChange } from '../redux/actions';
import { toastr } from 'react-redux-toastr';

export function RecloseModal({ open, handleClose, setRecloseSaleTrigger }) {
  const dispatch = useDispatch();

  const { saleReducer } = useSelector((state) => state);
  const { total } = saleReducer;

  const [newInstallments, setNewInstallments] = useState([]);
  const [newPaymentCondition, setNewPaymentCondition] = useState(null);
  const [newFeeValue, setNewFeeValue] = useState(0);

  const [installmentsSerialized, setInstallmentsSerialized] = useState(null);

  const [openRecloseConfirmationModal, setOpenRecloseConfirmationModal] =
    useState(false);

  const [reclosingInProgress, setReclosingInProgress] = useState(false);

  const validateInstallments = () => {
    const installmentsSerialized = newInstallments?.map((installment) => ({
      ...installment,
      formOfPayment: {
        id: installment.formOfPayment?.value?.id,
      },
      value: installment.value,
      amountReceived: installment.received,
      cashierBank: {
        id: installment.cashierBank?.value?.id,
      },
    }));

    console.log('newInstallments', newInstallments);
    console.log('installmentsSerialized', installmentsSerialized);

    if (installmentsSerialized.length === 0) {
      toastr.warning(
        'Parcelas indefinidas',
        'Por favor, preencha as parcelas para continuar'
      );
      return;
    }

    const parcelsWithoutFormOfPayment = installmentsSerialized.some(
      (parcel) => !parcel?.formOfPayment?.id
    );

    if (parcelsWithoutFormOfPayment) {
      toastr.warning(
        'Parcelas sem forma de pagamento',
        'Por favor, preencha todas as formas de pagamento para continuar'
      );
      return;
    }

    const totalParcels = installmentsSerialized.reduce(
      (prev, curr) => prev + curr.value,
      0
    );

    if (totalParcels !== total + newFeeValue) {
      toastr.warning(
        'Somatória das parcelas é diferente o agrupamento total',
        'Por favor, preencha os valores corretamente para continuar'
      );
      return;
    }

    setInstallmentsSerialized(installmentsSerialized);
    setOpenRecloseConfirmationModal(true);
  };

  const handleRecloseSale = () => {
    if (reclosingInProgress) return;
    setReclosingInProgress(true);

    dispatch(handleChange(newFeeValue, 'installmentFees'));
    dispatch(handleChange(installmentsSerialized, 'installments'));
    dispatch(handleChange(newPaymentCondition, 'selectedCondOfPayment'));
    dispatch(handleChange(true, 'isClosingAgain'));

    setRecloseSaleTrigger(true);
    setTimeout(() => {
      setRecloseSaleTrigger(false);
    }, 500);
  };

  return (
    <Modal show={open} onHide={() => null} dialogClassName="modal-90w">
      <Modal.Header>
        <Modal.Title>
          <strong>Refechamento de Venda</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="w-100" style={{ marginTop: '-20px' }}>
          <FormParcels
            total={total}
            installments={newInstallments}
            setInstallments={setNewInstallments}
            feeValue={newFeeValue}
            setFeeValue={setNewFeeValue}
            onChangePaymentCondition={setNewPaymentCondition}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="flex end gap-050">
          <button className="button button-red" onClick={handleClose}>
            Voltar
          </button>
          <button
            className="button button-green"
            onClick={validateInstallments}
          >
            Concluir
          </button>
        </div>
      </Modal.Footer>

      <AlertModal
        title="Confirmar refechamento"
        show={openRecloseConfirmationModal}
        message="Deseja realmente refechar esta venda? Quaisquer títulos anteriores serão cancelados."
        onHide={() => setOpenRecloseConfirmationModal(false)}
        onCancel={() => setOpenRecloseConfirmationModal(false)}
        onSubmit={handleRecloseSale}
      />
    </Modal>
  );
}
